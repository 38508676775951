import { FETCH_KEYWORDS } from '../actions/types'
const initialState = {
  keywords: []
}

const keywords = (state=initialState, action)=>{
  switch(action.type){
    case FETCH_KEYWORDS:
      return {
        ...state,
        keywords: action.payload
      }
  }

  return state
}

export default keywords
