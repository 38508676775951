import React, { Component } from 'react'
import logo from '../../assets/img/logo.png'
import _ from 'lodash'
// COMPONENTES
import MainContainer from './MainContainer/MainContainer'

import './styles.css'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: 'all',
      competitor: 'brand',
      order: 'date'
    }

    this.onCompetitor = this.onCompetitor.bind(this)
    this.onFilter = this.onFilter.bind(this)
    this.onOrder = this.onOrder.bind(this)
  }

  onCompetitor(competitor){
    this.setState({
      competitor: competitor
    })
  }

  onFilter(filter){
    this.setState({
      filter: filter
    }, this.container.onRefreshPosts)
  }

  onOrder(order){
    this.setState({
      order: order
    }, this.container.onRefreshPosts)
  }

  render () {
    const { industrySettings, networks } = this.props
    const { competitors_enabled } = this.props.settings

    const brand = _.find(networks, {
      provider: 'facebook'
    })

    let competitors = _.map(industrySettings.names.competitors, (competitor, index)=>{
      const { facebook, instagram, twitter, images } = industrySettings
      const id = facebook[index] || twitter[index] || instagram[index] || ''

      return {
        name: competitor == '' ? `Competitor ${index+1}` : competitor,
        facebook: facebook[index] || '',
        twitter: twitter[index] || '',
        instagram: instagram[index] || '',
        image: images.competitors[index],
        id: competitor == '' ? id : competitor,
      }
    })

    competitors = _.filter(competitors, (competitor, index)=>{
      const { facebook, instagram, twitter } = competitor

      return facebook.trim() != '' || instagram.trim() || twitter.trim()
    })

    return(
      <div id="dashboard">
        <div className="dashboardSidebar">
          <div className="dashboardSidebarProviders">
             <a 
              className={this.state.competitor == 'brand' && 'active'} 
              onClick={()=>(this.onCompetitor('brand'))}
            >
              <div className="dashboardSidebarProviderImage" style={{backgroundImage: `url(${brand.picture})`}} />
              <p className="dashboardSidebarLabel">{industrySettings.names.brand.trim() == '' ? brand.name : industrySettings.names.brand}</p>
            </a>

            {(competitors_enabled ? competitors : []).map((competitor, index)=>{
              const { name, image } = competitor
              return (
                <a 
                  className={(this.state.competitor.name || this.state.competitor).replace(/\s/g, "").toLowerCase() == name.replace(/\s/g, "").toLowerCase() && 'active'} 
                  onClick={()=>{
                    this.onCompetitor(competitor)
                    this.state.filter == 'linkedin' && this.onFilter('all')
                    !['interactions', 'category', 'reach_organic', 'eng_score', 'date'].includes(this.state.order) && this.onOrder('date')
                  }}
                >
                  <div className="dashboardSidebarProviderImage" style={{backgroundImage: `url(${image})`}} />
                  <p className="dashboardSidebarLabel">{name}</p>
                </a>
              )
            })}
            
            {(competitors.length > 0 && competitors_enabled) && (
              <a 
                className={this.state.competitor == 'all' && 'active'} 
                onClick={()=>(this.onCompetitor('all'))}
              >
                <div className="dashboardSidebarProviderImage" style={{backgroundImage: `url(${logo})`}} />
                <p className="dashboardSidebarLabel">All</p>
              </a>
            )}
          </div>
          <div>
            <a
              className={this.state.filter == 'facebook' && 'active'} 
              onClick={()=>(this.onFilter('facebook'))}
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className={this.state.filter == 'instagram' && 'active'} 
              onClick={()=>(this.onFilter('instagram'))}
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className={this.state.filter == 'twitter' && 'active'} 
              onClick={()=>(this.onFilter('twitter'))}
            >
              <i className="fab fa-twitter"></i>
            </a>
            {(this.state.competitor == 'brand' || this.state.competitor == 'all') && (
              <a
                data-disabled={this.state.competitor != 'brand' && this.state.competitor != 'all'}
                className={this.state.filter == 'linkedin' && 'active'} 
                onClick={()=>(this.onFilter('linkedin'))}
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            )}
            {(this.state.competitor == 'brand' || this.state.competitor == 'all') && (
              <a
                data-disabled={this.state.competitor != 'brand' && this.state.competitor != 'all'}
                className={this.state.filter == 'youtube' && 'active'} 
                onClick={()=>(this.onFilter('youtube'))}
              >
                <i className="fab fa-youtube"></i>
              </a>
            )}
            <a
              className={this.state.filter == 'all' && 'active'} 
              onClick={()=>(this.onFilter('all'))}
            > 
              <i>ALL</i>
            </a>
          </div>
          <div>
            <a 
              className={this.state.order == 'date' && 'active'} 
              onClick={()=>(this.onOrder('date'))}
            >
              <i className="far fa-calendar-alt" />
              <p className="dashboardSidebarLabel">DAY</p>
            </a>

            <a 
              className={(this.state.order == 'eng_score' || this.state.order == 'es_competition') && 'active'} 
              onClick={()=>(this.onOrder(this.state.competitor != 'brand' ? 'es_competition' : 'eng_score'))}
            >
              <i className="far fa-heart" />
              <p className="dashboardSidebarLabel">ENG SCORE</p>
            </a>

            {(this.state.competitor == 'brand') && (
              <a 
                className={this.state.order == 'eng_score_organic' && 'active'} 
                onClick={()=>(this.onOrder('eng_score_organic'))}
              >
                <i className="fas fa-leaf" />
                <p className="dashboardSidebarLabel">ENG SCORE ORGANIC</p>
              </a>
            )}

            {(this.state.competitor == 'brand') && (
              <a 
                className={this.state.order == 'eng_score_paid' && 'active'} 
                onClick={()=>(this.onOrder('eng_score_paid'))}
              >
                <i className="far fa-money-bill-alt" />
                <p className="dashboardSidebarLabel">ENG SCORE PAID</p>
              </a>
            )}

            <a 
              className={this.state.order == 'reach_organic' && 'active'} 
              onClick={()=>(this.onOrder('reach_organic'))}
            >
              <i className="fas fa-sitemap" />
              <p className="dashboardSidebarLabel">REACH ORGANIC</p>
            </a>

            {(this.state.competitor == 'brand') && (
              <a 
                className={this.state.order == 'reach_paid' && 'active'} 
                onClick={()=>(this.onOrder('reach_paid'))}
              >
                <i className="fas fa-globe-americas" />
                <p className="dashboardSidebarLabel">REACH PAID</p>
              </a>
            )}

            <a 
              className={this.state.order == 'category' && 'active'} 
              onClick={()=>(this.onOrder('category'))}
            >
              <i className="fas fa-minus-circle" />
              <p className="dashboardSidebarLabel">NO CATEGORY</p>
            </a>

            <a 
              className={this.state.order == 'interactions' && 'active'} 
              onClick={()=>(this.onOrder('interactions'))}
            >
              <i className="fab fa-wpexplorer" />
              <p className="dashboardSidebarLabel">INTERACTIONS</p>
            </a>
          </div>
        </div>
        <MainContainer
          {...this.props} 
          ref={(container)=>(this.container = container)}
          filter={this.state.filter}
          order={this.state.order}
          competitor={this.state.competitor}
        />
      </div>
    )
  }
}

export default Dashboard
