import React, { Component } from 'react'
import { Colors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"
import numeral from 'numeral'
import moment from 'moment'
import Chart from '../../Shared/Chart'
import _ from 'lodash'
import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class AgeSessions extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        columns: [],
        rows: [],
        serialized: [{}]
      },
      loading: true
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:pageviewsPerSession',
          dimensions: 'ga:userAgeBracket',
          sort: '',
          maxResults: '',
          filters: ''
        }
      })
    })
  }

  chart(data){
    const { serialized, columns, rows } = data

    const custom =  _.map(rows, (item, index)=>{
      return {
        'plot-rules': {
          rule: `%i==${index}`,
          backgroundColor: Colors[index]
        },
        'plot-labels': {
          rule: `%i==${index}`,
          borderColor: Colors[index],
          text: `<span style="font-weight:bold"><div style="width: 10px; height: 10px; background-color: ${Colors[index]}; display: inline-block"></div> ${item[0]}: ${numeral(item[1]).format('0,0[.]00')}</span>`
        }
      }
    })

    let config = {
      type: 'bar',
      backgroundColor: '#363737',
      gui: {
        behaviors: [{
          id: 'ViewSource',
          enabled: 'none'
        },{
          id: 'HideGuide',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'ViewAsPng',
          enabled: 'none'
        },{
          id: 'ViewDataTable',
          enabled: 'none'
        }]
      },
      globals: {
        fontFamily: 'Helvetica'
      },
      plotarea:{ 
        backgroundColor : '#363737',
        adjustLayout: true,
        margin: 0
      },
      scaleY: {
        lineStyle: 'solid',
        thousandsSeparator: ',',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF'
        }
      },
      scaleX: {
        values: _.map(rows, (row)=>(row[0])),
        lineStyle: 'solid',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF'
        }
      },
      plot: {
        cursor: 'hand',
        rules: _.map(custom, 'plot-rules')
      },
      tooltip: {
        text: '%v',
        multiple: true,
        fontSize: '12px',
        color: '#FFFFFF',
        backgroundColor: '#000',
        borderWidth: 0,
        alpha: 0.6,
        callout: true,
        calloutPosition: 'bottom',
        shadow: 0,
        borderRadius: 4,
        rules: _.map(custom, 'plot-labels'),
        placement: 'node:top',
        calloutPosition: 'bottom',
        offsetY: 8
      },
      series: [{
        values: _.map(rows, (row)=>(row[1]))
      }]
    }

    return (
      <Chart 
        width="100%" 
        height="146px"
        output="canvas"
        data={config} 
      />
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>{Chart}</div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id='googleAgeSessions'>
        <Title description='Reporte de mayor rango de edades.' title='Age' />
        <div className="googleAgeSessionsContainer">
          <h3>Page / Sessions</h3>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )

  }
}

export default AgeSessions
