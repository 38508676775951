import React, { Component } from 'react'
import _ from 'lodash'
import { TimezonePicker } from "@blueprintjs/timezone"

import './styles.css'

class TimeZonePicker extends Component {
  render () {
    return(
      <div className="timeZoneContainer">
        <TimezonePicker  
          popoverProps={{
            'popoverClassName': 'timeZone'
          }} 
        >
          <i className="fas fa-globe-americas" />
        </TimezonePicker>
      </div>
    )
  }
}

export default TimeZonePicker