import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import axios from '../../../services/axios'
import storage from '../../../services/storage'
import numeral from 'numeral'
import _ from 'lodash'

import logo from '../../../assets/img/logo.png'
import { Competitors } from '../../../config'
import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import IndustryError from '../../Shared/IndustryError'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Rank extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    return storage.get('page')
    .then((page)=>{
      return axios.get(`industry/alexa/${page}`)
    }).then((response)=>{
      return _.orderBy(response.data.data, 'rank.percent', 'desc')
    })
  }

  chart(data){
    return data.length > 0 ? (
      data.map((item, index)=>{
        return (
          <div className={`trendingRankItem ${Competitors < 5 && 'trendingRankItemFull'}`}>
            <div className="trendingRankItemIcon" >
               <img alt="" src={!item.brand.image || item.brand.image == "" ? logo : item.brand.image} />
            </div>
            <div className="trendingRankItemData">
              <p className="trendingRankItemPosition">
                #{index+1}
              </p>
              <p className="trendingRankItemPercent">
                {item.rank.percent}%
              </p>
              <p className="trendingRankItemValue">
                {numeral(item.rank.value).format('0,0')}
              </p>
            </div>
          </div>
        )
      })
    ): (
      <IndustryError />
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="trendingRank">
        <Title description='El lugar que ocupa el sitio de la marca y su competencia a nivel global.' title='Rank' />
         <div className='trendingRankContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default Rank
