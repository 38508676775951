import React, { Component } from 'react'
import { Button, H1 } from "@blueprintjs/core"
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import _ from 'lodash'
import { RangeConfig } from '../../../config'
import axios from '../../../services/axios'
import storage from '../../../services/storage'

// COMPONENTES
import CommunicationStrategy from '../../SocialIntelligence/CommunicationStrategy/CommunicationStrategy'
import TopicData from '../../SocialIntelligence/TopicData/TopicData'
import Analytics from '../../SocialIntelligence/Analytics'
import AdsScore from '../../AdsIntelligence/AdsScore/AdsScore'
import TopInterests from '../../AdsIntelligence/TopInterests/TopInterests'
import TopInterestsCampaings from '../../AdsIntelligence/TopInterestsCampaings'
import TotalSpend from '../../AdsIntelligence/TotalSpend/TotalSpend'
import Placement from '../../AdsIntelligence/Placement/Placement'

import 'bootstrap-daterangepicker/daterangepicker.css'
import './styles.css'

class MainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      range: [
        moment(this.props.range[0]).startOf('day').toDate(),
        moment(this.props.range[1]).startOf('day').toDate()
      ],
      networks: this.props.networks,
      campaing: {
        campaign_id: 0
      },
      category: {
        category: {
          name: 'all'
        }
      }
    } 

    this.range = [
      moment(this.props.range[0]).startOf('day').toDate(),
      moment(this.props.range[1]).startOf('day').toDate()
    ]

    this.components = {}
    this.onSearch = this.onSearch.bind(this)
    this.onGetData = this.onGetData.bind(this)
    this.onChangeRange = this.onChangeRange.bind(this)
    this.onSelectCategory = this.onSelectCategory.bind(this)
    this.onSelectCampaing = this.onSelectCampaing.bind(this)
  }

  onSearch(){
    this.onGetData()
  }

  onGetData(){
    this.setState({
      range: this.range
    })
  }

  onChangeRange({startDate, endDate}){
    const range = [startDate.toDate(), endDate.toDate()]

    this.dateStart.innerHTML = moment(range[0]).format('YYYY-MM-DD')
    this.dateEnd.innerHTML = moment(range[1]).format('YYYY-MM-DD')

    storage.set('range', range)
    
    this.range = range
  }

  onSelectCategory(category){
    this.setState({
      category: category
    })
  }

  onSelectCampaing(campaing){
    this.setState({
      campaing: campaing
    })
  }

  render () {
    const exist = _.find(this.state.networks, {provider: this.props.filter})
    const { campaings } = this.props

    return(
      <div className="socialContainer" data-html2canvas-print>
        <div className="top-container">
          <div className="brands-added">
            <div className="grid">
              <div className="col-5_sm-12">
                <H1 className="brands-added__title" data-html2canvas-ignore>Overview Intelligence</H1>
              </div>
              <div className="col-7_sm-12">
                <div className="searchContainer" data-html2canvas-ignore>
                  <div className="dateSmart bp3-dark">
                    <span className="searchCalendarIcon"><i className="far fa-calendar-alt"></i></span>
                    <DateRangePicker
                      {...RangeConfig} 
                      startDate={this.state.range[0]} 
                      endDate={this.state.range[1]}
                      onApply={(event, picker)=>(this.onChangeRange(picker))}>
                      <div className="daterangepickerDates">
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateStart = ref)}>
                          {moment(this.state.range[0]).format('YYYY-MM-DD')}
                        </span>
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateEnd = ref)}>
                          {moment(this.state.range[1]).format('YYYY-MM-DD')}
                        </span>
                      </div>
                    </DateRangePicker>
                    <Button onClick={this.onSearch} minimal="true" className="button btn-outline" >Search</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(this.props.filter == 'all' || !!exist) ? (
          <div className="social-intelligence__container" id="MainContainer">
            <div className="grid" id="overview_organic">
              <H1 className="overview-section__title">
                ORGANIC OVERVIEW {this.props.filter == 'all' ? '' : this.props.filter}
              </H1>
              <div className="col-12">
                <Analytics 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['analytics'] = ref))}
                />
              </div>
              <div className="col-12">
                <CommunicationStrategy 
                  onSelectCategory={this.onSelectCategory} 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['strategy'] = ref))}
                />
              </div>
              {this.props.competitor == 'brand' && (
                <div className="col-12">
                    <TopicData 
                      filter={this.props.filter}
                      competitor={this.props.competitor}
                      range={this.state.range}
                      ref={(ref)=>( ref && (this.components['topicdata'] = ref))}
                    />
                </div>
              )}
            </div>
            <div className="grid" id="overview_ads">
              <H1 className="overview-section__title">
                ADS OVERVIEW
              </H1>
              <div className="col-12">
                <AdsScore
                  onSelectCampaing={this.onSelectCampaing} 
                  range={this.state.range}
                  campaings={campaings}
                  ref={(ref)=>( ref && (this.components['score'] = ref))}
                />
              </div>
              <div className="col-5_sm-12">
                <TopInterests 
                  range={this.state.range}
                  campaings={campaings}
                  ref={(ref)=>( ref && (this.components['interests'] = ref))}
                />
              </div>
              <div className="col-3_sm-12">
                <TopInterestsCampaings
                  {...this.state.campaing}
                  range={this.state.range}
                  campaings={campaings}
                  ref={(ref)=>( ref && (this.components['interestsCategory'] = ref))}
                />
              </div>
              <div className="col-4_sm-12 no-pad-b">
                <div className="col-12">
                  <div className="best-days-shell">
                    <div className="grid">
                      <div className="col-12 no-pad-l-f">
                        <TotalSpend 
                          range={this.state.range}
                          campaings={campaings}
                          ref={(ref)=>( ref && (this.components['spend'] = ref))}
                        />
                      </div>
                      <div className="col-12 no-pad-l-f no-pad-b">
                        <Placement 
                          range={this.state.range}
                          campaings={campaings}
                          ref={(ref)=>( ref && (this.components['placement'] = ref))} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="notData">
            NO DATA
          </div>
        )}
      </div>
    )
  }
}

export default MainContainer
