import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import Root from './Root'

// Framework
import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/table/lib/css/table.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'gridlex/dist/gridlex.min.css'
import 'react-virtualized/styles.css'
import 'react-tippy/dist/tippy.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'simplebar/dist/simplebar.css'
import 'overlayscrollbars/css/OverlayScrollbars.min.css'

import App from './components/App'

// App CSS Theme
import './assets/css/styles.css'

// library.add(faCalendar)
window.Sentry.init({ dsn: 'https://50420c4bbf2e4ffcac0c2f15709f4c04@sentry.io/1465943' })
ReactDOM.render(
  <Root>
    <BrowserRouter>
      <Route path="/" component={App} />
    </BrowserRouter>
  </Root>,
  document.querySelector('#root')
)
