import React, { Component } from 'react'
import { 
  Spinner,
  Dialog 
} from "@blueprintjs/core"
import { Dropdown as DropdownSem } from 'semantic-ui-react'
import axios from '../../../services/axios'
import SocialIcons from '../../Shared/SocialIcons'
import DropDown from '../../Shared/DropDown'


import './styles.css'

class ListAds extends Component {
    constructor(props){
      super(props)
      this.state = {
        visible: false,
        accounts: [],
        active: -1,
        loading: false
      }

      this.access_token = ''  
      this.resolve = null
      this.reject = null

      this.onSelect = this.onSelect.bind(this)
      this.show = this.show.bind(this)
      this.hide = this.hide.bind(this)
    }


    onSelect(){
     this.resolve && this.resolve({
      provider: 'ads',
      data: {
        id: this.state.accounts[this.state.active].id,
        token: this.access_token,
        provider: 'ads'
      }
     })
     this.hide() 
    }

    show(access_token){
      this.setState({
        loading: true,
        visible: true
      })
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        this.access_token = access_token

        axios.get('marcas/accounts/pages', {
          params: {
            access_token,
            provider: 'ads'
          }
        }).then((response)=>{
          this.setState({
            loading: false,
            accounts: response.data.data
          })
        })
      })
    }

    hide(){
      this.setState({
        visible: false,
        accounts: [],
        active: -1,
        loading: false
      })
    }

    componentWillMount() {

    }

    render() {
      return(
        <Dialog
          isOpen={this.state.visible}
          className='listAds'
        >
          <div className='listAdsContainer'>
            <div className="bp3-dialog-header listAdsHeader">
              <SocialIcons provider='facebook' className='listAdsProvider' />
              <h4 className="bp3-heading">Select Your Ads Account</h4>
              <i className="fas fa-times" onClick={this.hide}></i>
            </div>
            <div className="bp3-dialog-body">
              <div className="listAdsItems">
                <DropDown
                  bsStyle='default'
                  disabled={this.state.loading}
                  title={this.state.active > -1 ? this.state.accounts[this.state.active].name : 'accounts'}
                >
                {this.state.accounts.map((account, index)=>{
                  return (
                    <DropdownSem.Item onClick={()=>{
                      this.setState({
                        active: index
                      })
                    }} active={this.state.active == index} >{account.name}</DropdownSem.Item>
                  )
                })}
                </DropDown>
              </div>
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <button
                  disabled={this.state.active == -1}
                  onClick={this.onSelect} 
                  type="button" 
                  className="bp3-button listAdsSelect">
                  Select
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      )
    }
}

export default ListAds
