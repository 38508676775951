import React, { Component } from 'react';
import { 
  InputGroup,
  FormGroup,
  Button
} from "@blueprintjs/core"
import Alert from '../../../Alert'
import _ from 'lodash'

import './styles.css'

class Optimal extends Component {
  constructor(props){
    super(props)

    this.inputs = {}
    this.onSave = this.onSave.bind(this)
  }

  onSave(){
    const data = _.reduce(this.inputs, (data, input, index)=>{
      data[index] = input.value
      return data
    }, {})
    
    this.props.onSave && this.props.onSave({
      optimal: JSON.stringify(data)
    })
  }

  render() {
    return (
      <div className="sidebarOptimalPosts">
        <Alert 
          ref={(alert)=>(this.alert = alert)}
          body='You will change your monthly optimal post number.'
          onAccept={this.onSave}
        />
        <div className="sidebar-title sidebar-title__left">
          Optimal Posts Per Month
        </div>
        {_.map(this.props.optimal, (item, index)=>{
          return (
            <FormGroup
              label={index}
              inline={true}>
              <InputGroup
                disabled={this.props.lock}
                inputRef={(input)=>(this.inputs[index] = input)}
                defaultValue={item}
              />
            </FormGroup>
          )
        })}      
        <Button
          onClick={()=>(this.props.lock ? this.props.onErrorPermissions() : this.alert.show())} 
          minimal="true" 
          className={`button button-primary sidebar-button button__save sidebarOptimalPostsSave ${this.props.lock && 'bp3-disabled'}`}>
          SAVE
        </Button>
      </div>
    )
  }
}

export default Optimal
