import React, { Component } from 'react'

import MainContainer from '../MainContainer/'

class Retail extends Component {
  render(){
    return (
      <div id="retail">
        <MainContainer />
      </div>
    )
  }
}


export default Retail
