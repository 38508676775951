import React, { Component } from 'react'
import { Card, Elevation, Spinner } from '@blueprintjs/core'

import SimpleBar from 'simplebar-react'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import { Tooltip } from 'react-tippy'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Demographics extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: {
        countries: [],
        cities: []
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.extras = this.extras.bind(this)
    this.countries =  this.countries.bind(this)
    this.cities = this.cities.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range, filter){
    const { competitor } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/demographics/${page}?since=${since}&until=${until}&provider=${filter}`)
    })
  }

  cities({cities: data}){
    return (
      <div className="demographicsCities">
        <table>
          <thead>
            <tr>
              <th>City</th>
              <th>Fans</th> 
            </tr>
          </thead>
          <SimpleBar style={{maxHeight: '106px'}}>
            <tbody>
              {data.map((city, index)=>{
                return (
                  <tr>
                    <td>{index+1}. {city.name}</td>
                    <td>{numeral(city.value).format('0,0')}</td> 
                  </tr>
                )
              })}
              {_.range(0, 5 - data.length, 1).map((item, index)=>{
                return (
                  <tr>
                    <td>-</td>
                    <td>-</td> 
                  </tr>
                )
              })}
            </tbody>
          </SimpleBar>
        </table>
      </div>
    )
  }

  countries({countries: data}){
    return (
      <div className="demographicsFlags">
        {data.slice(0, 8).map((country, index)=>{
          const flag = require(`../../../assets/img/flags/${country.name.toLowerCase()}.png`)
          return (
            <Tooltip
              title={`${country.name}: ${numeral(country.value).format('0,0')}`}
              position="top"
              trigger="mouseenter focus"
            >
              <div className="demographicsFlagsImage" style={{backgroundImage: `url(${flag})`}} />
            </Tooltip>
          )
        })}
      </div>
    )
  }

  extras({fans}){
   return (
      <div className="demographicsSubtitle">
        <div className="demographicsSubtitleItem">
          <p className="demographicsSubtitleText">Followers</p>
          <p className="demographicsSubtitleValue">{numeral(fans || 0).format('0,0')}</p>
        </div>
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Extras = this.extras(data)
    const Cities = this.cities(data)
    const Countries = this.countries(data)

    return (
      <div>
        {Extras}
        {Countries}
        {Cities}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialDemografics">
        <Title description='Reporte de Edades y Sexo de audiencia.' title='Demografics' />
         <div className='socialDemograficsContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default Demographics
