import React, { Component } from 'react';
import { 
  Dialog,
  Switch
} from "@blueprintjs/core"
import SocialIcons from '../../../SocialIcons'
import _ from 'lodash' 
import './styles.css'

class NetWorks extends Component {
  constructor(props){
    super(props)
  }

  render() {
    const networks = _.filter(this.props.networks, (network)=>{
      return network.provider != 'google' && network.provider != 'ads'
    })
    
    return (
      <div className="sidebar-accounts">
        <div className="sidebar-title">
          Brand Accounts
        </div>
        <div className='socialNetworkContainer' >
          {networks.map((network)=>{
            return (
              <div
                className='accountSocialNetwork' 
                provider={network.provider} 
                style={{backgroundImage:`url(${network.picture})`}}>
                <SocialIcons provider={network.provider} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default NetWorks
