import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import { Apis, Competitors as competitors, Permissions } from './constants'

const API_HOST = Apis[window.location.hostname] || 'smartapi.smartintel.io'
const API_URL = `https://${API_HOST}/api/`
const AUTH_URL = `https://${API_HOST}/auth/`
const API_ADS_URL = `https://${API_HOST}/api/ads/`

// Constantes
const Test = {
  token: 'EAACCEYFYQT0BAOhP8lx4M9WgR6L6S5qhdNmGZCtwZAaFTuwhMdZBc9DzIgjmWAwzmQJmLZC7laWaJ6LZBVVZBSMvGkSSRaT9pzPSJA9HWpFYZBGseNHbvZCbR7ZAq0l6IWondw55FJn5L8035oBD01iQf7igiBQYfjWD0uVfi377VDgZDZD',
  id: '101147927883577'
}
const Providers = ['facebook', 'twitter', 'instagram', 'youtube', 'linkedin', 'google', 'ads']
const Competitors = competitors[window.location.hostname] || 4
const Colors  = _.reduce(_.range(20), (colors, value, key)=>{
  colors = _.concat(colors, 
    ['#CCFF33', '#4FABE6', '#E2474F', '#3CC453', '#F79A3A', '#A91Dff', '#E2474F'])
  return colors
}, [])
const RangeConfig = {
  autoApply: true,
  ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  },
  alwaysShowCalendars: true,
  maxDate: new Date(),
  minDate: window.location.hostname == 'apple.smartintel.io' ? moment().subtract(4, 'months').toDate() : undefined,
  opens: 'left'
}
const showMetrics = ()=>{
  return window.location.hostname == 'audi.smartintel.io'
}
export {
	API_URL,
	API_ADS_URL,
	AUTH_URL,
	Competitors,
	Permissions,
	Providers,
  Colors,
  Test,
  RangeConfig,
  showMetrics
}
