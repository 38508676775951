import React, { Component } from 'react'
import { Icon, Elevation } from "@blueprintjs/core"
import WordItems from "./WordItems"
import { API_URL, API_ADS_URL } from '../../../../config.js'
import axios from 'axios'

class TopicDataItem extends Component {

  constructor(props) {
    super(props)

    this.addWord = this.addWord.bind(this)
  }

  addWord(){
    const { value } = this.word

    this.props.addWord(this.props.id, value)

    this.word.value = ''
  }

  render() {
    return(
      <div className="topicData-item">
        <div className="topic-item__title">
          {this.props.nombre}
        </div>
        <div className="topic-item__count">
          COUNT: {this.props.count}
        </div>

        <div className="topic-item__word">
          <div>
            <div className="grid-center-middle">
              <div className="col-10">
                <div className="bp3-input-group">
                  <input  
                    className="bp3-input topic-item__input"
                    ref={(word) => this.word = word}
                    placeholder="new word" >
                  </input>
                </div>

              </div>
              <div className="col-1">
                <Icon 
                  icon="add" 
                  elevation={Elevation.TWO} 
                  onClick={this.addWord} />
              </div>
            </div>
          </div>
        </div>
        <WordItems 
          words={this.props.words} 
          delete={this.props.deleteWord} 
        />
      </div>
    )
  }
}

export default TopicDataItem
