import React, { Component } from 'react';
import { Card, Elevation, Spinner } from "@blueprintjs/core";

import SimpleBar from 'simplebar-react'
import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'
import { Tooltip } from 'react-tippy'

import { Colors } from '../../../config'
import Chart from '../../Shared/Chart'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Location extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        contries: {
          columns: [],
          rows: [],
          serialized: [{}]
        },
        cities: {
          columns: [],
          rows: [],
          serialized: [{}]
        },
        sessions: {
          columns: [],
          rows: [],
          serialized: [{}]
        }
      },
      flags: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.table = this.table.bind(this)
    this.sessions = this.sessions.bind(this)
    this.flags = this.flags.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      const sessions = axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:sessions'
        }
      })

      const contries = axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:pageviews',
          dimensions: 'ga:country',
          sort: '-ga:pageviews',
          maxResults: '9'
        }
      })

      const cities = axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:pageviews',
          dimensions: 'ga:city',
          sort: '-ga:pageviews',
          maxResults: '10'
        }
      })

      const flags = axios.get('https://restcountries.eu/rest/v2/all', {
        params: {
          fields: 'name;flag;alpha2Code'
        }
      })

      return Promise.all([contries, cities, sessions, flags])
    }).then((response)=>{
      return {
        contries: response[0].data.data,
        cities: response[1].data.data,
        sessions: response[2].data.data,
        flags: response[3].data
      }
    })
  }

  flags(data){
    const { serialized, columns, rows } = data.contries
    return (
      <div>
        <div className="googleLocationFlags">
          {serialized.slice(0, 8).map((item, index)=>{
            let flag = ''
            let name = item.Country || ''
            let value = item.Pageviews || 0

            if(!!item.Country){
              const country = _.find(data.flags, (flag)=>{
                const name = flag.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                const country = item.Country.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")      
                return name.indexOf(country) > -1
              })
              if(!!country){
                flag = require(`../../../assets/img/flags/${country.alpha2Code.toLowerCase()}.png`) 
              }
            }

            return (
              <Tooltip
                title={`${name.toUpperCase()}: ${numeral(value).format('0,0')}`}
                position="top"
                trigger="mouseenter focus"
              >
                <div 
                  className="googleLocationFlag" 
                  style={{
                    backgroundImage: `url(${flag})`
                  }}
                />
              </Tooltip>
            )
          })}
        </div>
      </div>
    )
  }

  table(data){
    const { columns, rows, serialized } = data.cities

    return (
      <div className="googleLocationTable">
        <table>
          <thead>
            <tr>
              {columns.map((column)=>{
                return (
                  <th>{column}</th>
                )
              })}
            </tr>
          </thead>
          <SimpleBar style={{ maxHeight: '106px' }}>
            <tbody>
              {rows.map((row, index)=>{
                return (
                  <tr>
                    <td>{index+1}. {row[0]}</td>
                    <td>{numeral(row[1]).format('0,0[.]00')}</td> 
                  </tr>
                )
              })}
            </tbody>
          </SimpleBar>
        </table>
      </div>
    )
  }

  sessions(data){
    return (
      <div className="googleLocationSessions">
        <p className="googleLocationSessionsTitle">Sessions</p>
        <p className="googleLocationSessionsValue">{numeral(data.sessions.rows[0]).format('0,0.[0000]')}</p>
      </div>
    )
  }

  success({ response, load }){
    const data = response
    const Flags = this.flags(data)
    const Table = this.table(data)
    const Sessions = this.sessions(data)

    return (
      <div>
        {Sessions}
        {Flags}
        {Table}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        id='googleLocation'
        className="no-pad-t-b">
        <Title description='Relación de países y ciudades de donde se visita el sitio web.' title='Location' />
        <div className="googleLocationContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default  Location
