import React, { Component } from 'react'
import { Card, Elevation, Position, H5, H6  } from "@blueprintjs/core"

import logoSmartCom from '../../../../../assets/img/loginsmart.png'
import logoSmart from '../../../../../assets/img/logo.png'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import { Tooltip } from 'react-tippy'

import Truncate from '../../../../Shared/Truncate'
import SocialIcons from '../../../../Shared/SocialIcons'
import IconType from '../../../../Shared/IconType'

import Swiper from 'react-id-swiper'

import Loading from '../../../../Shared/LoadingCharts'
import Ads from '../Ads'

import './styles.css'

class Panel extends Component {
  constructor(props){
    super(props)
    this.state = {
      active: 0
    }
    
    this.ads = this.ads.bind(this)
    this.score = this.score.bind(this)
    this.campaings = this.campaings.bind(this)
  }
  
  componentWillMount(){
    const { data } = this.props

    data.length > 0 && this.props.onSelect && this.props.onSelect(data[0])
  }

  campaings(data){
    return (
      <div className='adsScoreCampaings'>
        <Swiper 
          effect='fade'
          rebuildOnUpdate
          direction='vertical'
          autoHeight
          spaceBetween={0}
          initialSlide={Math.floor(this.state.active/4)}
          ref={(swiper)=>(swiper && (this.swiperCategories = swiper.swiper))}
        >
          {_.chunk(data, 4).map((campaings, campaingIndex)=>{
            return (
              <div>
                <div className="adsScoreCampaingsContainer">
                {campaings.map((campaing, index)=>{
                  return (
                    <div
                      className={`adsScoreCampaing ${this.state.active == (campaingIndex * 4) + index && 'active'}`} 
                      onClick={()=>{
                        this.props.onSelect && this.props.onSelect(campaing)
                        this.setState({
                          active: (campaingIndex * 4) + index
                        })
                      }}>
                      <p className="adsScoreCampaingName">
                        <Tooltip
                          title={campaing.name}
                          position="top"
                          trigger="mouseenter focus"
                        >
                          <p onCopy={(event)=>{
                            event.clipboardData.setData('text/plain', campaing.name)
                            event.preventDefault()
                            event.stopPropagation()
                          }}>
                            {campaing.name}
                          </p>
                        </Tooltip>
                      </p>
                      <p className="adsScoreCampaingScore">{numeral(campaing.score).format('0,0.00')}%</p>
                      <p className="adsScoreCampaingPosition">#{(campaingIndex * 4) + index + 1}</p>
                    </div>
                  )
                })}
                {_.range(4 - campaings.length).map((item, index)=>{
                  return (
                    <div 
                      className="adsScoreCampaing adsScoreCampaingPlaceholder"
                      style={{
                        backgroundImage: `url(${logoSmart})`
                      }}
                    />
                  )
                })}
                </div>
              </div>
            )
          })}
        </Swiper>
        {_.chunk(data, 4).length > 1 && (
          <div>
            <div 
              className="adsScoreCampaingsPrev">
              <i 
                onClick={()=>(this.swiperCategories && this.swiperCategories.slidePrev())} 
                className="fas fa-caret-up"
              />
            </div>
            <div
              className="adsScoreCampaingsNext">
              <i
                onClick={()=>(this.swiperCategories && this.swiperCategories.slideNext())}  
                className="fas fa-caret-down"
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  score(data){
    const { active: index } = this.state
    const active = data[index]

    return (
      <div className='adsScoreData'>
        <div className="adsScoreDataContainer">
          <div className="adsScoreDataItem">
            <p className="adsScoreDataResults">{numeral(active.results).format('0,0')}</p>
            <p className="adsScoreDataItemTitle">Results</p>
          </div>
          <div className="adsScoreDataItem">
            <p className="adsScoreDataObjetive">{active.objective}</p>
            <p className="adsScoreDataItemTitle">Objetive</p>
          </div>
          <div className="adsScoreDataItem">
            <p className="adsScoreDataDate">{moment(active.created_time).format('DD-MMM-YY')}</p>
            <p className="adsScoreDataSubdate">{active.status}</p>
            <p className="adsScoreDataItemTitle">Date</p>
          </div>
          <div className="adsScoreDataItem">
            <p className="adsScoreDataRelevance">{numeral(active.reach).format('0,0')}</p>
            <p className="adsScoreDataItemTitle">Impressions</p>
          </div>
          <div className="adsScoreDataItem">
            <p className="adsScoreDataFrequency">{numeral(active.frequency).format('0,0.00')}</p>
            <p className="adsScoreDataItemTitle">Frequency</p>
          </div>
          <div className="adsScoreDataItem">
            <p className="adsScoreDataAds">{numeral(active.ads_count).format('0,0')}</p>
            <p className="adsScoreDataItemTitle">Campaign ADs</p>
          </div>
          <div className="adsScoreDataItem">
            <p className="adsScoreDataCurrency">{active.account_currency}</p>
            <p className="adsScoreDataSpend">{numeral(active.insights.spend).format('0,0')}</p>
            <p className="adsScoreDataItemTitle">Spend</p>
          </div>
          <div className="adsScoreDataItem">
            <p className="adsScoreDataCurrency">{active.account_currency}</p>
            <p className="adsScoreDataCPR">{numeral(active.cpr).format('0,0.00')}</p>
            <p className="adsScoreDataItemTitle">CPR</p>
          </div>
          <div className="adsScoreDataItem">
            <p className="adsScoreDataCurrency">{active.account_currency}</p>
            <p className="adsScoreDataConversions">{numeral(active.conversions).format('0,0')}</p>
            <p className="adsScoreDataItemTitle">Conversions</p>
          </div>
        </div>
      </div>
    )
  }

  ads(data){
    const { active: index } = this.state
    const active = data[index]

    return (
      <Ads
        campaings={this.props.campaings} 
        range={this.props.range} 
        campaing={active.campaign_id} 
        {...active}
      />
    )
  }


  render () {
    const { data } = this.props

    const Campaings = this.campaings(data)
    const Score = this.score(data)
    const Ads = this.ads(data)

    return(
      <div>
        {Campaings}
        {Score}
        {Ads}
      </div>
    )
  }
}

export default Panel
