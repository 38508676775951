import React, { Component } from 'react'
import { Button, Card, Dialog, Classes, Slider } from "@blueprintjs/core"
// import { DateInput, TimePrecision } from "@blueprintjs/datetime"
import { DateInput } from "@blueprintjs/datetime"
import BigCalendar from 'react-big-calendar'
import Select from 'react-select'
import Dropzone from 'react-dropzone'
import VideoThumbnail from 'react-video-thumbnail'
import { Dropdown as DropdownSem } from 'semantic-ui-react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import DropDown from '../../Shared/DropDown'
import Alert from '../../Shared/Alert'
import NewEvent from '../components/NewEvent'
import NewWeek from '../components/NewWeek'
import Events from '../components/Events'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './styles.css'



class Calendar extends Component {
  constructor(props){
    super(props)

    this.state = {
      newWeekDialogOpen: false,
      rank: 1,
      newEventOpen: false,
      events: [],
      eventsCopy: [],
      files: [],
      date:null,
      selectedOption: null,
      updated: false,
      copyValue: '',
      commentValue: '',
      status: '',
      filter: 'all',
      posts: []
    }

    this.filter = this.filter.bind(this)
    this.getCalendar = this.getCalendar.bind(this)
    this.customToolbar = this.customToolbar.bind(this)
    this.getPosts = this.getPosts.bind(this)
  }

  getPosts(){
    const since = moment().subtract(3, 'months').format('YYYY-MM-DD')
    const until = moment().add(5, 'months').format('YYYY-MM-DD')
    
    storage.get('page')
    .then((page)=>{
      return axios.get(`posts/brand/${page}/${since}/${until}/all`)
    }).then((response)=>{
      this.setState({
        posts: _.orderBy(response.data.data, ['es_calculate'], ['desc'])
      })
    }).catch((error)=>{
  
    })
  }

  getCalendar(since = moment()){
    since = moment(since).startOf('month').format('YYYY-MM-DD')
    const until = moment(since).endOf('month').format('YYYY-MM-DD')

    storage.get('page')
    .then((page)=>{
      return axios.get(`calendar/${page}/posts?provider=all&since=${since}&until=${until}`)
    }).then((response)=>{
      const events =  _.filter(response.data.data, (event)=>{
        return this.state.filter == 'all' || event.provider == this.state.filter
      })

      this.setState({
        events: events,
        eventsCopy: response.data.data
      })
    }).catch((error)=>{
  
    })
  }

  filter(provider){
    const events =  _.filter(this.state.eventsCopy, (event)=>{
      return provider == 'all' || event.provider == provider
    })

    this.setState({
      filter: provider,
      events: events
    })
  }

  customToolbar(toolbar){
    const { userPermissions,  brandPermissions } = this.props
    
    const goToBack = () => {
      const month = toolbar.date.getMonth() - 1
      toolbar.date.setMonth(month)
      this.getCalendar(toolbar.date)
      toolbar.onNavigate('prev')
    }

    const goToNext = () => {
      const month = toolbar.date.getMonth() + 1
      toolbar.date.setMonth(month)
      this.getCalendar(toolbar.date)
      toolbar.onNavigate('next')
    }

    const label = () => {
      const date = moment(toolbar.date)
      return (
        <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
      )
    }

    return (
      <div className='toolbarCalendar'>
        <div className='toolbarCalendarFilter'>
          <div className='toolbarCalendarTwitter' onClick={()=>(this.filter('twitter'))}>
            <i className='fab fa-twitter'></i>
          </div>
          <div className='toolbarCalendarFacebook' onClick={()=>(this.filter('facebook'))}>
            <i className='fab fa-facebook-f'></i>
          </div>
          <div className='toolbarCalendarInstagram' onClick={()=>(this.filter('instagram'))}>
            <i className='fab fa-instagram'></i>
          </div>
          <div className='toolbarCalendarLinkedin' onClick={()=>(this.filter('linkedin'))}>
            <i className='fab fa-linkedin'></i>
          </div>
          <div className='toolbarCalendarAll' onClick={()=>(this.filter('all'))}>
            <p>ALL</p>
          </div>
        </div>
        <div className='toolbarCalendarNavigate'>
          <div className='toolbarCalendarBack' onClick={goToBack}>
            <i className="fas fa-chevron-left" />
          </div>
          <label className='toolbarCalendarLabel'>{label()}</label>
          <div className='toolbarCalendarNext' onClick={goToNext}>
            <i className="fas fa-chevron-right" />
          </div>
        </div>
        <div className='toolbarCalendarNew'>
          <button
            className={`${!userPermissions['New Week'] && 'bp3-disabled'}`} 
            onClick={()=>(!userPermissions['New Week'] ? this.error.show() : this.newWeek.show())}>
            NEW WEEK
          </button>
          <button
            className={`${!userPermissions['New Event'] && 'bp3-disabled'}`} 
            onClick={()=>(!userPermissions['New Event'] ? this.error.show() : this.newEvent.show({vision: this.props.vision_enabled}))}>
            NEW EVENT
          </button>
        </div>
      </div>
    )
  }

  componentWillMount(){
    this.getCalendar()
    this.getPosts()
  }

  render () {
    const localizer = BigCalendar.momentLocalizer(moment)
    const { userPermissions } = this.props

    return(
      <div className='calendar'>
        <Alert
          confirm 
          body='Your plan doesn’t have the required credentials to access this section. Please call you brand manager for further assistance.' 
          ref={(alert)=>(this.error = alert)} 
        />
        <BigCalendar
          views={{ month: true }}
          defaultDate={new Date()}
          defaultView="month"
          drilldownView={null}
          events={this.state.events}
          localizer={localizer}
          components={{
            dateCellWrapper: ({value, children, range}) => {
              let events = _.filter(this.state.events, (event)=>{
                return moment(event.startsAt).isSame(value, 'day')
              })
              events = _.orderBy(events, 'startsAt', 'asc')

              return (
                <Events 
                  {...children.props} 
                  events={events} 
                  show={(event)=>(this.newEvent.show({...event, vision: this.props.vision_enabled}))}
                />
              )
            },
            eventWrapper: (props) => {
              return (
                <div />
              )
            },
            month: {
              header: ({label})=>{
                return (
                  <p className='calendarHeader'>{label}</p>
                )
              },
              event: ({event})=>{
                return (
                  <div />
                )
              },
              dateHeader: ({isOffRange, label, date})=>{
                let events = _.filter(this.state.events, (event)=>{
                  return moment(event.startsAt).isSame(date, 'day')
                })

                return (
                  !isOffRange ?
                  <div className='calendarDayHeader'>
                    <div className='dayHeaderEvents'>
                      {events.length > 0 &&
                        <p>
                          {events.length}
                        </p>
                      }
                    </div>
                    <p className='dayHeaderNumber'>
                      {label}
                    </p>
                  </div>:
                  <div />
                )
              }
            },
            toolbar: this.customToolbar
          }}
          dayPropGetter={(date)=>{
            return {
              className: 'calendarDay'
            }
          }}
          style={{ height: '100vh' }}
        /> 

        <NewEvent
          onDelete={this.getCalendar} 
          onCreate={this.getCalendar} 
          onUpdate={this.getCalendar}
          permissions={userPermissions}
          posts={this.state.posts}
          ref={(ref)=>(ref && (this.newEvent = ref.getWrappedInstance()))} 
        />
        <NewWeek
          onAccept={this.getCalendar}
          ref={(newWeek)=>(this.newWeek = newWeek)} 
        />
      </div>
    )
  }
}
export default Calendar
