import React, { Component } from 'react'
import _ from 'lodash'
import Chart from './components/Chart'
import Panel from './components/Panel'
import './styles.css'

class Analytics extends Component {
  constructor(props){
    super(props)
    this.state = {
      metric: {
        type: 'er',
        data: {}
      },
      date: 'daily'
    }

    this.onToggleChart = this.onToggleChart.bind(this)
    this.onChangeMetric = this.onChangeMetric.bind(this)
    this.onSwitch = this.onSwitch.bind(this)
  }

  onSwitch(date){
    this.setState({
      date: date
    }, ()=>{
      const { date, metric } = this.state
      this.onChangeMetric(metric)
    })
  }

  onChangeMetric(metric){
    const { date } = this.state

    if(!_.isEqual(metric, this.state.metric)){
      this.setState({
        metric: metric
      })
    }

    this.chart && this.chart.onUpdateChart && this.chart.onUpdateChart(metric, date)
  }

  onToggleChart(state){
    this.chart && this.chart.toggle && this.chart.toggle(state)
  }
  
  render () {
    const { date } = this.state

    return (
      <div id="socialAnalytics" className="grid">
        <div className="col-4_sm-12">
          <Panel 
            {...this.props}
            onChange={this.onChangeMetric}
            onToggleChart={this.onToggleChart}
          />
        </div>
        <div className="col-8_sm-12">
          <Chart 
            {...this.props}
            ref={(chart)=>(this.chart = chart)}
            onSwitch={this.onSwitch}
            date={this.state.date}
            metric={this.state.metric}
          />
        </div>
      </div>
    )
  }
}

export default Analytics