import React, { Component } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

import { withRouter } from 'react-router-dom'

import './styles.css'

class Print extends Component {
  print(){
    const main = document.querySelector('[data-html2canvas-print]')
    const positionInfo = main.getBoundingClientRect()

    html2canvas(main, {
      allowTaint: false,
      useCORS: true,
      width:  positionInfo.width,
      backgroundColor: '#2A2A2A',
      scale: 1
    }).then((canvas)=>{
      let width = canvas.width
      let height = canvas.height
      let image = canvas.toDataURL()

      var doc = new jsPDF({
        orientation: width > height ? 'l' : 'p',
        unit: 'px',
        format: width > height ? [height, width] : [width, height]
      })

      var widthInternal = doc.internal.pageSize.width    
      var heightInternal = doc.internal.pageSize.height

      doc.addImage(image, 'PNG', 0, 0, widthInternal, heightInternal)
      doc.save('capture.pdf')
    })
  }

  render () {
    const { pathname } = this.props.location
    return(
      pathname != '/home' && pathname != '/' && pathname.search('dashboard') == -1
      ? <div data-html2canvas-ignore id="print" onClick={()=>(this.print())}> 
          <i className="fas fa-print"></i>
        </div>
      : <div />
    )
  }
}

export default withRouter(Print)