import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'


import './styles.css'

class DropDown extends Component {
  render () {
    return(
      <div className='dropdownContainer'>
        <Dropdown text={this.props.title} floating button {...this.props}>
          <Dropdown.Menu>
            {this.props.children}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
}

export default DropDown