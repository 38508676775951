import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import _ from 'lodash'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import IndustryError from '../../Shared/IndustryError'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class ERComp extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    return storage.get('page')
    .then((page)=>{
      return axios.get(`brandSettings/${page}`)
    })
  }

  chart(data){
    const settings = data.industry_settings
    data = _.hasIn(settings, 'names') 
    ? _.concat(settings.names.brand, settings.names.competitors)
    : []
    data = _.compact(data)

    const container = React.createElement('div', {
      id: 'widget'
    })

    if(data.length > 0){
      setTimeout(()=>{
        const keywords = _.map(data, (item)=>{
          return {
            "keyword": item,
            "geo":"MX",
            "time":"today 3-m"
          }
        }) 
        const container = document.getElementById('widget')
        container.innerHTML = ''

        const script = document.createElement('script')
        script.src = "https://ssl.gstatic.com/trends_nrtr/1709_RC01/embed_loader.js"
        script.async = true

        container.appendChild(script)
        
        script.onload = ()=>{
          window.trends.embed.renderExploreWidgetTo(container, "TIMESERIES", {"comparisonItem": keywords.slice(0, 4),"category":0,"property":""}, {"exploreQuery":"date=all&q=bmw","guestPath":"https://trends.google.com:443/trends/embed/"})
        }
      },100)
    }


    return data.length > 0 ? container : <IndustryError />
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="trendingSearchTrend">
        <Title description='Búsquedas de la marca y la competencia, que se han realizado en el país seleccionado.' title='Search Trends' />
         <div className='trendingSearchTrendContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default ERComp
