import React, { Component } from 'react'
import { Colors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"
import numeral from 'numeral'
import moment from 'moment'
import Chart from '../../Shared/Chart'
import _ from 'lodash'
import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

const icons = {
  desktop: 'fas fa-desktop',
  mobile: 'fas fa-mobile-alt',
  tablet: 'fas fa-tablet-alt'
}
class Devices extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        columns: [],
        rows: [],
        serialized: [{}]
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:pageviews',
          dimensions: 'ga:deviceCategory'
        }
      })
    })
  }

  chart(data){
    const { serialized, columns, rows } = data
    const total = _.sumBy(serialized, 'Pageviews')

    return (
      <div className="googleDevicesChart">
        {serialized.map((item, index)=>{
          const percent = Math.round(item.Pageviews/(total/100))
          const background = Colors[index]
          const icon = icons[item['Device Category']]

          return (
            <div 
              className={`googleDevices${item.Devices}`}
              style={{
                width: `${percent}%`,
                color: background == '#CCFF33' ? '#36383a' : '#ffffff',
                backgroundColor: background
              }}>
              <i className={icon} />
              <p>{percent}%</p>
            </div>
          )
        })}
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id='googleDevices'>
        <Title description='Porcentaje de dispositivos (Celulares, Tabletas o Web) que visitan el sitio web.' title='Devices' />
        <div className="googleDevicesContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )

  }
}

export default Devices
