import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import { Colors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Truncate from '../../Shared/Truncate'
import numeral from 'numeral'
import _ from 'lodash'
import SocialIcons from '../../Shared/SocialIcons'

import logo from '../../../assets/img/logo.png'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Benchmark extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getPercentageChange(oldNumber, newNumber){
    if(newNumber > oldNumber){
      const decreaseValue = newNumber - oldNumber
      return numeral(Math.abs((decreaseValue / newNumber) * 100)).format('0,0')
    }else{
      const decreaseValue = oldNumber - newNumber
      return numeral(Math.abs((decreaseValue / oldNumber) * 100)).format('0,0')
    }
    
  }

  getData(range){
    return storage.get('page')
    .then((page)=>{
      return axios.get(`industry/benchmark/${page}`)
    })
  }

  chart(data){
    const titles = {
      er: 'ER',
      numberOfPosts: 'Posts',
      followers: 'Followers',
      reactions: 'Reactions',
      comments: 'Comments',
      shares: 'Shares'
    }

    return (
      <div>
        {data.map((item, index)=>{
          const { brand } = item

          return (
            <div className="trendingBenchmarkData">
              <div className="trendingBenchmarkDataAvatar">
                <div
                  style={{
                    backgroundImage: `url(${brand.image})`
                  }} 
                  className='trendingBenchmarkAvatar'>
                  {(!brand.image || brand.image == '') && (
                    <SocialIcons provider='facebook' />
                  )}
                </div>
                <p className='trendingBenchmarkName'>{brand.name || brand.facebook}</p>
              </div>
              <div className="trendingBenchmarkDataInfoContainer">
                <div className="trendingBenchmarkDataInfo">
                  {_.map(titles, (title, key)=>{
                    const max = _.maxBy(data, `data.${key}`)
                    const value = item.data[key]
                    return (
                      <div className={`trendingBenchmarkItem ${max.brand.facebook == item.brand.facebook && 'first'}`}>
                        <p className='trendingBenchmarkItemTitle'>{title}</p>
                        <p className='trendingBenchmarkItemValue'>{numeral(value).format('0,0[.]00')}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>
       {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }
  
  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="trendingBenchmark">
        <Title title='Benchmark' />
        <div className='trendingBenchmarkContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default Benchmark
