import React, { Component } from 'react'
import { 
  Spinner,
  Dialog 
} from "@blueprintjs/core"
import _ from 'lodash'
import axios from '../../../services/axios'
import { API_URL } from '../../../config'
import SocialIcons from '../../Shared/SocialIcons'
import LoadingCharts from '../../Shared/LoadingCharts'


import './styles.css'

class ListPages extends Component {
    constructor(props){
      super(props)
      this.state = {
        visible: false,
        title: '',
        pages: [],
        active: -1,
        provider: '',
        loading: false
      }

      this.access_token = ''  
      this.resolve = null
      this.reject = null

      this.onSelect = this.onSelect.bind(this)
      this.show = this.show.bind(this)
      this.hide = this.hide.bind(this)
      this.list = this.list.bind(this)
    }


    onSelect(){
     this.resolve && this.resolve({
      provider: this.state.provider,
      data: this.state.pages[this.state.active]
     })
     this.hide() 
    }

    show(access_token, provider){
      this.setState({
        loading: true,
        visible: true,
        provider: provider,
        title: `Select Your ${provider} ${provider == 'youtube' ? 'channel' : 'Page'}` 
      })

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        this.access_token = access_token

        axios.get('marcas/accounts/pages', {
          params: {
            access_token,
            provider
          }
        }).then((response)=>{
          this.setState({
            loading: false,
            pages: response.data.data
          })
        })
      })
    }

    hide(){
      this.setState({
        visible: false,
        title: '',
        pages: [],
        active: -1,
        provider: '',
        loading: false
      })
    }
    
    list(){
      const errors = {
        facebook: 'No facebook pages are associated with this account.',
        instagram: 'You need to link your instagram account, to the linked facebook page.',
        linkedin: 'No linkedin pages are associated with this account.',
        youtube: 'No youtube channels are associated with this account.'
      }
      return (
        this.state.pages.length > 0 ?
        <div className="listPagesItems">
          {this.state.pages.map((page, index)=>{
            return (
              <div
                onClick={()=>(this.setState({active: index}))} 
                className={this.state.active == index ? 'listPagesItemActive' : 'listPagesItem'}>
                <div style={{backgroundImage: `url(${page.url_picture || page.avatar })` }} />
                <p>{page.name}</p>
              </div>
            )
          })}
          {_.range(this.state.pages.length % 3, 1).map((page, index)=>{
            return (
              <div className='listPagesItemPlaceholder' />
            )
          })}
        </div> :
        <div className="listPagesItemsError">
          <p>{errors[this.state.provider]}</p>
        </div>
      )
    }

    render() {
      return(
        <Dialog
          isOpen={this.state.visible}
          className='listPages'
        >
          <div className='listPagesContainer'>
            <div className="bp3-dialog-header listPagesHeader">
              <SocialIcons provider={this.state.provider} className='listPagesProvider' />
              <h4 className="bp3-heading">{this.state.title}</h4>
              <i className="fas fa-times" onClick={this.hide}></i>
            </div>
            <div className="bp3-dialog-body">
              {this.state.loading ? (
                <div className="listPagesLoading">
                  <LoadingCharts />
                </div>
              ) : (
                this.list()
              )}
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <button
                  disabled={this.state.active == -1}
                  onClick={this.onSelect} 
                  type="button" 
                  className="bp3-button listPagesSelect">
                  Select
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      )
    }
}

export default ListPages
