import React, { Component } from 'react'
import { Card, Elevation, Spinner } from '@blueprintjs/core'

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import axios from '../../../../../services/axios'
import storage from '../../../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../../../Shared/LoadingCharts'
import Title from '../../../../Shared/TitleChart'
import Reload from '../../../../Shared/ReloadChart'
import Error from '../../../../Shared/ErrorChart'
import Metrics from './components/Metrics'

import './styles.css'

class Panel extends Component {
  constructor(props){
    super(props)

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.onActiveMetric = this.onActiveMetric.bind(this)
    this.getData = this.getData.bind(this)
    this.data = {}
  }

  getData(range, filter){
    const { competitor, onToggleChart } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      onToggleChart('hidden')
      return axios.get(`/content/${page}/analytics?since=${since}&until=${until}&type=w&&is_brand=${competitor == 'brand'}&provider=${filter}`)
    }).then((response)=>{
      onToggleChart('show')
      return response
    })
  }

  onActiveMetric(metric, category){
    const { onChange, onUpdateChart } = this.props
    const actives = document.querySelectorAll('.socialAnalyticsPanelCategories div[class*=active]')
    const active_keys = _.map(actives, (active)=>(active.dataset.key))
    const element = document.querySelector(`[data-key="${category}.${metric}"]`)

    if(metric == 'er'){
      document.querySelector('div[data-key=er]').classList.add('active')
      _.forEach(actives, (element)=>(element.classList.remove('active')))

      onChange({
        type: 'er',
        data: this.data.er
      })

      this.metrics.onActive({
        type: 'er'
      })
    }else{
      let metrics = []
      const has_category = active_keys.join(' ').includes(category)
      const has_metric = active_keys.join(' ').includes(`${category}.${metric}`)

      if(!(active_keys.length == 1 && active_keys[0] == `${category}.${metric}`)){
        if(has_metric){
          element.classList.remove('active')
          metrics = [metric == 'organic' ? 'paid' : 'organic']
        }else if(has_category){
          element.classList.add('active')
          metrics = ['organic', 'paid']
        }else{
          element.classList.add('active')
          metrics = [metric]
          _.forEach(actives, (element)=>(element.classList.remove('active')))
        }
        
        onChange({
          type: category,
          metrics: metrics,
          data: this.data[category]
        })

        this.metrics.onActive({
          type: category,
          metrics: metrics
        })
      }
    }
  }

  success({ response, load }){
    const { onChange } = this.props

    this.data = response.data.data

    onChange({
      type: 'er',
      data: this.data.er
    })

    return (
      <Metrics
        {...this.props}
        data={this.data}
        ref={(metrics)=>(this.metrics = metrics)}
        onActiveMetric={this.onActiveMetric}
      />
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialAnalyticsPanel">
        <Title title='Analytics' />
         <div className='socialAnalyticsPanelContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default Panel
