import React, { Component } from 'react'
import { Card, Elevation, Position, H5, H6  } from "@blueprintjs/core"

import logoSmart from '../../../../../assets/img/logo.png'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import { Tooltip } from 'react-tippy'

import Truncate from '../../../../Shared/Truncate'
import SocialIcons from '../../../../Shared/SocialIcons'
import IconType from '../../../../Shared/IconType'
import axios from '../../../../../services/axios'
import storage from '../../../../../services/storage'

import Swiper from 'react-id-swiper'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../../../Shared/LoadingCharts'
import Title from '../../../../Shared/TitleChart'
import Reload from '../../../../Shared/ReloadChart'
import Error from '../../../../Shared/ErrorChart'
import './styles.css'

class Ads extends Component {
  constructor(props){
    super(props)

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
    this.ads = this.ads.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range, campaing){

    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      return axios.get(`https://adsapi.smartintel.io/api/ads/ads/${page}?since=${since}&until=${until}&domain=${window.location.hostname}&campaings=${this.props.campaings}&campaing_id=${campaing}`)
    }).then((response)=>{
      const data = response.data.data
      return data
    })

    /*return new Promise((resolve, reject) => {
      setTimeout(()=>{
        const ads = Array.isArray(this.props.ads) ? this.props.ads : []
        resolve(ads)
      }, 100)
    })*/
  }

  ads(data){
    return (
      <div className='adsScoreAdsContainer'>
        <Swiper 
          effect='fade'
          rebuildOnUpdate
          autoHeight
          spaceBetween={0}
          pagination={{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          }}
          ref={(swiper)=>(swiper && (this.swiperPosts = swiper.swiper))}
        >
          {data.slice(0, 5).map((ad, index)=>{
            const post = _.has(ad, 'post')  ? ad.post : {}
            const creative = _.has(ad, 'adcreatives[0]') ? ad.adcreatives[0] : {}

            return (
              <div>
                <Card 
                  interactive={false} 
                  elevation={Elevation.TWO} 
                  className="postContainer">
                  <div className="postIcon">
                    <SocialIcons provider='facebook' />
                  </div>
                  <div className="post">
                    <div className="post-topContainer">
                      <div className="postImage">
                        <a href={`https://www.facebook.com/${post.id || creative.effective_object_story_id}`} target="_blank">
                          <img src={ad.image_url} alt="" />
                        </a>
                      </div>
                      <div className="postExtras">
                        <p className="postExtrasType">{!!ad.post ? ad.post.type : ''}</p>
                        <div className="postExtrasEngagment">
                          <H6>
                            ADs Score
                          </H6>
                          <p>
                            {numeral(ad.es_score).format('0,0.00')}%
                          </p>
                        </div>
                        <div className="postExtrasEngagment">
                          <H6>
                            Objective
                          </H6>
                          <p>
                            {ad.objective}
                          </p>
                        </div>
                        <div className="postExtrasEngagment">
                          <H6>
                            Status
                          </H6>
                          <p>
                            {ad.status}
                          </p>
                        </div>
                        <div className="postExtrasEngagment">
                          <H6>
                            Campaign Name
                          </H6>
                          <p>
                            {ad.campaign_name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="postMessage">
                      <Truncate text={post.message || creative.body} />
                    </div>
                    <div className="postDate">
                      <p> 
                        <img src={logoSmart} alt="Logo Smart" className="logoSmartPost" />
                        {moment(ad.created_time).format("llll")}
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            )
          })}
        </Swiper>
        {data.length > 1 && (
          <div>
            <div 
              className="adsScoreAdsPrev">
              <i 
                onClick={()=>(this.swiperPosts && this.swiperPosts.slidePrev())} 
                className="fas fa-caret-left"
              />
            </div>
            <div
              className="adsScoreAdsNext">
              <i
                onClick={()=>(this.swiperPosts && this.swiperPosts.slideNext())}  
                className="fas fa-caret-right"
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  success({ response, load }){
    const data = response

    return (
      this.ads(data)
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.campaing)
    })    

    return(
      <div className='adsScoreAds'>
        <Chart loadOnMount>
          <Chart.Loading>
            {this.loading()}
          </Chart.Loading>
          <Chart.Success>
            {this.success}
          </Chart.Success>
          <Chart.Error>
            {this.error}
          </Chart.Error>
        </Chart>
      </div>
    )
  }
}

export default Ads
