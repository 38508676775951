import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import axios from '../../../services/axios'
import storage from '../../../services/storage'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import { Competitors } from '../../../config'
import logo from '../../../assets/img/logo.png'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import IndustryError from '../../Shared/IndustryError'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class PageViews extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    return Promise.all([storage.get('page'), storage.range()])
    .then((responses)=>{
      const since = moment(responses[1][0]).format('YYYY-MM-DD')
      const until = moment(responses[1][1]).format('YYYY-MM-DD')

      return axios.get(`industry/alexa/${responses[0]}?since=${since}&until=${until}`)
    }).then((response)=>{
      return _.orderBy(response.data.data, 'traffic.value', 'desc')
    })
  }

  chart(data){
    return data.length > 0 ? (
      data.map((item, index)=>{
        return (
          <div className={`trendingPageViewsItem ${Competitors < 5 && 'trendingPageViewsItemFull'}`}>
            <div className="trendingPageViewsCompetitor">
              <p className="trendingPageViewsCompetitorValue">{item.traffic.value}</p>
              <p className="trendingPageViewsCompetitorStatus">
                <i className={item.traffic.state == 'positive' ? 'fas fa-caret-up' : 'fas fa-caret-down'}></i>
                 {numeral(item.traffic.delta).format('0,0[.]00')}
              </p>
              <div className="trendingPageViewsCompetitorImage">
               <img src={!item.brand.image || item.brand.image == "" ? logo : item.brand.image} alt="logo" />
              </div>
            </div>
          </div>
        )
      })
    ) : (
      <IndustryError />
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="trendingPageViews">
        <Title description='El indice de paginas visitadas por usuario único la marca y su competencia. Se mide el incremento o decremento que se tiene contra el mes pasado.' title='Page Views / Visitor' />
         <div className="trendingPageViewsContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default PageViews
