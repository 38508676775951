import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"
import Color from 'color'

import zingchart from 'zingchart'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import axios from '../../../../../services/axios'
import storage from '../../../../../services/storage'
import { Colors } from '../../../../../config'

import Loading from '../../../../Shared/LoadingCharts'
import Title from '../../../../Shared/TitleChart'
import Chart from '../../../../Shared/Chart'

import './styles.css'

class ChartAnalytics extends Component {
  constructor(props){
    super(props)

    this.loading = this.loading.bind(this)

    this.onUpdateChart = this.onUpdateChart.bind(this)
    this.getData = this.getData.bind(this)
    this.generateDataChart = this.generateDataChart.bind(this)
    this.toggle = this.toggle.bind(this)
  }


  toggle(state){
    const graph = document.querySelector('.socialAnalyticsChartGraph')
    const loading = document.querySelector('.socialAnalyticsChartLoading')

    if(state == 'show'){
      graph.setAttribute('data-show', 'true')
      loading.setAttribute('data-show', 'false')
    }else{
      graph.setAttribute('data-show', 'false')
      loading.setAttribute('data-show', 'true')
    }
  }

  generateDataChart({type, data, metrics}, date){
    let labels = []
    let values = []

    if(!_.isEmpty(data)){
      if(type == 'er'){
        let dates = data.dates[date]
        dates = _.groupBy(dates, 'date')
        dates = _.map(dates, (data, index)=>{
          const value = _.sumBy(data, (date)=>(parseFloat(date.value)))
          return {
            date: index,
            value: value
          }
        })

        labels = _.map(dates, 'date')
        values = [{values: _.map(dates, 'value')}]
      }else if(type == 'interactions'){
          const organic_metrics = data.organic.metrics
          const paid_metrics = data.paid.metrics

          const interactions = _.mapValues(organic_metrics, (dates, interaction)=>{        
            dates = _.reduce(metrics, (all, metric)=>{
              return _.concat(all, data[metric]['metrics'][interaction].dates[date])
            }, [])

            dates = _.groupBy(dates, 'date')
            dates = _.map(dates, (data, index)=>{
              const value = _.sumBy(data, (date)=>(parseInt(date.value)))
              return {
                date: index,
                value: value
              }
            })

            return dates
          })

          labels = _.reduce(interactions, (all, interaction)=>{
            all = _.concat(all, _.map(interaction, 'date'))
            return _.uniq(all)
          }, [])

          values = _.map(interactions, (interaction, key)=>{
            return {
              values: _.map(interaction, 'value'),
              'data-interaction': key.toUpperCase()
            }
          })
      }else{
        let dates = _.reduce(metrics, (all, metric)=>{
          return _.concat(all, data[metric].dates[date])
        }, [])
        dates = _.groupBy(dates, 'date')
        dates = _.map(dates, (data, index)=>{
          const value = _.sumBy(data, (date)=>(parseInt(date.value)))
          return {
            date: index,
            value: value
          }
        })
        
        labels = _.map(dates, 'date')
        values = [{values: _.map(dates, 'value')}]
      }
    }

    const custom =  _.map(Colors, (item, index)=>{
      return {
        'plot-rules': {
          rule: `%p==${index}`,
          lineColor: Colors[index]
        },
        'marker-rules': {
          rule: `%p==${index}`,
          borderColor: Colors[index],
          backgroundColor: Colors[index]
        },
        'plot-labels': {
          rule: `%p==${index}`,
          borderColor: Colors[index],
          text: `<span style="font-weight:bold"><div style="width: 10px; height: 10px; background-color: ${Colors[index]}; display: inline-block"></div> %v <br/> %kl</span>`
        },
        'plot-labels-iteractions': {
          rule: `%p==${index}`,
          borderColor: Colors[index],
          text: `<span style="font-weight:bold"><div style="width: 10px; height: 10px; background-color: ${Colors[index]}; display: inline-block"></div> %v <br/> %kl <br/> %data-interaction</span>`
        }
      }
    })

    return {
      type: 'line',
      backgroundColor: '#2A2A2A',
      gui: {
        behaviors: [{
          id: 'ViewSource',
          enabled: 'none'
        },{
          id: 'HideGuide',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'ViewAsPng',
          enabled: 'none'
        },{
          id: 'ViewDataTable',
          enabled: 'none'
        }]
      },
      globals: {
        fontFamily: 'Helvetica'
      },
      plotarea:{ 
        backgroundColor : '#2A2A2A',
        adjustLayout: true,
        margin: 0,
        width: '98%',
        x: '3%'
      },
      scaleY: {
        lineStyle: 'solid',
        thousandsSeparator: ',',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          fontSize: '0px'
        }
      },
      scaleX: {
        values: _.map(labels, (label)=>(moment(label, 'YYYY-MM-DD').format('DD/MMM/YY'))),
        lineStyle: 'solid',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF',
          fontSize: '9px'
        }
      },
      plot: {
        cursor: 'hand',
        marker: {
          size: 0,
          rules: _.map(custom, 'marker-rules'),
          borderColor: 'none'
        },
        rules: _.map(custom, 'plot-rules')
      },
      tooltip: {
        text: '%v',
        multiple: true,
        fontSize: '12px',
        color: '#FFFFFF',
        backgroundColor: '#000',
        borderWidth: 0,
        alpha: 0.6,
        callout: true,
        calloutPosition: 'bottom',
        shadow: 0,
        borderRadius: 4,
        rules: _.map(custom, type == 'interactions' ? 'plot-labels-iteractions' : 'plot-labels'),
        placement: 'node:top',
        calloutPosition: 'bottom',
        thousandsSeparator: ',',
        offsetY: 8
      },
      series: values
    }
  }

  onUpdateChart(metric, date){
    const config = this.generateDataChart(metric, date)
    const { id } = this.chart

    zingchart.exec(id, 'setdata', {
      data: config,
      update: true
    })
  }

  getData(range, filter, date){
    const { competitor, metric } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return Promise.resolve({})
  }

  loading(){
    return (
      <Loading />
    )
  }

  shouldComponentUpdate(props){
    return false
  }

  render() {
    const { metric, date } = this.props
    const config = this.generateDataChart(metric, date)

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialAnalyticsChart">
        <Title 
          left={(
            <div 
              ref={(inputs)=>(this.inputs = inputs)}
              className="socialAnalyticsSwitch">
              <label 
                onClick={()=>(this.props.onSwitch('daily'))}>
                <input
                  defaultChecked={true}
                  type="radio" 
                  name="socialAnalyticsDate" 
                  value="daily" />
                  <p>Daily</p>
              </label>
              <label
                onClick={()=>(this.props.onSwitch('weekly'))}>
                <input 
                  type="radio" 
                  name="socialAnalyticsDate"
                  value="weekly" />
                  <p>Week</p>
              </label>
            </div>
          )} 
          title='Analytics' 
        />
        <div className='socialAnalyticsChartContainer'>
          <div className="socialAnalyticsChartGraph" data-show="false">
            <Chart 
              events={{
                load: (chart)=>(this.chart = chart)
              }}
              width="100%" 
              height="410px"
              output="canvas"
              data={config} 
            />
          </div>
          <div className="socialAnalyticsChartLoading" data-show="true">
            <Loading />
          </div>
        </div>
      </Card>
    )
  }
}

export default ChartAnalytics
