import React, { Component } from 'react'
import { Button, Popover, Position, Tooltip, Classes } from "@blueprintjs/core"

import './styles.css'

class TitleChart extends Component {
  render () {
    return(
    	<div className='titleChart'>
        {this.props.left}
	    	<p className='titleChartTitle'>{this.props.title}</p>
	    	{!!this.props.description && (
          <Tooltip 
          className='titleChartTooltip'
          content={this.props.description}>
            <i className="fas fa-question-circle" data-html2canvas-ignore />
          </Tooltip>
        )}
	    </div>
    )
  }
}

export default TitleChart