import React, {Component} from 'react'

import './styles.css'

class IndustryError extends Component {
  render () {
    return(
      <div className='IndustryError'>
      	<p>Configure industry settings for this widget</p>
      </div>
    )
  }
}

export default IndustryError
