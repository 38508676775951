import React, { Component } from 'react';
import { 
  Dialog,
  Switch,
  Tag,
  Elevation,
  Button,
  Icon,
  FormGroup,
  InputGroup,
  Classes
} from "@blueprintjs/core"
import { connect } from 'react-redux'
import _ from 'lodash'
import { 
  get, 
  add, 
  edit,
  remove
} from '../../../../../actions/categories'
import Alert from '../../../Alert'
import CategoryDialog from '../CategoryDialog'
import './styles.css'

class Categories extends Component {
  constructor(props){
    super(props)
    this.state = {
      edit: '',
      exclude: false,
      id: undefined
    }

    this.onConfirm = this.onConfirm.bind(this)
    this.onAdd = this.onAdd.bind(this)
    this.onRemove = this.onRemove.bind(this)
  }

  componentWillMount(){
    this.props.get()
  }

  onAdd(id = undefined, edit = '', exclude = false){
    this.setState({
      edit: edit,
      id: id,
      exclude: exclude
    }, this.categoryDialog.show)
  }

  onRemove(id){
    this.alert.show()
    .then((response)=>{
      this.props.remove(id)
    }).catch((error)=>{

    })
  }

  onConfirm(value, exclude){
    const { id } = this.state
    id ? this.props.edit(id, value, exclude) : this.props.add(value, exclude)

    this.setState({
      edit: '',
      exclude: false,
      id: undefined
    })
  }

  render() {
    const { categories } = this.props

    return (
      <div className="sidebar-categories">
        <Alert 
          ref={(alert)=>(alert && (this.alert = alert))}
          body='Do you want to delete this category?'
        />
        <CategoryDialog
          defaultValue={this.state.edit} 
          edit={!!this.state.edit}
          exclude={this.state.exclude}
          onConfirm={this.onConfirm}
          type='category'
          ref={(dialog)=>(dialog && (this.categoryDialog = dialog))}
        /> 
        <div className="sidebar-title sidebar-title__left">
          Categories
        </div>
        <div className="sidebarCategoriesContainer">
          {categories.map((category, index)=>{
            return (
              <p className="sidebarTag" data-exclude={category.exclude}>
                <span className="sidebarTagName">{category.name}</span>
                {category.id != 1 && (
                  <span className="sidebarTagEdit" onClick={()=>(this.props.lock ? this.props.onErrorPermissions() : this.onAdd(category.id, category.name, category.exclude))}>
                    <i className="fas fa-pen"></i>
                  </span>
                )}
                {category.id != 1 && (
                  <span className="sidebarTagDelete" onClick={()=>(this.props.lock ? this.props.onErrorPermissions() : this.onRemove(category.id))}>
                    <i className="fas fa-times"></i>
                  </span>
                )}
              </p>
            )
          })}
          <Button 
            onClick={()=>(this.props.lock ? this.props.onErrorPermissions() : this.onAdd())} 
            minimal="true"
            className="button" >
            <Icon icon="add" elevation={Elevation.TWO} />
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: state.categories.categories
  }
}

export default connect(
  mapStateToProps, { 
  get, add, remove, edit
})(Categories)