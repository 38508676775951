import React, { Component } from 'react'
import { List as ListComponent } from 'react-virtualized'
import _ from 'lodash'
import Post from '../Post/Post'
import { showMetrics } from '../../../config'

class List extends Component {
  constructor(props){
    super(props)
    this.state = {
      posts: this.props.posts,
      originPosts: this.props.posts,
      resize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    }

    this.setPosts = this.serPosts.bind(this)
    this.getPosts = this.getPosts.bind(this)
    this.onResize = this.onResize.bind(this)
  }

  componentWillMount(){
    window.addEventListener('resize', _.debounce(this.onResize, 500))
  }

  componentDidUnmount(){
    window.addEventListener('resize')
  }
  
  onResize(event){
    this.setState({
      resize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    })
  }

  getPosts(){
    return this.state.originPosts
  }

  serPosts(posts){
    this.setState({
      posts: posts
    })
  }

  render(){
    const { userPermissions} = this.props
    const count = Math.floor((this.state.resize.width - 86)/368)
    const rows = _.chunk(this.state.posts, count)

    return (
      <ListComponent
        width={this.state.resize.width - 86}
        height={this.state.resize.height - 160}
        rowHeight={showMetrics() ? 492 : 430}
        rowCount={rows.length}
        rowRenderer={({key, index, isScrolling, isVisible, style})=>{
          const posts = rows[index]
          return (
            <div
              className='postsContainerRow'
              key={key} 
              style={style}>
                {posts.map((post)=>{
                    return (
                        <Post
                            onErrorPermissions={()=>(this.error.show())}
                            lock={!userPermissions['Categorize']} 
                            onChangeCategory={()=>{}}
                            key={post.id}
                            {...post}
                        />
                    )
                })}
                {_.range(count - posts.length).map(()=>{
                    return (
                        <div className="postsContainerRowDummy" />
                    )
                })}
            </div>
          )}}/>
        )
    }
}

export default List