import React, {Component} from 'react'
import { 
  Button, 
  Card, 
  Classes, 
  Elevation, 
  Dialog, 
  Tooltip, 
  Position 
} from '@blueprintjs/core'

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import { Colors } from '../../../config'
import Chart from '../../Shared/Chart'

import Section from './components/Section'
import SectionDialog from './components/SectionDialog'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class TopicData extends Component {
  constructor(props){
    super(props)
    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
    this.onAddCategory = this.onAddCategory.bind(this)
    this.panel = this.panel.bind(this)
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range, filter){
    const { competitor } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      return axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/palabras/${page}?since=${since}&until=${until}&provider=${filter}`)
    })
  }
    
  chart(data){
    const custom =  _.map(data, (item, index)=>{
      return {
        'plot-rules': {
          rule: `%i==${index}`,
          backgroundColor: Colors[index]
        },
        'plot-labels': {
          rule: `%i==${index}`,
          borderColor: Colors[index],
          text: `<span style="font-weight:bold"><div style="width: 10px; height: 10px; background-color: ${Colors[index]}; display: inline-block"></div> ${item.count}</span>`
        }
      }
    })

    let config = {
      type: 'bar',
      backgroundColor: '#363737',
      gui: {
        behaviors: [{
          id: 'ViewSource',
          enabled: 'none'
        },{
          id: 'HideGuide',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'ViewAsPng',
          enabled: 'none'
        },{
          id: 'ViewDataTable',
          enabled: 'none'
        }]
      },
      globals: {
        fontFamily: 'Helvetica'
      },
      plotarea:{ 
        backgroundColor : '#363737',
        adjustLayout: true,
        margin: 0
      },
      scaleY: {
        lineStyle: 'solid',
        thousandsSeparator: ',',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF'
        }
      },
      scaleX: {
        values: _.map(data, (item)=>{
          return item.nombre
        }),
        lineStyle: 'solid',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF'
        }
      },
      plot: {
        cursor: 'hand',
        rules: _.map(custom, 'plot-rules')
      },
      tooltip: {
        text: '%v',
        multiple: true,
        fontSize: '12px',
        color: '#FFFFFF',
        backgroundColor: '#000',
        borderWidth: 0,
        alpha: 0.6,
        callout: true,
        calloutPosition: 'bottom',
        shadow: 0,
        borderRadius: 4,
        rules: _.map(custom, 'plot-labels'),
        placement: 'node:top',
        calloutPosition: 'bottom',
        offsetY: 8
      },
      series: [{
        values: _.map(data, 'count')
      }]
    }

    return (
      <div>
        <Chart 
          width="100%" 
          height="400px"
          output="canvas"
          data={config} 
        />
      </div>
    )
  }

  panel(data){
    return (
      <div className="socialTopicDatasSections">
        <div className="socialTopicDatasSectionsContainer" >
          <div>
            {data.map((topicData)=>{
              return (
                <div
                  className="socialTopicDataSection" 
                  key={topicData.id}>
                  <Section 
                    {...topicData}
                    load={()=>(this.load())} 
                    addWord={this.addWord}
                    deleteWord={this.deleteWord}
                  />
                </div>
              )
            })}
            <div
              className="socialTopicDataSection" 
              onClick={()=>(this.sectionDialog.show())}>
              <div className="socialTopicDataSectionAdd">
                <i className="fas fa-plus topicData-item__addIcon"></i>
              </div>
            </div>
          </div>
        </div>
        <SectionDialog
          onConfirm={this.onAddCategory}  
          ref={(dialog)=>(this.sectionDialog = dialog)}
        />
      </div>
    )
  }

  onAddCategory(name){
    storage.get('page')
    .then((page)=>{
      return axios.post(`${page}/palabras/categorias`,{
        name: name
      })
    }).then((response)=>{
      this.load()
    }).catch((error)=>{

    })
  }

  success({ response, load }){
    const data = response.data.data
    this.load = load

    const Chart = this.chart(data)
    const Panel = this.panel(data)

    return (
      <div>
        {Chart}
        {Panel}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }


  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialTopicData">
        <Title description='Análisis de palabras calves dentro organizada por categorías, de los comentarios en FB de la marca.' title='Topic Data' />
        <div
          className='socialTopicDataContainer'
          ref={(ref)=>(this.chartContainer = ref)}
        >
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default TopicData
