import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import _ from 'lodash'

import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Truncate from '../../Shared/Truncate'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class CompetitionMentions extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    return storage.get('page')
    .then((page)=>{
      return axios.get(`industry/mentions/${page}`)
    }).then((response)=>{
      return response.data.data.slice(0, 5)
    })
  }

  chart(data){
    return (
      <ul>
        {data.map((item, index)=>{
          return (
            <li>
              <a href={`https://twitter.com/${item.user.screen_name}/status/${item.id_str}`}>
                <Truncate 
                  clamp={3} 
                  text={`${index + 1}- ${item.text}`} 
                />
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="trendingMentions">
        <Title description='Listening de conversación digital de la competencia.' title='Mentions Competition' />
         <div className='trendingMentionsContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default CompetitionMentions
