import React, { Component } from 'react'

import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import _ from 'lodash'

import Header from '../components/Shared/Header/Header'
import SidebarContent from '../components/Shared/SidebarContent/SidebarContent'
import Print from '../components/Shared/Print'
import Alert from '../components/Shared/Alert'
import ListPages from '..//components/Home/ListPages'
import ListAds from '..//components/Home/ListAds'
import ListGoogle from '..//components/Home/ListGoogle'
import social from '../services/social'
import Notify from 'react-notification-system'
import storage from '../services/storage'
import axios from '../services/axios'
import { Permissions } from '../config'

import './styles.css'

class Container extends Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      auth: null,
      range: [],
      networks: [],
      userPermissions: [],
      brandPermissions: [],
      providerPermissions: [],
      industrySettings: [],
      vision_enabled: false,
      campaings: null,
      brands: [],
      config: {}
    }

    this.onFixNetwork = this.onFixNetwork.bind(this)
  }

  onFixNetwork(brand, provider){
    social(provider == 'youtube' ? 'google' : provider)
    .then((response)=>{
      switch(provider) {
        case 'facebook':
        case 'instagram':
        case 'linkedin':
        case 'youtube':
          return this.listPages.show(response.token, provider)
          break;
        case 'ads': 
          return this.listAds.show(response.token)
          break;
        case 'google': 
          return this.listGoogle.show(response.token, response)
          break;
        case 'twitter':
          return {
            provider,
            data: response
          }
          break;
      }
    }).then((account)=>{
      let params = {}
      switch(account.provider) {
        case 'facebook':
        case 'instagram':
        case 'linkedin':
        case 'google':
        case 'youtube':
          params = {
            provider: account.provider,
            access_token: account.data.access_token,
            avatar: account.data.avatar,
            account_id: account.data.id,
            name: account.data.name
          }
          break;
        case 'twitter':
          params = {
            provider: account.provider,
            access_token: account.data.token,
            avatar: account.data.avatar,
            access_token_secret: account.data.tokenSecret,
            account_id: account.data.id,
            name: account.data.name
          }
          break;
        case 'ads':
          params = {
            provider: account.provider,
            access_token: account.data.token,
            account_id: account.data.id
          }
          break;
      }
      return axios.post(`marcas/${brand}/accounts/fix`,  params)
    }).then((response)=>{
      window.location.reload()
    }).catch((error)=>{
      console.log(error)
    })
  }

  brandPermissions(permissions){
    permissions = _.filter(permissions, 'access')

    if(permissions.length == 0){
      return 'all'
    }else{
      permissions = _.filter(Permissions, (permission,  index)=>{
        return !!_.find(permissions, { 'name': permission.name });
      })
      permissions = _.orderBy(permissions, 'lock.length', 'desc')
      permissions = _.reduce(permissions, (all, permission, index)=>{
        return index == 0 ? permission.lock : _.intersection(all, permission.lock)
      }, [])

      return permissions
    }
  }

  componentWillMount(){
    const { id } = this.props.match.params    
    const urls = {
      'dashboard' : 'Dashboard',
      'social' : 'Social Intelligence',
      'web' : 'Web Intelligence',
      'trending' : 'Industry Intelligence',
      'calendar' : 'Calendar',
      'ads' : 'Ads Intelligence'
    }

    let page = this.props.location.pathname.split('/')[1]
    page = urls[page]
    let promises = [
      storage.get('auth_token'), 
      storage.range(),
      axios.get('marcas/list'),
      Promise.resolve({})
    ]

    if(id){
      promises = _.concat(
        promises, 
        storage.set('page', id), 
        axios.get(`brandSettings/${id}`),
        axios.get(`user/CampaingsAds?brand_id=${id}`),
      )
    }

    Promise.all(promises)
    .then((responses)=>{
      return {
        auth: true,
        loading: true,
        range: !!responses[1] ? responses[1] : [],
        brands: !!responses[2] ? responses[2].data.data.brands : [],
        config: !!responses[3] ? responses[3] : {},
        vision_enabled: !!responses[5] ? responses[5].data.data.vision_enabled : [],
        networks: !!responses[5] ? responses[5].data.data.networks : false,
        userPermissions: !!responses[5] ? responses[5].data.data.userPermissions : [],
        industrySettings: !!responses[5] ? responses[5].data.data.industry_settings : [],
        brandPermissions: !!responses[5] ? responses[5].data.data.brandPermissions : [],
        settings: !!responses[5] ? responses[5].data.data : {},
        campaings: !!responses[6] ? responses[6].data.data.campaing_ads : null,
      }
    }).then((data)=>{
      const path = this.props.location.pathname
      const ads = _.find(data.networks, {provider: 'ads'})
      const google = _.find(data.networks, {provider: 'google'})
      const providerPermissions = this.brandPermissions(data.brandPermissions)

      const userPermissions = _.reduce(data.userPermissions, (permissions, permission)=>{
        permissions[permission.name] = permission.access
        return permissions
      }, {})

      const brandPermissions = _.reduce(data.brandPermissions, (permissions, permission)=>{
          permissions[permission.name] = permission.access
          return permissions
      }, {})

      switch (true) {
        case path.includes('ads') && ads == undefined:
        case path.includes('web') && google == undefined:
          window.location.href = '/'
          break;
        case !!page && !userPermissions[page]:
        case providerPermissions.includes('ads') && page == 'ads':
        case providerPermissions.includes('google') && page == 'web':
        case !!page && providerPermissions == 'all':
          this.error.show()
          break;
        default:
          this.setState({
            auth: true,
            loading: true,
            networks: data.networks,
            range: data.range,
            userPermissions: userPermissions,
            brandPermissions: brandPermissions,
            providerPermissions: providerPermissions,
            vision_enabled: data.vision_enabled,
            campaings: data.campaings,
            industrySettings: data.industrySettings,
            settings: data.settings,
            brands: data.brands,
            config: data.config
          })
          break
      }
    }).catch((error)=>{
      console.log(error)
      //window.location.href = '/'
    })
  }

  auth(auth){
    const { children } = this.props
    const { id } = this.props.match.params 

    const notifyStyle = {
      NotificationItem: {
        success: { 
          borderTop: '2px solid #CF3',
          backgroundColor: '#363737',
          color: '#fff',
          padding: '10px 14px',
          fontSize: '12px'
        }
      },
      Dismiss: {
        success: {
          color: '#fff',
          backgroundColor: 'transparent'
        }
      }
    }
    
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        ...this.props,
        providerPermissions: this.state.providerPermissions,
        brandPermissions: this.state.brandPermissions,
        userPermissions: this.state.userPermissions,
        range: this.state.range,
        networks: this.state.networks,
        vision_enabled: this.state.vision_enabled,
        campaings: this.state.campaings,
        industrySettings: this.state.industrySettings,
        settings: this.state.settings,
        brands: this.state.brands,
        config: this.state.config
      })
    )

    if(!!auth && !!id){
      axios.get(`users/tokensStatus/${id}`)
      .then((response)=>{
        let tokens = response.data.data
        tokens = _.filter(tokens, ['status', 'invalid'])
        if(tokens.length > 0){
          setTimeout(()=>{
            this.notify.addNotification({
              autoDismiss: 0,
              level: 'success',
              children: (
                <div>
                  <p>
                    An access token ({_.map(tokens, ({account_key})=>(<spam style={{cursor: 'pointer', color: '#cf3'}} onClick={()=>(this.onFixNetwork(id, account_key.toLowerCase()))} >{account_key}</spam>))}) has been disconnected from SMART, please contact tech support.
                  </p>
                  <p>Select your network you want to fix.</p>
                </div>
              )
            })
          }, 3000)
        }
      }).catch((error)=>{
        console.log(error)
      })
    }

    return (
      !!auth 
      ? <div>
          <Notify
            ref={(notify)=>(this.notify = notify)} 
            style={notifyStyle}
          />
          <ListGoogle 
            ref={(ListGoogle)=>(this.listGoogle = ListGoogle)} />
          <ListAds 
            ref={(ListAds)=>(this.listAds = ListAds)} />
          <ListPages 
            ref={(ListPages)=>(this.listPages = ListPages)} />
          <Header />
          <SidebarContent 
            providerPermissions={this.state.providerPermissions}
            userPermissions={this.state.userPermissions}
            brandPermissions={this.state.brandPermissions}
          />
          {childrenWithProps}
        </div>
      : <Redirect to='/' />
    )
  }

  render() {
    const { loading, auth } = this.state

    return (
      <main>
        <Helmet>
          <title>{this.props.title || 'S|M|A|R|T'}</title>
        </Helmet>
        <Alert
          confirm
          onAccept={()=>(window.location.href = '/')} 
          body='Your plan doesn’t have the required credentials to access this section. Please call you brand manager for further assistance.' 
          ref={(alert)=>(this.error = alert)} 
        />
        {(loading && auth != null) ? this.auth(auth) : <div />}
      </main>
    )
  }
}

export default Container