import React, { Component } from 'react'
import { Colors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

const metrics = [{
  icon: <i className="fas fa-flag-checkered" />,
  title: 'Sessions',
  metric: 'Sessions',
  format: '0,0.[0000]'
},
{
  icon: <i className="fas fa-user" />,
  title: 'Users',
  metric: 'Users',
  format: '0,0.[0000]'
},
{
  icon: <i className="far fa-clone" />,
  title: 'Page / Session',
  metric: 'Pages / Session',
  format: '0,0.[0000]'
},
{
  icon: <i className="far fa-clock" />,
  title: 'Session Duration',
  metric: 'Avg. Session Duration',
  format: '00:00:00'
},
{
  icon: <i className="fas fa-ban" />,
  title: 'Bounce Rate',
  metric: 'Bounce Rate',
  format: '0.[00]%'
},
{
  icon: <i className="fas fa-eye" />,
  title: 'Page Views',
  metric: 'Pageviews',
  format: '0,0.[0000]'
}]

class PageActivity extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        columns: [],
        rows: [],
        serialized: [{}]
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:sessions, ga:users, ga:pageviewsPerSession,  ga:avgSessionDuration, ga:bounceRate, ga:pageviews'
        }
      })
    })
  }

  secondsToTime(s){
    s = s * 1000
    const pad = (n, z)=>{
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;
    
    return pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
  }

  chart(data){
    const { serialized, columns, rows } = data

    return (
      <div className="googlePageActivityContainerItems">
        {metrics.map((metric, index)=>{
          let value = 0

          if(serialized[0] == undefined){
            value = 0
          }else{
            value = metric.metric == 'Bounce Rate' ? serialized[0][metric.metric]/100 : serialized[0][metric.metric]
          }
        
          
          return (
            <div className="googlePageActivityItem">
              <div 
                className="googlePageActivityIcon"
                style={{
                  backgroundColor: Colors[index],
                  color: Colors[index] == '#CCFF33' ? '#36383a' : '#ffffff'
                }}>
                {metric.icon}
              </div>
              <div className="googlePageActivityInfo">
                <p className="googlePageActivityTitle">
                  {metric.title}
                </p>
                <p className="googlePageActivityValue">
                  { metric.format == '00:00:00' ? this.secondsToTime(value) : numeral(value).format(metric.format)}
                </p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>{Chart}</div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }
  
  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="googlePageActivity">
        <Title description='Reacciones Generales en página.' title='Page Activity' />
        <div className="googlePageActivityContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )

  }
}

export default PageActivity
