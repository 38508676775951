import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import { Colors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Truncate from '../../Shared/Truncate'
import numeral from 'numeral'
import _ from 'lodash'

import logo from '../../../assets/img/logo.png'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class TopConversations extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    return storage.get('page')
    .then((page)=>{
      return axios.get(`industry/topConversations/${page}`)
    })
  }

  chart(data){
    return (
      <ul>
        {data.slice(0, 4).map((item, index)=>{
          return (
            <li style={{
              backgroundColor: Colors[index]
            }}>
              <a 
                href={item.link}
                style={{
                  color: Colors[index] == '#CCFF33' ? '#36383a' : '#ffffff'
                }}>
                {index+1}.{item.news_title}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="trendingConversations">
        <Title description='Las conversación con mayor relevancia en el país seleccionado.' title='Top Conversations' />
         <div className='trendingConversationsContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default TopConversations
