import React, { Component } from 'react'
import { Card, Elevation, Position, H5, H6  } from "@blueprintjs/core"

import logoSmartCom from '../../../../../assets/img/loginsmart.png'
import logoSmart from '../../../../../assets/img/logo.png'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import { Tooltip } from 'react-tippy'

import Truncate from '../../../../Shared/Truncate'
import SocialIcons from '../../../../Shared/SocialIcons'
import IconType from '../../../../Shared/IconType'

import Swiper from 'react-id-swiper'

import Loading from '../../../../Shared/LoadingCharts'

import './styles.css'

class PanelFormats extends Component {
  constructor(props){
    super(props)
    this.state = {
      active: 0
    }
    
    this.posts = this.posts.bind(this)
    this.info = this.info.bind(this)
    this.categories = this.categories.bind(this)
  }
  
  componentWillMount(){
    const { data } = this.props

    data.length > 0 && this.props.onSelect && this.props.onSelect(data[0])
  }

  formatMetrics(metric, format){
    format = numeral(metric).format(metric > 100000 ? '0.0a' : format)
    return format.toUpperCase()
  }

  categories(data){
    return (
      <div className='socialStrategyCategories'>
        <Swiper 
          effect='fade'
          rebuildOnUpdate
          direction='vertical'
          autoHeight
          spaceBetween={0}
          initialSlide={Math.floor(this.state.active/4)}
          ref={(swiper)=>(swiper && (this.swiperCategories = swiper.swiper))}
        >
          {_.chunk(data, 4).map((categories, categoryIndex)=>{
            return (
              <div>
                <div className="socialStrategyCategoriesContainer">
                {categories.map((category, index)=>{
                  return (
                    <div
                      className={`socialStrategyCategory ${this.state.active == (categoryIndex * 4) + index && 'active'}`} 
                      onClick={()=>{
                        this.setState({
                          active: (categoryIndex * 4) + index
                        }, ()=>{
                          this.props.onSelect(category)
                        })
                      }}>
                      <p className="socialStrategyCategoryName">
                        <Tooltip
                          title={category.name.replace(/-/g, " ").replace(/_/g, " ").replace(/(^|\s)\S/g, l => l.toUpperCase())}
                          position="top"
                          trigger="mouseenter focus"
                        >
                          <p>
                            <IconType 
                              type={category.name.toUpperCase()} 
                            />
                          </p>
                        </Tooltip>
                      </p>
                      <p className="socialStrategyCategoryScore">{numeral(category.score).format('0,0.0000')}%</p>
                      <p className="socialStrategyCategoryPosition">#{(categoryIndex * 4) + index + 1}</p>
                    </div>
                  )
                })}
                {_.range(4 - categories.length).map((item, index)=>{
                  return (
                    <div 
                      className="socialStrategyCategory socialStrategyCategoryPlaceholder"
                      style={{
                        backgroundImage: `url(${logoSmart})`
                      }}
                    />
                  )
                })}
                </div>
              </div>
            )
          })}
        </Swiper>
        {_.chunk(data, 4).length > 1 && (
          <div>
            <div 
              className="socialStrategyCategoriesPrev">
              <i 
                onClick={()=>(this.swiperCategories && this.swiperCategories.slidePrev())} 
                className="fas fa-caret-up"
              />
            </div>
            <div
              className="socialStrategyCategoriesNext">
              <i
                onClick={()=>(this.swiperCategories && this.swiperCategories.slideNext())}  
                className="fas fa-caret-down"
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  info(data){
    const { active: index } = this.state
    const active = data[index]

    return (
      <div className='socialStrategyData'>
        <div className="socialStrategyDataContainer">
          <div className="socialStrategyDataItem">
            <p className="socialStrategyDataPosts">{numeral(active.posts.length).format('0,0')}</p>
            <p className="socialStrategyDataItemTitle">Posts</p>
          </div>
          <div className="socialStrategyDataItem">
            <p className="socialStrategyDataInteractions">{numeral(active.interactions).format('0,0')}</p>
            <p className="socialStrategyDataItemTitle">Interactions</p>
          </div>
          <div className="socialStrategyDataItem">
            <p className="socialStrategyDataImpressions">{numeral(active.impressions).format('0,0')}</p>
            <p className="socialStrategyDataItemTitle">Impressions</p>
          </div>
        </div>
      </div>
    )
  }

  posts(data){
    const { active: index } = this.state
    const { competitor } = this.props
    const active = data[index]

    return (
      <div className='socialStrategyPosts'>
        <div className='socialStrategyPostsContainer'>
          <Swiper 
            effect='fade'
            rebuildOnUpdate
            autoHeight
            spaceBetween={0}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
            }}
            ref={(swiper)=>(swiper && (this.swiperPosts = swiper.swiper))}
          >
            {active.posts.slice(0, 5).map((post, index)=>{
              post = competitor == 'all' && post.is_brand ? {...post, ...post.brand_post} : post

              return (
                <div>
                  <Card 
                    interactive={false} 
                    elevation={Elevation.TWO} 
                    className="postContainer">
                    <div className="postIcon">
                      <SocialIcons
                        type={(competitor == 'brand' || post.is_brand) ? (!!post.ins_media_type ? post.ins_media_type.toLowerCase() : post.post_type.toLowerCase()) : post.type.toUpperCase()} 
                        provider={post.provider}
                      />
                    </div>
                    <div className="post">
                      <div className="postTopContainer">
                        <div className="postImage">
                          <a href={post.link} target="_blank">
                            <img src={post.picture} alt="" />
                          </a>
                        </div>
                        <div className="postExtras">
                          <p className="postExtrasType"> 
                            <IconType 
                              type={(competitor == 'brand' || post.is_brand) ? post.post_type.toUpperCase() : post.type.toUpperCase()} 
                            />
                          </p>
                          {(competitor == 'brand' || post.is_brand) && (
                            <div className="postExtrasEngagment">
                              <H6>
                                ENG Score
                              </H6>
                              <p>
                                {this.formatMetrics(post.es_calculated, '0,0.000')}%
                              </p>
                            </div>
                          )}
                          <div className="postExtrasEngagment">
                            <H6>
                              ES Competition
                            </H6>
                            <p>
                              {this.formatMetrics(post.es_competition || post.score, '0,0.0000')}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="postMessage">
                        <Truncate text={post.caption || post.message} />
                      </div>
                      <div className="postDate">
                        <p> 
                          <img 
                            src={logoSmart} 
                            alt="Logo Smart" 
                            className="logoSmartPost" 
                          />
                          {moment(post.post_published_date).format("llll")}
                        </p>
                      </div>
                    </div>
                  </Card>
                </div>
              )
            })}
          </Swiper>
          {active.posts.length > 1 && (
            <div>
              <div 
                className="socialStrategyPostsPrev">
                <i 
                  onClick={()=>(this.swiperPosts && this.swiperPosts.slidePrev())} 
                  className="fas fa-caret-left"
                />
              </div>
              <div
                className="socialStrategyPostsNext">
                <i
                  onClick={()=>(this.swiperPosts && this.swiperPosts.slideNext())}  
                  className="fas fa-caret-right"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }


  render () {
    const { data } = this.props

    const Categories = this.categories(data)
    const Info = this.info(data)
    const Posts = this.posts(data)

    return(
      <div>
        {Categories}
        {Info}
        {Posts}
      </div>
    )
  }
}

export default PanelFormats
