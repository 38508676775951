import React, { Component } from 'react'
import { Colors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

const metrics = [{
  icon: <span><i className="fas fa-dollar-sign" /><i className="fas fa-dollar-sign" /></span>,
  title: 'Sales Score',
  metric: 'SS',
  format: '0%'
},
{
  icon: <i className="far fa-money-bill-alt" />,
  title: 'Revenue',
  metric: 'Revenue',
  format: '$0,0.[0000]'
},
{
  icon: <i className="fas fa-shopping-cart" />,
  title: 'Transaction',
  metric: 'Transactions',
  format: '0,0.[0000]'
},
{
  icon: <i className="fas fa-tag" />,
  title: 'Avg Order Value', //Avg Order Value,
  metric: 'Avg. Order Value',
  format: '0,0.[0000]' //$0,0.[0000]
},
{
  icon: <i className="fas fa-percent" />,
  title: 'Conversion Rate',
  metric: 'Ecommerce Conversion Rate',
  format: '0.[00]'
},
{
  icon: <i className="fas fa-ticket-alt" />,
  title: 'Internal Promotion Views',
  metric: 'Internal Promotion Views',
  format: '0,0.[0000]'
}]

class Conversion extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        columns: [],
        rows: [],
        serialized: [{}]
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return Promise.all([axios.get(`${page}/analytics/google/score`, {
        params: {
          since,
          until,
          metrics: 'ga:sessions, ga:users, ga:pageviewsPerSession,  ga:avgSessionDuration, ga:bounceRate, ga:pageviews'
        }
      }), axios.get(`${page}/analytics/totalSpend`, {
        params: {
          since,
          until,
        }
      })])
    }).then((response)=>{
      return {
        score: response[0].data.data,
        total_spend: response[1].data.data
      }
    })
  }

  chart(data){
    const { serialized, columns, rows } = data.score

    return (
      <div className="googleConversionContainerItems">
        {metrics.map((metric, index)=>{
          let value = 0

          if(serialized[0] == undefined){
            value = 0
          }else{
            value = metric.metric == 'SS' ? serialized[0][metric.metric]/100 : serialized[0][metric.metric]
          }
          
          return (
            <div className="googleConversionItem">
              <div 
                className="googleConversionIcon"
                style={{
                  backgroundColor: Colors[index],
                  color: Colors[index] == '#CCFF33' ? '#36383a' : '#ffffff'
                }}>
                {metric.icon}
              </div>
              <div className="googleConversionInfo">
                <p className="googleConversionTitle">
                  {metric.title}
                </p>
                <p className="googleConversionValue">
                  {metric.title == 'Avg Order Value' && '$'}{numeral(value).format(metric.format)}{metric.title == 'Conversion Rate' && '%'}
                </p>
              </div>
            </div>
          )
        })}
        {data.total_spend.active && (
          <div className="googleConversionItem">
            <div 
              className="googleConversionIcon"
              style={{
                backgroundColor: '#CCFF33',
                color: '#36383a'
              }}>
              <i className="fas fa-dollar-sign" />
            </div>
            <div className="googleConversionInfo">
              <p className="googleConversionTitle">
                Total Spend
              </p>
              <p className="googleConversionValue">
                ${numeral(data.total_spend.spend).format('0,0.[0000]')}
              </p>
            </div>
          </div>
        )}
      </div>
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="googleConversion">
        <Title description='Reacciones Generales en página relacionadas con la compras realizadas en el sitio.' title='Conversion' />
        <div className="googleConversionContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )

  }
}

export default Conversion
