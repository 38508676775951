import React, { Component } from 'react'
import { 
  Button,
  FormGroup, 
  InputGroup,
  Dialog,
  Classes
} from "@blueprintjs/core"
import Alert from '../../../../Shared/Alert'

import is from 'is_js'

import './styles.css'

class CategoryDialog extends Component {
    constructor(props){
      super(props)
      this.state = {
        visible: false,
      }

      this.onConfirm = this.onConfirm.bind(this)
      this.show = this.show.bind(this)
      this.hide = this.hide.bind(this)
    }

    show(){
      this.setState({
        visible: true
      })
    }

    hide(){
      this.setState({
        visible: false
      })
    }

    onConfirm(){
      const { value } = this.category
      if(is.empty(value.replace(/\s+/g, ''))){
        this.error.show()
      }else{
        this.hide()
        this.props.onConfirm && this.props.onConfirm(value.trim())
      }
    }

    render() {
      return(
         <Dialog
          isOpen={this.state.visible}
          backdropProps={{
            onClick: this.hide,
            style: {
              cursor: 'pointer'
            }
          }}
          className="bp3-dark input-modal">
          <Alert 
            confirm
            body='Enter a category name'
            ref={(error)=>(this.error = error)}
          />
          <div className={Classes.DIALOG_BODY}>
            <div className="grid-center-middle">
              <div className="col-10">
                <FormGroup
                  label="Input Category"
                  labelFor="category-input">
                  <InputGroup 
                    inputRef={(input)=>(
                      this.category = input
                    )}
                    defaultValue={this.props.defaultValue}
                    id="category-input" 
                    placeholder="Category"
                  />
                </FormGroup>
              </div>
              <div className="col-2">
                <Button minimal="true" 
                  className="button button-primary" 
                  onClick={this.onConfirm}>
                  Add
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      )
    }
}

export default CategoryDialog
