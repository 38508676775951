import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Truncate from '../../Shared/Truncate'
import { Competitors } from '../../../config'

import placeholder from '../../../assets/img/placeholder.jpg'
import logoSmart from '../../../assets/img/logo.png'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class TwitterTrending extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    return Promise.all([storage.get('page'), storage.range()])
    .then((responses)=>{
      const since = moment(responses[1][0]).format('YYYY-MM-DD')
      const until = moment(responses[1][1]).format('YYYY-MM-DD')

      return axios.get(`industry/twitterPosts/${responses[0]}?since=${since}&until=${until}`)
    })
  }

  chart(data){
    if(!!data && data.length > 0){
      return (
        <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="trendingTwitter">
          <div className='trendingTwitterContainer'>
            <div>
              <div className="postsContainer">
                <div className="trendingTwitterContainerItem">
                  <div className="trendingTwitterContainerIcon">
                    <i className="fab fa-twitter"></i>
                  </div>
                  <div className="trendingTwitterContainerPosts">
                    {data.map((item, index)=>{
                      const post = item.post || {}
                      const media = _.has(post, 'extended_entities.media[0].media_url') 

                      return (
                        <div className='post'>
                          <div className='postPicture'
                            style={{
                              backgroundImage: `url(${logoSmart})`
                            }}
                          >
                            <div style={{
                              backgroundImage: `url(${media ? post.extended_entities.media[0].media_url : ''})`
                            }} />
                          </div>
                          <div className='postMessage'>
                            <Truncate
                              text={post.text}
                            /> 
                          </div>
                          <p className='postDate'>
                            {moment(post.created_time).format("ll")}
                          </p>
                          <img 
                            src={!item.brand.image || item.brand.image == '' ? logoSmart : item.brand.image} 
                            alt="Logo Smart" 
                            className="postImageCompetitor" />
                        </div>
                      )
                    })}
                    {_.range(0, Competitors - data.length, 1).map((post)=>{
                      return (
                        <div 
                          className="postPlaceholder"
                          style={{
                            backgroundImage: `url(${placeholder})`
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      )
    }else{
      return (<div/>)
    }
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return Chart
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Chart loadOnMount>
        <Chart.Loading>
          {this.loading()}
        </Chart.Loading>
        <Chart.Success>
          {this.success}
        </Chart.Success>
        <Chart.Error>
          {this.error}
        </Chart.Error>
      </Chart>
    )
  }
}

export default TwitterTrending
