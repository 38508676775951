import React, { Component } from 'react'
import { Card, Elevation, Spinner } from "@blueprintjs/core"

import Color from 'color'

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import { Colors } from '../../../config'
import Chart from '../../Shared/Chart'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Persons extends Component {
  constructor(props){
    super(props)
    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range, filter){
    const { competitor } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      return axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/vision/${page}/persons?since=${since}&until=${until}&provider=${filter}`)
    })
  }
    
  chart(data){
    return (
      <div className="socialPersonsgender">
        <div 
          className="socialPersonsgenderFemale" 
          style={{
            width: `${data[0].percent}%`
          }}>
          <i className='fas fa-female' />
          <p>{data[0].percent}%</p>
        </div>
        <div 
        className="socialPersonsgenderMale"          
        style={{
          width: `${data[1].percent}%`
        }}>
          <i className='fas fa-male' />
          <p>{data[1].percent}%</p>
        </div>
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data.persons)
    const total = _.sumBy(data.persons, 'percent')

    return (
      <div>
        {total == 0 ? (
          <div className="socialPersonsNotData">
            <p>Not Data</p>
          </div>
        ) : (
          <div>
            <div className="socialPersonsRange">
              <p><i className="fas fa-user-alt" /></p>
              <p>Persons per Image</p>
              <p>{Math.round(data.percents)}</p>
            </div>
            {Chart}
          </div>
        )}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }


  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialPersons">
        <Title title='Persons Composition' />
        <div
          className='socialPersonsContainer'
          ref={(ref)=>(this.chartContainer = ref)}
        >
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default Persons
