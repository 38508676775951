import React, { Component } from 'react'
import _ from 'lodash'
import Mark from 'mark.js'
import Dotdotdot from 'react-dotdotdot'

class Truncate extends Component {
  constructor(props){
    super(props)

    this.mark = undefined
    this.markOptions = {
      element: 'span',
      className: 'smart-mark'
    }
  }

  shouldComponentUpdate(props){
    return this.props.text != props.text || !_.isEqual(this.props.keywords != props.keywords)
  }

  componentDidUpdate(){
    this.mark = new Mark(this.text)
    this.mark.unmark(this.props.keywords)
    this.mark.markRegExp(/(^|\s)(#[a-zA-Z0-9_-\u00C0-\u017F\d-]+)/ig, this.markOptions)
    this.mark.markRegExp(/(https?:\/\/[^\s]+)/g, this.markOptions)
    this.mark.markRegExp(/(^|\s)(@[a-z0-9_-]+)/ig, this.markOptions)
    this.mark.mark(this.props.keywords || [], this.markOptions)
  }

  componentDidMount(){
    this.mark = new Mark(this.text)
    this.mark.unmark(this.props.keywords)
    this.mark.markRegExp(/(^|\s)(#[a-zA-Z0-9_-\u00C0-\u017F\d-]+)/ig, this.markOptions)
    this.mark.markRegExp(/(https?:\/\/[^\s]+)/g, this.markOptions)
    this.mark.markRegExp(/(^|\s)(@[a-z0-9_-]+)/ig, this.markOptions)

    this.mark.mark(this.props.keywords || [], this.markOptions)
  }
  
  render () {
    return(
      <Dotdotdot clamp={3} useNativeClamp={false} {...this.props}>
        <p ref={(text)=>(this.text = text)} style={{wordBreak: 'break-all'}}>
          {this.props.text}
        </p>
      </Dotdotdot>
    )
  }
}

export default Truncate