import axios from 'axios'
import storage from './storage'
import md5 from 'js-md5'
import _ from 'lodash'
import { API_URL } from '../config'
import moment from 'moment'

let requests = 0
let connections = 10
let total = 0
let reload = false

let queu = {
	start: [],
	wait: []
}

const Axios = () => {
	const instance = axios.create({
	  baseURL: API_URL,
	  timeout: 60000
	})	

	const next = ()=>{
		let wait = queu.wait
		const next = _.head(wait)

		if(!!next){
			next.resolve(next.config)
		}else{
			requests = 0
			queu = {
				start: [],
				wait: []
			}
		}

		queu.wait = _.drop(wait)
	}

	const refresh = (token)=>{
		return storage.get('user').then((user)=>{
			return axios.post(`${API_URL}users/refresh`, {
				user_id: user.id,
				facebook_id: user.facebook_id
			},{
				headers: {
					Authorization: `Bearer ${token}`
				}
			})
		})
	}

	instance.interceptors.request.use(async (config) =>{
		let wait = false
		let token = null
		let expiration = null

		requests++
		wait = requests > connections

		if((!reload && config.url.indexOf('http') == -1) || config.url.indexOf('https://adsapi.smartintel.io') != -1){
			expiration = await storage.expiration()		
			token = await storage.token()
			if(!!token) {
		    config.headers.Authorization = `Bearer ${token}`
		  }
		}		
		
		return new Promise((resolve, reject) => {
			const id = `${JSON.stringify(config)}-${Math.random()}-${new Date().getMilliseconds()}`

			if(expiration != null && moment().isAfter(expiration)){
				reject({
					config: config,
					response: {
						data: {
							description: 'Token expiration'
						}
					}
				})
			}else if(!wait){
				let start = queu.start
				start = _.concat(start, {
					id: md5(id),
					config: config
				})
				queu.start = start
				resolve(config)
			}else{
				let wait = queu.wait
				wait = _.concat(wait, {
					id: md5(id),
					config: config,
					resolve: resolve,
					reject: reject
				})
				queu.wait = wait
			}
		})
	},(error)=>{
	  return Promise.reject(error)
	})

	instance.interceptors.response.use(async (response) =>{
		total = 0
		reload = false
		next()
	  return response
	},async({ config, response})=>{
		let token = null
		token = await storage.token()

		if(total < 5 && (response.data.description == 'Token expiration' || response.data.description == 'Error en el token.')){
			total++
			return refresh(token).then((response)=>{
				const { data } = response.data
      	const zone = moment().format('Z')
      	const expiration = moment().add(data.expiration, 'ms').subtract(2, 'days').utcOffset(zone).format()
      	config.headers.Authorization = `Bearer ${data.token}`
				return Promise.all([
					storage.set('auth_token', data.token),
	      	storage.set('expiration', expiration)
				])
			}).then(()=>{
				reload = true
				return Promise.resolve(instance(config))
			})
		}else if(total == 5 && response.data.description == 'Error en el token.'){
			storage.clear().then(()=>{
				window.location.href = window.location.origin
			})
		}else{
			next()
			throw {
				config,
				response
			}
			/*Promise.reject({
				config,
				response
			})*/
		}
	})

 	return instance
}

export default Axios()
