const interests = [
  {
    "name": "Public service",
    "score": "3,24"
  },
  {
    "name": "Teacher",
    "score": "3,22"
  },
  {
    "name": "Certified teacher",
    "score": "3,2"
  },
  {
    "name": "Teacher",
    "score": "3,18"
  },
  {
    "name": "Elementary Teacher",
    "score": "3,15"
  },
  {
    "name": "Civil service",
    "score": "3,12"
  },
  {
    "name": "Primary Teacher",
    "score": "3,09"
  },
  {
    "name": "Teacher education",
    "score": "2,95"
  },
  {
    "name": "Primary School Teacher",
    "score": "2,87"
  },
  {
    "name": "Public sector",
    "score": "2,85"
  },
  {
    "name": "Professor",
    "score": "2,8"
  },
  {
    "name": "Middle School Teacher",
    "score": "2,76"
  },
  {
    "name": "Secretariat of Public Education (Mexico)",
    "score": "2,66"
  },
  {
    "name": "Elementary School Teacher",
    "score": "2,58"
  },
  {
    "name": "Government",
    "score": "2,53"
  },
  {
    "name": "Learning",
    "score": "2,48"
  },
  {
    "name": "High School Teacher",
    "score": "2,4"
  },
  {
    "name": "Municipality",
    "score": "2,37"
  },
  {
    "name": "Teacher education",
    "score": "2,35"
  },
  {
    "name": "Professor",
    "score": "2,25"
  },
  {
    "name": "Teacher",
    "score": "2,19"
  },
  {
    "name": "profesorado de educacion fisica",
    "score": "1,8"
  },
  {
    "name": "Preschool teacher",
    "score": "1,78"
  },
  {
    "name": "Education",
    "score": "1,73"
  },
  {
    "name": "Public administration",
    "score": "1,65"
  }
]

export default interests