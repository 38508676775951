import React, { Component } from 'react';
import { 
  Dialog,
  Switch
} from "@blueprintjs/core"
import _ from 'lodash'
import moment from 'moment'
import ContentLoader from 'react-content-loader'
import axios from '../../../../../services/axios'
import storage from '../../../../../services/storage'

import './styles.css'

class CampaingsSettings extends Component {
  constructor(props){
    super(props)

    this.state = {
      open: false,
      search: '',
      campaings: [], 
      loading: false
    }

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.onUnSelectAll = this.onUnSelectAll.bind(this)
    this.onSelectAll = this.onSelectAll.bind(this)
    this.onChangeCampaing = this.onChangeCampaing.bind(this)
    this.onChangeSearch = _.debounce(this.onChangeSearch.bind(this), 500)
    this.onSave = this.onSave.bind(this)
  }

  onUnSelectAll(){
    const { search } = this.state

    let campaings = _.filter(this.state.campaings, (campaing)=>{
      const { search } = this.state

      return search == '' ? true : campaing.name.indexOf(search) > -1
    })

    campaings = _.map(this.state.campaings, (campaing)=>{
      const find = _.find(campaings, { id: campaing.id})
      return {
        ...campaing,
        active: find == undefined ? campaing.active : false
      }
    })

    this.setState({
      campaings: campaings
    })
  }

  onSelectAll(){
    const { search } = this.state

    let campaings = _.filter(this.state.campaings, (campaing)=>{
      const { search } = this.state

      return search == '' ? true : campaing.name.indexOf(search) > -1
    })

    campaings = _.map(this.state.campaings, (campaing)=>{
      const find = _.find(campaings, { id: campaing.id})

      return {
        ...campaing,
        active: find == undefined ? campaing.active : true
      }
    })

    this.setState({
      campaings: campaings
    })

  }

  onChangeSearch(event){
    this.setState({
      search: event.target.value
    })
  }
  
  onSave(){
    let data = _.filter(this.state.campaings, (campaing)=>{
      return campaing.active
    })
    data = _.map(data, 'id')

    this.hide()

    this.props.onSave && this.props.onSave(JSON.stringify(data))
  }

  onChangeCampaing(event, id){
    let campaings = _.concat([], this.state.campaings)
    const index = _.findIndex(campaings, {id: id})

    campaings[index] = {
      ...campaings[index],
      active: !campaings[index].active
    }

    this.setState({
      campaings: campaings
    })
  }

  show(){
    this.setState({
      open: true,
      loading: true
    }, ()=>{
      Promise.all([
        storage.get('page'), 
        storage.range()
      ]).then((data)=>{
        const since = moment(data[1][0]).format('YYYY-MM-DD')
        const until = moment(data[1][1]).format('YYYY-MM-DD')

        return Promise.all([
          axios.get(`ads/campaingsList/${data[0]}?since=${since}&until=${until}`),
          axios.get(`user/CampaingsAds?brand_id=${data[0]}`)
        ])
      }).then((response)=>{
        const userCampaings = response[1].data.data.campaing_ads
        const campaings = _.map(response[0].data.data, (campaing)=>{
          return {
            ...campaing,
            active: userCampaings == 'all' || (!!userCampaings && userCampaings.indexOf(campaing.id) > -1)
          }
        })
        this.setState({
          campaings: campaings,
          loading: false
        })
      }).catch((error)=>{
        this.setState({
          loading: false
        })
        console.log(error)
      })
    })
  }

  hide(){
    this.setState({
      open: false,
      search: '',
      campaings: [],
      loading: false
    })
  }

  render() {
    const campaings = _.filter(this.state.campaings, (campaing)=>{
      const search = this.state.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      const name = campaing.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      return search == '' ? true : name.indexOf(search) > -1
    })

    return (
      <div>
        <Dialog
          {...this.props}
          isOpen={this.state.open}
          backdropProps={{
            onClick: this.hide,
            style: {
              cursor: 'pointer'
            }
          }}
          className='campaingsSettings'
        >
          <div className='campaingsContent'>
            <div className='campaingsHeader'>
              <p className='campaingsHeaderTitle'>
                ADs Campagns
              </p>
              <div className='campaingsHeaderSearch'>
                <input 
                  onChange={(event)=>{
                    event.persist()
                    this.onChangeSearch(event)
                  }}
                />
                <button
                  onClick={this.onSelectAll}  
                  className='campaingsHeaderButtom'
                >
                  Select All
                </button>
                <button
                  onClick={this.onUnSelectAll} 
                  className='campaingsHeaderButtom'
                >
                  Unselect All
                </button>
              </div>
            </div>
            {this.props.network ? (
              <div className='campaingsBody'>
                {campaings.map((campaing, index)=>{
                  return (
                    <div className='campaingListItem'>
                      <Switch
                        className='campaingListItemSwitch'
                        checked={campaing.active} 
                        label={campaing.name} 
                        onChange={(event)=>(this.onChangeCampaing(event, campaing.id))} 
                      />
                    </div>
                  )
                })}
                {this.state.loading && (
                  <div>
                    <ContentLoader 
                      height={160}
                      width={400}
                      speed={2}   
                      primaryColor="#7b7b7b"
                      secondaryColor="#363737"
                    >
                      {_.range(0, 7).map((item)=>{
                        return (
                          <rect x="0" y={20 * item} rx="5" ry="5" width="28" height="12" />
                        )
                      })}
                      {_.range(0, 7).map((item)=>{
                        return (
                          <rect x="36" y={(20 * item) + 2} rx="5" ry="5" width="354" height="10" />
                        )
                      })}
                    </ContentLoader>
                  </div>
                )}
              </div>
            ) : (
              <div className='campaingsBody'>
                <p className='campaingsBodyNot'>NO DATA</p>
              </div>
            )}
            <div className='campaingsFotter'>
              <button 
                onClick={()=>{
                  this.props.network && this.onSave()
                }}
                className='campaingsFotterSave'
              >
                Save
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default CampaingsSettings
