import React, {Component} from 'react'

import mind from '../../../assets/img/mind.png'

import './styles.css'

class LoadingCharts extends Component {
  render () {
    return(
      <div className='LoadingCharts'>
      	<div className='LoadingChartsContainer'>
         <div>
           <img src={mind} />
            <div className='LoadingChartsSpiner'>
              <div class="lds-css">
                <div class="lds-dual-ring">
                  <div>
                    
                  </div>
                </div>
              </div>
            </div> 
         </div>
        </div>
      </div>
    )
  }
}

export default LoadingCharts
