import React, { Component } from 'react'
import { H1 } from "@blueprintjs/core"

//COMPONENTES
import TopTrends from '../TopTrends'
import TopConversations from '../TopConversations'
import TrendingVideos from '../TrendingVideos'
import Hashtags from '../Hashtags'
import CompetitionMentions from '../CompetitionMentions'
import Rank from '../Rank'
import Traffic from '../Traffic'
import LinksInCount from '../LinksInCount'
import PageViews from '../PageViews'
import ERComp from '../ERComp'
import SearchTrends from '../SearchTrends'
import FacebookTrending from '../FacebookTrending'
import TwitterTrending from '../TwitterTrending'
import InstagramTrending from '../InstagramTrending'
import Benchmark from '../Benchmark'

import './styles.css'

class MainContainer extends Component {
  render () {
    const { networks } = this.props

    return(
      <div className="industryContainer" data-html2canvas-print>
        <div className="top-container">
          <div className="brands-added">
            <div className="grid">
              <div className="col-5_sm-12">
                <H1 className="brands-added__title" data-html2canvas-ignore>Industry Intelligence</H1>
              </div>
              <div className="col-7_sm-12">
              </div>
            </div>
          </div>
        </div>
        <div className="social-intelligence__container" id="MainContainer">
          <div className="grid">
            {window.location.hostname == 'expansion.smartintel.io' && (
              <div className="col-12">
                <Benchmark id={this.props.id} />
              </div>
            )}
            <div className="col-4_sm-12">
              <TopTrends id={this.props.id} />
            </div>
            <div className="col-4_sm-12 no-pad-b">
              <div className="col-12">
                <div className="best-days-shell">
                  <div className="grid">
                    <div className="col-12 no-pad-l-f">
                      <TopConversations id={this.props.id} />
                    </div>
                    <div className="col-12 no-pad-l-f no-pad-b">
                      <TrendingVideos id={this.props.id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4_sm-12 no-pad-b">
              <div className="col-12">
                <div className="best-days-shell">
                  <div className="grid">
                    <div className="col-12 no-pad-l-f">
                      <Hashtags id={this.props.id} />
                    </div>
                    <div className="col-12 no-pad-l-f no-pad-b">
                      <CompetitionMentions id={this.props.id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4_sm-12">
              <Rank id={this.props.id} />
            </div>
            <div className="col-4_sm-12">
              <Traffic id={this.props.id} />
            </div>
            <div className="col-4_sm-12 no-pad-b">
              <div className="col-12">
                <div className="best-days-shell">
                  <div className="grid">
                    <div className="col-12 no-pad-l-f">
                      <LinksInCount id={this.props.id} />
                    </div>
                    <div className="col-12 no-pad-l-f no-pad-b">
                      <PageViews id={this.props.id} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8_sm-12">
              <SearchTrends id={this.props.id} />
            </div>
            <div className="col-4_sm-12">
              <ERComp id={this.props.id} />
            </div>
            <div className="col-12">
              <FacebookTrending
                id={this.props.id} 
              />
            </div>
            <div className="col-12">
              <TwitterTrending
                id={this.props.id} 
              />
            </div>
            <div className="col-12">
              <InstagramTrending
                id={this.props.id} 
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default MainContainer
