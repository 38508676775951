import React, { Component } from 'react'
import { Button, H1 } from "@blueprintjs/core"
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import _ from 'lodash'
import { RangeConfig } from '../../../config'
import axios from '../../../services/axios'
import storage from '../../../services/storage'

// COMPONENTES
import CommunicationStrategy from '../CommunicationStrategy/CommunicationStrategy'
import Engagement from '../Engagement/Engagement'
import PostsDistribution from '../PostsDistribution/PostsDistribution'
import TotalActivity from '../TotalActivity/TotalActivity'
import BestDays from '../BestDays/BestDays'
import BestHours from '../BestHours/BestHours'
import Interactions from '../Interactions/Interactions'
import PerfectMix from '../PerfectMix/PerfectMix'
import BestHashtags from '../BestHashtags/BestHashtags'
import Audience from '../Audience/Audience'
import Demographics from '../Demographics/Demographics'
import Reactions from '../Reactions/Reactions'
import Sentiment from '../Sentiment/Sentiment'
import TopicData from '../TopicData/TopicData'
import BestPosts from '../BestPosts/BestPosts'
import BestPaidPosts from '../BestPaidPosts/BestPaidPosts'
import Labels from '../Labels'
import Persons from '../Persons'
import Composition from '../Composition'
import Colors from '../Colors'
import Analytics from '../Analytics'


import 'bootstrap-daterangepicker/daterangepicker.css'
import './styles.css'

class MainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      range: [
        moment(this.props.range[0]).startOf('day').toDate(),
        moment(this.props.range[1]).startOf('day').toDate()
      ],
      networks: this.props.networks,
      category: {
        category: {
          name: 'all'
        }
      }
    } 

    this.range = [
      moment(this.props.range[0]).startOf('day').toDate(),
      moment(this.props.range[1]).startOf('day').toDate()
    ]

    this.components = {}
    this.onSearch = this.onSearch.bind(this)
    this.onGetData = this.onGetData.bind(this)
    this.onChangeRange = this.onChangeRange.bind(this)
    this.onSelectCategory = this.onSelectCategory.bind(this)
  }

  onSearch(){
    this.onGetData()
  }

  onGetData(){
    this.setState({
      range: this.range
    })
  }

  onSelectCategory(category){
    this.setState({
      category: category
    })
  }

  onChangeRange({startDate, endDate}){
    const range = [startDate.toDate(), endDate.toDate()]

    this.dateStart.innerHTML = moment(range[0]).format('YYYY-MM-DD')
    this.dateEnd.innerHTML = moment(range[1]).format('YYYY-MM-DD')

    storage.set('range', range)
    
    this.range = range
  }


  render () {
    const exist = _.find(this.state.networks, {provider: this.props.filter})

    return(
      <div className="socialContainer" data-html2canvas-print>
        <div className="top-container">
          <div className="brands-added">
            <div className="grid">
              <div className="col-5_sm-12">
                <H1 className="brands-added__title" data-html2canvas-ignore>Content Optimization {this.props.filter == 'all' ? '' : this.props.filter}</H1>
              </div>
              <div className="col-7_sm-12">
                <div className="searchContainer" data-html2canvas-ignore>
                  <div className="dateSmart bp3-dark">
                    <span className="searchCalendarIcon"><i className="far fa-calendar-alt"></i></span>
                    <DateRangePicker
                      {...RangeConfig} 
                      startDate={this.state.range[0]} 
                      endDate={this.state.range[1]}
                      onApply={(event, picker)=>(this.onChangeRange(picker))}>
                      <div className="daterangepickerDates">
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateStart = ref)}>
                          {moment(this.state.range[0]).format('YYYY-MM-DD')}
                        </span>
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateEnd = ref)}>
                          {moment(this.state.range[1]).format('YYYY-MM-DD')}
                        </span>
                      </div>
                    </DateRangePicker>
                    <Button onClick={this.onSearch} minimal="true" className="button btn-outline" >Search</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(this.props.filter == 'all' || !!exist) ? (
          <div className="social-intelligence__container" id="MainContainer">
            <div className="grid" id="strategy">
              <div className="col-12">
                <CommunicationStrategy 
                  onSelectCategory={this.onSelectCategory} 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['strategy'] = ref))}
                />
              </div>
              <div className="col-12">
                <Analytics 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['analytics'] = ref))}
                />
              </div>
              <div className="col-4_sm-12">
                <Engagement 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['engagement'] = ref))}
                />
              </div>
              <div className="col-4_sm-12">
                <PostsDistribution 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['distribution'] = ref))}
                />
              </div>
              <div className="col-4_sm-12">
                <TotalActivity 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['activity'] = ref))}
                />
              </div>
              {(this.props.vision_enabled && this.props.competitor == 'brand') &&(
                <div className="col-12">
                  <div className="grid"> 
                    <div className="col-4_sm-12">
                      <Colors 
                        filter={this.props.filter}
                        competitor={this.props.competitor}
                        range={this.state.range}
                        ref={(ref)=>( ref && (this.components['colors'] = ref))}
                      />
                    </div>
                    <div className="col-4_sm-12 no-pad-b">
                      <div className="col-12">
                        <div>
                          <div className="grid">
                            <div className="col-12 no-pad-l-f">
                              <Persons 
                                filter={this.props.filter}
                                competitor={this.props.competitor}
                                range={this.state.range}
                                ref={(ref)=>( ref && (this.components['persons'] = ref))}
                              />
                            </div>
                            <div className="col-12 no-pad-l-f no-pad-b">
                              <Composition 
                                filter={this.props.filter}
                                competitor={this.props.competitor}
                                range={this.state.range}
                                ref={(ref)=>( ref && (this.components['composition'] = ref))} 
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4_sm-12">
                      <Labels 
                        filter={this.props.filter}
                        competitor={this.props.competitor}
                        range={this.state.range}
                        ref={(ref)=>( ref && (this.components['labels'] = ref))}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="col-4_sm-12">
                <BestDays 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['bestdays'] = ref))}
                />
              </div>
              <div className="col-8_sm-12">
                <BestHours 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['besthours'] = ref))}
                />
              </div>
              <div className="col-4_sm-12">
                <BestHashtags 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['hashtags'] = ref))}
                />
              </div>
              <div className="col-4_sm-12">
                <Interactions 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['interactions'] = ref))}
                />
              </div>
              <div className="col-4_sm-12">
                <PerfectMix 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['perfectmix'] = ref))}
                />
              </div>
            </div>
            {this.props.competitor == 'brand' && (
              <div className="grid" id="audience">
                <div className="col-4_sm-12">
                  <Audience 
                    filter={this.props.filter}
                    competitor={this.props.competitor}
                    range={this.state.range}
                    ref={(ref)=>( ref && (this.components['audience'] = ref))}
                  />
                </div>
                <div className="col-4_sm-12">
                  <Demographics 
                    filter={this.props.filter}
                    competitor={this.props.competitor}
                    range={this.state.range}
                    ref={(ref)=>( ref && (this.components['demographics'] = ref))}
                  />
                </div>
                <div className="col-4_sm-12 no-pad-b">
                  <div className="col-12">
                    <div className="best-days-shell">
                      <div className="grid">
                        <div className="col-12 no-pad-l-f">
                          <Reactions 
                            filter={this.props.filter}
                            competitor={this.props.competitor}
                            range={this.state.range}
                            ref={(ref)=>( ref && (this.components['reactions'] = ref))}
                          />
                        </div>
                        <div className="col-12 no-pad-l-f no-pad-b">
                          <Sentiment 
                            onSelectAll={(category)=>(this.setState({category: category}))}
                            category={this.state.category}
                            filter={this.props.filter}
                            competitor={this.props.competitor}
                            range={this.state.range}
                            ref={(ref)=>( ref && (this.components['sentiment'] = ref))} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.props.competitor == 'brand' && (
              <div className="grid" id="topic">
                <div className="col-12">
                  <TopicData 
                    filter={this.props.filter}
                    competitor={this.props.competitor}
                    range={this.state.range}
                    ref={(ref)=>( ref && (this.components['topicdata'] = ref))}
                  />
                </div>
              </div>
            )}
            <div className="grid" id="top">
              <div className="col-12">
                <BestPosts 
                  filter={this.props.filter}
                  competitor={this.props.competitor}
                  range={this.state.range}
                  ref={(ref)=>( ref && (this.components['bestposts'] = ref))}
                />
              </div>
              {this.props.competitor == 'brand' && (
                <div className="col-12">
                  <BestPaidPosts 
                    filter={this.props.filter}
                    competitor={this.props.competitor}
                    range={this.state.range}
                    ref={(ref)=>( ref && (this.components['bestpaidposts'] = ref))}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="notData">
            NO DATA
          </div>
        )}
      </div>
    )
  }
}

export default MainContainer
