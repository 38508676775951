import React, { Component } from 'react'
import Alert from '../../Shared/Alert'
import './styles.css'

class Account extends Component {
  render () {
    const { brand, account, provider } = this.props

    return(
      <div 
        className='brandAccountActive'>
        <Alert 
          body='Do you want to edit this account?'
          onAccept={()=>(this.props.onUpdate(brand, provider))} 
          ref={(alert)=>(this.update = alert)} 
        />
        <Alert
          confirm 
          body='Your plan doesn’t have the required credentials to access this section. Please call you brand manager for further assistance.' 
          ref={(alert)=>(this.error = alert)} 
        />
        <Alert 
          body='Do you want to erase this account?'
          onAccept={()=>(this.props.onDelete(brand, provider))} 
          ref={(alert)=>(this.delete = alert)} 
        />
        <div className='brandAccountAvatar'>
          <div
            style={{
              backgroundImage: `url(${account.avatar})`
            }} 
            className={`brandAccountAvatar${account.provider}`}>
            {account.provider != 'facebook' &&
              <div>
                <div className='brandAccountsActionsEdit'>
                  <i 
                    className="fas fa-pen"
                    onClick={()=>(this.props.lock ? this.error.show() : this.update.show())} 
                  />
                </div>
                <div className='brandAccountsActionsDelete'>
                  <i 
                    className="far fa-trash-alt" 
                    onClick={()=>(this.props.lock ? this.error.show() : this.delete.show())} 
                  />
                </div>
              </div>
            }
          </div>
        </div>
        <p className='brandAccountsProvider'>{account.name}</p>
      </div>
    )
  }
}

export default Account