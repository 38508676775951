import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import { Colors } from '../../../../../config'
import Color from 'color'

import numeral from 'numeral'
import _ from 'lodash'

import './styles.css'

class Details extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: this.props.data
    }

    this.setData = this.setData.bind(this)
  }

  setData(data){
    this.setState({
      data: data
    })
  }

  render() {
    return (
      <div className='retailDetails'>
        <p className='retailDetailsTitle'>Weekly Data</p>
        <div className='retailDetailsItems'>
          <div className='retailDetailsItem'>
            <p className='retailDetailsItemValue'>MX{numeral(this.state.data.investment_facebook).format('0,0')}</p>
            <p className='retailDetailsItemTitle'>Display / Programatic</p>
          </div>
          <div className='retailDetailsItem'>
            <p className='retailDetailsItemValue'>MX{numeral(this.state.data.investment_display_programatic).format('0,0')}</p>
            <p className='retailDetailsItemTitle'>Facebook Conversion</p>
          </div>
          <div className='retailDetailsItem'>
            <p className='retailDetailsItemValue'>MX{numeral(this.state.data.investment_reach_and_frecuency).format('0,0')}</p>
            <p className='retailDetailsItemTitle'>Facebook Reach and Frequency</p>
          </div>
        </div>
      </div> 
    )
  }
}

export default Details
