import React, { Component } from 'react';
import _ from 'lodash'
import axios from '../../../../../services/axios'
import storage from '../../../../../services/storage'
import './styles.css'

class IndustryImage extends Component {
  constructor(props){
    super(props)

    this.state = {
      image: this.props.defaultValue || ''
    }

    this.delete = this.delete.bind(this)
    this.value = undefined
    this.url = this.url.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  url(){
    return new Promise((resolve, reject) => {
      if(this.value != undefined){
        storage.get('page')
        .then((page)=>{
          let formData = new FormData()
          formData.append('file', this.value);
          return axios.post(`upload/assets/${page}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        }).then((response)=>{
          resolve(response.data.data[0].url)
        }).catch((error)=>{
          resolve(this.props.defaultValue)
        })
      }else{
        resolve(this.state.image)
      }
    })
  }

  onChange(event){
    const files = event.target.files

    if(files && files[0]) {
      const reader = new FileReader()

      reader.onload = (e)=>{
        this.setState({
          image: e.target.result
        })
      }

      reader.readAsDataURL(files[0])
      this.value = files[0]
    }
  }

  delete(){
    this.value = undefined
    this.setState({
      image: ''
    })
  }

  render() {
    return (
      <div>
        <input
          {...this.props.file} 
          accept='image/*'
          type='file' 
          className='industryImageInput'
          id={`IndustryImage_${this.props.label}`}
          onChange={this.onChange} />
        <label htmlFor={`IndustryImage_${this.props.label}`}>
          <div 
            className='industryImagePreview'
            style={{
              backgroundImage: `url(${this.state.image})`
            }}>
            { !this.state.image && (
               <i className="fas fa-plus"></i>
            )}
          </div>
          <p className='industryImageLabel'>{this.props.label}</p>
        </label>
      </div>
    )
  }
}

export default IndustryImage
