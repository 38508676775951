import React, { Component } from 'react'
import { Card, Elevation, Spinner } from '@blueprintjs/core'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Truncate from '../../Shared/Truncate'

import placeholder from '../../../assets/img/placeholder.jpg'
import logoSmart from '../../../assets/img/logo.png'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class BestPerformingAdsBottom extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`https://adsapi.smartintel.io/api/ads/bestPerformingAds/${page}?since=${since}&until=${until}&domain=${window.location.hostname}&campaings=${this.props.campaings}`)
    })
  }

  chart(data){
    return (
      <div className='postsContainer'>
        {data.map((competitor)=>{
          const posts = competitor.posts || []

          return (
            <div className="performingCompItem">
              <div 
                className="performingCompAvatar"
                style={{
                  backgroundImage: `url(${competitor.competitor.image || logoSmart})`
                }} />
              <div className="performingCompPosts">
                {posts.slice(0, 4).map((post)=>{
                  return (
                    <div 
                      className='post'
                      onClick={()=>(window.open(`https://www.facebook.com/${post.id}`, '_blank'))}>
                      <div className='postPicture'
                        style={{
                          backgroundImage: `url(${logoSmart})`
                        }}
                      >
                        <div style={{
                          backgroundImage: `url(${post.full_picture})`
                        }} />
                      </div>
                      <div className='postMessage'>
                        <Truncate
                          text={post.message}
                        /> 
                      </div>
                      <p className='postDate'>
                        {moment(post.created_time).format('MMM. DD YYYY')}
                      </p>
                      <p className='postRelevance'>ER Comp</p>
                      <p className='postNumber'>{numeral(post.score).format('0,0.0000')}</p>
                    </div>
                  )
                })}
                {_.range(0, 4-posts.length, 1).map((post)=>{
                  return (
                    <div 
                      className="postPlaceholder"
                      style={{
                        backgroundImage: `url(${placeholder})`
                      }}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>{Chart}</div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="adsBestPerformingComp">
        <Title title='Best Performing ADs' />
         <div className='adsBestPerformingCompContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default BestPerformingAdsBottom
