import React, {Component} from 'react'
import logoSmart from '../../../assets/img/logo.png'
import {Card, Elevation, H5, H6} from "@blueprintjs/core"
import { Tooltip } from 'react-tippy'
import _ from 'lodash'

import {connect} from 'react-redux'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import Truncate from '../../Shared/Truncate'
import IconType from '../../Shared/IconType'
import DropDown from '../../Shared/DropDown'
import SocialIcons from '../../Shared/SocialIcons'
import numeral from 'numeral'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Select, { components }  from 'react-select'
import random from "generate-random-data"

import 'react-lazy-load-image-component/src/effects/blur.css'
import './styles.css'

class Post extends Component {
  constructor( props ){
    super(props)

    this.state = {
      ...this.props,
      isOpenCategories: false,
      isOpenPromotions: false
    }

    this.onChangeCategory = this.onChangeCategory.bind(this)
    this.onChangePromotion = this.onChangePromotion.bind(this)

    this.selectCategory = this.selectCategory.bind(this)
    this.selectPromotion = this.selectPromotion.bind(this)
  }

  formatMetrics(metric, format){
    format = numeral(metric).format(metric > 100000 ? '0.0a' : format)
    return format.toUpperCase()
  }

  onChangePromotion({id}){
    storage.get('page')
    .then((page)=>{
      return axios.put(`brands/${page}/competitorPosts/${this.props.id}`, {
        promotion_id: id,
        is_brand: this.state.is_brand
      })
    }).then((response)=>{
      this.setState({
        promotion_id: id
      }, ()=>{
        //this.props.onChangeCategory(this.props.id, id)
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  onChangeCategory({id}){
    storage.get('page')
    .then((page)=>{
      return axios.put(`brands/${page}/competitorPosts/${this.props.id}`, {
        category_id: id,
        is_brand: this.state.is_brand
      })
    }).then((response)=>{
      this.setState({
        categoria_id: id
      }, ()=>{
        this.props.onChangeCategory(this.props.id, id)
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  selectCategory(){
    const { isOpenCategories } = this.state
    let selectCategory = _.find(this.props.categories, {id: this.state.categoria_id})
    selectCategory == undefined && (selectCategory = _.find(this.props.categories, {id: 1}))
    
    const options = _.map(this.props.categories, (category)=>{
      return {
        value: category.id,
        label: category.name,
        ...category
      }
    })

    return (
      <div className="postCategoriesMenuContainer">
        <DropDown
          disabled={!!this.props.lock}
          bsStyle='default'
          onClose={()=>(this.setState({isOpenCategories: false}))}
          onClick={()=>(!!this.props.lock ? this.props.onErrorPermissions() : this.setState({isOpenCategories: !isOpenCategories}))}
          title={!!selectCategory && selectCategory.name}
        >
          <Select 
            menuIsOpen={isOpenCategories}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            backspaceRemovesValue={false}
            tabSelectsValue={false}
            options={options} 
            components={{ 
              DropdownIndicator: null, 
              IndicatorSeparator: null,
              Control: (props)=>{
                return (
                  <div className='postCategoriesMenuControl'>
                    <components.Control {...props} />
                  </div>
                )
              },
              MenuList : (props)=>{
                return (
                  <div className="postCategoriesMenu">
                    {props.children}
                  </div>
                )
              },
              Option: ({ innerProps, isDisabled, data}) =>{
                return (
                  <div 
                    className='postCategoryOption'
                    onClick={()=>(this.onChangeCategory(data))} 
                    data-active={selectCategory.id == data.id}>
                    <Tooltip
                      style={{
                        width: '100%',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      title={data.name}
                      position="top"
                      trigger="mouseenter focus"
                    >
                      {data.name}
                    </Tooltip>
                  </div>
                )
              }
            }}
          />
        </DropDown>
      </div>
    )
  }

  selectPromotion(){
    const { isOpenPromotions } = this.state
    let selectPromotion = _.find(this.props.promotions, {id: this.state.promotion_id})
    selectPromotion == undefined && (selectPromotion = _.find(this.props.promotions, {id: 1}))
    
    const options = _.map(this.props.promotions, (category)=>{
      return {
        value: category.id,
        label: category.name,
        ...category
      }
    })

    return (
      <div className="postCategoriesMenuContainer">
        <DropDown
          disabled={!!this.props.lock}
          bsStyle='default'
          onClose={()=>(this.setState({isOpenPromotions: false}))}
          onClick={()=>(!!this.props.lock ? this.props.onErrorPermissions() : this.setState({isOpenPromotions: !isOpenPromotions}))}
          title={!!selectPromotion && selectPromotion.name}
        >
          <Select 
            menuIsOpen={isOpenPromotions}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            backspaceRemovesValue={false}
            tabSelectsValue={false}
            options={options} 
            components={{ 
              DropdownIndicator: null, 
              IndicatorSeparator: null,
              Control: (props)=>{
                return (
                  <div className='postCategoriesMenuControl'>
                    <components.Control {...props} />
                  </div>
                )
              },
              MenuList : (props)=>{
                return (
                  <div className="postCategoriesMenu">
                    {props.children}
                  </div>
                )
              },
              Option: ({ innerProps, isDisabled, data}) =>{
                return (
                  <div 
                    className='postCategoryOption'
                    onClick={()=>(this.onChangePromotion(data))} 
                    data-active={selectPromotion.id == data.id}>
                    <Tooltip
                      style={{
                        width: '100%',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                      title={data.name}
                      position="top"
                      trigger="mouseenter focus"
                    >
                      {data.name}
                    </Tooltip>
                  </div>
                )
              }
            }}
          />
        </DropDown>
      </div>
    )
  }

  render () {
    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="postContainer" 
        id={this.state.id}>
        <div className="postIcon">
          <SocialIcons
            type={!!this.state.type ? this.state.type.toUpperCase() : this.state.type} 
            provider={this.state.provider} 
          />
        </div>
        <div className="post">
          <div className="postTopContainer">
            <div className="postImage">
              <a href={this.state.link} target="_blank">
                <LazyLoadImage
                  effect='blur' 
                  src={this.state.picture} 
                  alt="" 
                />
              </a>
            </div>
            <div className="postExtras">
              <p className="postExtrasType"> 
                <IconType 
                  type={!!this.state.type ? this.state.type.toUpperCase() : this.state.type} 
                />
              </p>
              {this.state.is_brand && (
                <div className="postExtrasEngagment">
                  <H6>
                    ENG Score
                  </H6>
                  <p>
                    {this.formatMetrics(this.state.es_calculated, '0,0.000')}%
                  </p>
                </div>
              )}
              <div className="postExtrasEngagment">
                <H6>
                  ES Competition
                </H6>
                <p>
                  {this.formatMetrics(this.state.es, '0,0.0000')}
                </p>
              </div>
              <div 
                className="postExtrasCategory">
                {/*this.selectCategory()*/}
              </div>
              <div 
                className="postExtrasCategory postExtrasPromotions">
                {/*this.selectPromotion()*/}
              </div>
            </div>
          </div>
          <div className="postMessage">
            <Truncate 
              clamp={4} 
              keywords={this.props.keywords}
              text={this.state.caption || this.state.message} />
          </div>
          <div className="compertitorPostDate">
            <div className="compertitorInfoBrand">
              <img 
                src={(this.props.is_brand ? this.props.competitor.picture : this.props.competitor.image) || logoSmart} 
                alt="Logo Smart" 
                className="logoBrand" 
              />
              <div>
                <p>{this.props.brand.name.replace('audi','').replace('Audi','')}</p>
              </div>
            </div> 
            <p>{moment(this.state.post_published_date).format("llll")}</p>
          </div>
          <div className="postInsights">
            <div className="postInsightsInfo">
              <H5>
                REACH
              </H5>
              <div className="postInsightsData">
                <div className="postDataItem">
                  <p className="postDataInfo">{this.formatMetrics(this.state.post_impressions_organic, '0,0')}</p>
                  <p className="postDataTitle">Organic</p>
                </div>
                <div className="postDataItem">
                  <p className="postDataInfo">{this.formatMetrics(this.state.post_impressions_paid, '0,0')}</p>
                  <p className="postDataTitle">Paid</p>
                </div>
                <div className="postDataItem">
                  <p className="postDataInfo">{this.formatMetrics((this.state.post_impressions_organic || 0) + (this.state.post_impressions_paid || 0), '0,0')}</p>
                  <p className="postDataTitle">Total</p>
                </div>
              </div>
            </div>
            <div className="postInsightsInfo">
              <H5>
                ENGAGEMENT
              </H5>
              <div className="postInsightsData">
                <div className="postDataItem">
                  <p className="postDataInfo">{this.formatMetrics( this.state.is_brand ? this.state.wow+this.state.likes+this.state.anger+this.state.haha+this.state.sorry+this.state.love+this.state.anger : this.state.metrics.reactions, '0,0')}</p>
                  <p className="postDataTitle">{
                    !!this.state.ins_media_type && this.state.ins_media_type.toLowerCase().includes('story') 
                    ? 'Views'
                    : (this.state.provider == 'facebook' ? 'Reactions' : 'Likes')
                  }</p>
                </div>
                <div className="postDataItem">
                  <p className="postDataInfo">{this.formatMetrics(this.state.is_brand ? this.state.shares : this.state.metrics.shares, '0,0')}</p>
                  <p className="postDataTitle">{
                      !!this.state.ins_media_type && this.state.ins_media_type.toLowerCase().includes('story') 
                      ? 'DM'
                      : (this.state.provider == 'twitter' ? 'RT’s' : 'Shares')
                  }</p>
                </div>
                <div className="postDataItem">
                  <p className="postDataInfo">{this.formatMetrics(this.state.is_brand ? this.state.comments : this.state.metrics.comments, '0,0')}</p>
                  <p className="postDataTitle">{this.state.provider == 'twitter' ? 'Replies' : 'Comments'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    )
 }
}

const mapStateToProps = (state, ownProps) => {
  return {
    categories: state.categories.categories,
    promotions: state.promotions.promotions
 }
}

export default connect(
  mapStateToProps, null)(Post)
