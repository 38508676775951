import axios from 'axios'
import storage from './storage'
import { AUTH_URL } from '../config'

const Social = (provider) => {
	return new Promise((resolve, reject) => {
		const popupWidth = 580
		const popupHeight = 400
		const popupLeft = (window.screen.width - popupWidth) / 2
		const popupTop = (window.screen.height - popupHeight) / 2
		/*const { clientId, scope } = this.props
	  const redirectUri = this.props.redirectUri || window.location.href*/

	  /*const href = `http://www.facebook.com/v3.0/dialog/oauth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&display=popup&scope=${scope || 'email,pages_show_list,read_insights,instagram_basic,instagram_manage_insights'}`*/

	  const href = `${AUTH_URL}login/${provider}`
		let popup = window.open(href, '_blank', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${popupTop}`)

		if(!!popup){
			const message = (event)=>{
				if(!!event.data && !!event.data.id){
					clearInterval(interval)
					resolve(event.data)
					popup.close()
				}
			}
			
			window.addEventListener('message', message, false)

			const interval = setInterval(()=>{
				if (!popup || popup.closed || popup.closed === undefined) {
					clearInterval(interval)
					reject('window closed')
		    }
				popup.postMessage && popup.postMessage('facebook',  `${AUTH_URL}login/${provider}`)
			}, 100)
		}else{
			reject('popup lock')
		}
	})
}

export default Social
