import React, { Component } from 'react'
import { Card, Elevation, Spinner } from '@blueprintjs/core'

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'
import SimpleBar from 'simplebar-react'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Composition extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range, filter){
    const { competitor } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/vision/${page}/objects?top=15&since=${since}&until=${until}&provider=${filter}`)
    }).then((response)=>{
      return _.map(response.data.data, (item, index)=>{
        item.index = index
        return item
      })
    })
  }

  body(data){
    return (
      <SimpleBar style={{maxHeight: '125px', width: '100%'}}>
        <tbody>
          {data.map((object, index)=>{
            return (
              <tr>
                <td>{object.index+1}. {object.name}</td>
                <td>{numeral(object.score).format('0,0.00')}%</td> 
              </tr>
            )
          })}
          {_.range(0, 5 - data.length, 1).map((object, index)=>{
            return (
              <tr>
                <td>-</td>
                <td>-</td> 
              </tr>
            )
          })}
        </tbody>
      </SimpleBar>
    )
  }

  chart(data){
    return (
      <div className="socialCompositionItems">
        <div className="socialCompositionItem">
          <table>
            <thead>
              <tr>
                <th>Objects</th>
                <th>ER</th> 
              </tr>
            </thead>
            {this.body(data)}
          </table>
        </div>
      </div>
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialComposition">
        <Title title='Composition' />
         <div className='socialCompositionContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default Composition
