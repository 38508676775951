import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import thunk from 'redux-thunk';


const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

export default ({ children, initialState = {} }) => {
  const store = createStoreWithMiddleware(reducers);

  return <Provider store={store}>{children}</Provider>;
};
