import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import { Colors } from '../../../../../config'
import Color from 'color'

import numeral from 'numeral'
import _ from 'lodash'

import './styles.css'

class Details extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: this.props.data
    }

    this.setData = this.setData.bind(this)
  }

  setData(data){
    this.setState({
      data: data
    })
  }

  render() {
    return (
      <div className='retailDetails'>
        <p className='retailDetailsTitle'>Weekly Data</p>
        <div className='retailDetailsItems'>
          <div className='retailDetailsItem'>
            <p className='retailDetailsItemValue'>MX{numeral(this.state.data.gross_sales_mobica).format('0,0')}</p>
            <p className='retailDetailsItemTitle'>Mobica Gross Sales</p>
          </div>
          <div className='retailDetailsItem'>
            <p className='retailDetailsItemValue'>MX{numeral(this.state.data.gross_sales_onLine).format('0,0')}</p>
            <p className='retailDetailsItemTitle'>Online Gross Sales</p>
          </div>
          <div className='retailDetailsItem'>
            <p className='retailDetailsItemValue'>MX{numeral(this.state.data.gross_sales_callcenter).format('0,0')}</p>
            <p className='retailDetailsItemTitle'>Call Center Gross Sales</p>
          </div>
        </div>
      </div> 
    )
  }
}

export default Details
