import React, { Component } from 'react'
import { 
  Dialog
} from "@blueprintjs/core"

import './styles.css'

class Alert extends Component {
    constructor(props){
      super(props)
      this.state = {
        visible: false,
      }

      this.onAccept = this.onAccept.bind(this)
      this.onCancel = this.onCancel.bind(this)
      this.show = this.show.bind(this)
      this.hide = this.hide.bind(this)

      this.reject = null
      this.resolve = null
    }

    show(data = {}){
      this.setState({
        visible: true,
        ...this.props,
        ...data
      })

     return new Promise((resolve, reject) => {
       this.resolve = resolve
       this.reject = reject
     })
    }

    hide(){
      this.setState({
        visible: false
      })
    }


    onAccept(){
      this.hide()
      this.resolve('accept')
      this.props.onAccept && this.props.onAccept()
    }

    onCancel(){
      this.hide()
      this.reject('cancel')
      this.props.onCancel && this.props.onCancel()
    }

    render() {
      return(
        <Dialog
          isOpen={this.state.visible}
          className='Alert'
        >
          <div className='AlertContainer'>
            {!!this.state.title && (
              <div className="bp3-dialog-header AlertHeader">
                <h4 className="bp3-heading">{this.state.title || 'Alert'}</h4>
              </div>
            )}
            <div className="bp3-dialog-body">
              <p>{this.state.body}</p>
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <button
                  onClick={this.onAccept} 
                  type="button" 
                  className="bp3-button AlertAccept">
                  Accept
                </button>
                {!this.props.confirm && (
                  <button
                    onClick={this.onCancel} 
                    type="button" 
                    className="bp3-button AlertCancel">
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      )
    }
}

export default Alert
