import React, { Component } from 'react'
class SocialIcons extends Component {
  render () {
    const story = !!this.props.type && this.props.type.includes('story')
    const icons = {
      facebook: 'fab fa-facebook-f',
      ads: 'fab fa-facebook-f',
      twitter: 'fab fa-twitter',
      instagram: 'fab fa-instagram',
      google: 'fab fa-google',
      linkedin: 'fab fa-linkedin-in',
      story: 'far fa-hourglass',
      youtube: 'fab fa-youtube'
    }
    return this.props.provider ? <i {...this.props}  className={`${story ? icons['story'] : icons[this.props.provider]} ${this.props.className}` }  /> : <i/>
  }
}

export default SocialIcons