import React, { Component } from 'react';
import { 
  Dialog, 
  Button, 
  Card, 
  FormGroup, 
  InputGroup, 
  Elevation, 
  Spinner, 
  Classes
} from "@blueprintjs/core"
import _ from 'lodash'
import IndustryImage from '../IndustryImage'
import Alert from '../../../../Shared/Alert'
import Modal from '../../../../Shared/Modal'
import { Competitors } from '../../../../../config'
import 'rodal/lib/rodal.css'
import './styles.css'

class IndustrySettings extends Component {
  constructor(props){
    super(props)
    this.state = {
      open: false
    }

    this.inputs = {
      names: {
        brand: '',
        competitors: []
      },
      images: {
        brand: '',
        competitors: []
      },
      facebook: [],
      instagram: [],
      twitter: [],
      urls: {
        brand: '',
        competitors: []
      }
    }


    this.onDeleteCompetitor = this.onDeleteCompetitor.bind(this)
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  onDeleteCompetitor(index){
    _.forEach(this.inputs, (input, key)=>{
      if(!!input.competitors){
        input = input.competitors
      }

      key != 'images' ? (input[index].value = '') : input[index].delete()
    })
  }

  show(){
    this.setState({
      open: true
    })
  }

  hide(){
    this.setState({
      open: false
    })
  }

  async onSave(){
    const data = _.reduce(this.inputs, (data, info, index)=>{
      if(Array.isArray(info)){
        data[index] = _.map(info, 'value')
      }else if(index != 'images'){
        data[index] = {}
        data[index]['brand'] = info.brand.value
        data[index]['competitors'] = _.map(info.competitors, 'value')
      }

      return data
    }, {})
    
    const images = _.reduce(this.inputs.images, (data, info, index)=>{
      if(Array.isArray(info)){
        _.forEach(info, (image)=>{
          data.push(image.url())
        })
      }else{
        data.push(info.url())
      }

      return data
    }, [])

    await Promise.all(images)
    .then((responses)=>{
      const images = {}
      images.brand = responses[0]
      images.competitors = responses.slice(1, Competitors)
      data['images'] = images

      return data
    }).then((data)=>{
      this.props.onSave && this.props.onSave({
        industry_settings: JSON.stringify(data)
      })
      this.alert.show()
    })
  }


  render() {
    const { industry } = this.props
    const rangeCompetitors = _.range(1, Competitors)

    return (
      <Dialog
        onClose={this.hide}
        title="Settings"
        {...this.props}
        isOpen={this.state.open}
        backdropProps={{
          onClick: this.hide,
          style: {
            cursor: 'pointer'
          }
        }}
        className="bp3-dark industrySettingsDialog">
        <Alert 
          body='Your data was updated'
          confirm
          onAccept={()=>(this.hide())}
          ref={(Alert)=>(this.alert = Alert)} 
        />
        <div className={Classes.DIALOG_BODY}>
          <div className="grid">
            <div className="col-4">
              <Card 
                interactive={false} 
                elevation={Elevation.TWO} 
                className="no-pad-t-b industrySettingsItem">
                <div className="subtitle">
                  Names
                </div>
                <div className="industrySettingsFormContainer">
                  <FormGroup
                    className={`industrySettingsForm ${Competitors < 5 && 'industrySettingsFormFull'}`}
                  >
                    <InputGroup 
                      inputRef={(input)=>(
                        this.inputs.names.brand = input
                      )}
                      defaultValue={industry.names.brand}
                    />
                    <label className="industrySettingsLabel">Your Brand</label>
                  </FormGroup>
                  {rangeCompetitors.map((competitor, index)=>{
                    return (
                      <FormGroup 
                        className={`industrySettingsForm ${Competitors < 5 && 'industrySettingsFormFull'}`}
                      >
                        <InputGroup
                          inputRef={(input)=>(
                            this.inputs.names.competitors[index] = input
                          )}
                          rightElement={(
                            <div className="industrySettingsDelete">
                              <i 
                                onClick={()=>(this.onDeleteCompetitor(index))}
                                className="fas fa-trash-alt" />
                            </div>
                          )}
                          defaultValue={industry.names.competitors[index]}
                        />
                        <label className="industrySettingsLabel">{`Competitor ${competitor}`}</label>
                      </FormGroup>
                    )
                  })}
                </div>
              </Card>
            </div>
            <div className="col-4">
              <Card 
                interactive={false} 
                elevation={Elevation.TWO} 
                className="no-pad-t-b industrySettingsItem">
                <div className="subtitle">
                  Facebook Handle
                </div>
                <div className="industrySettingsFormContainer">
                  {rangeCompetitors.map((competitor, index)=>{
                    return (
                      <FormGroup 
                        className={`industrySettingsForm ${Competitors < 5 && 'industrySettingsFormFull'}`}
                      >
                        <InputGroup
                          inputRef={(input)=>(
                            this.inputs.facebook[index] = input
                          )}
                          defaultValue={industry.facebook[index]}
                        />
                        <label className="industrySettingsLabel">{`Competitor ${competitor}`}</label>
                      </FormGroup>
                    )
                  })}
                </div>
              </Card>
            </div>
            <div className="col-4">
              <Card 
                interactive={false} 
                elevation={Elevation.TWO} 
                className="no-pad-t-b industrySettingsItem">
                <div className="subtitle">
                  Instagram Handle
                </div>
                <div className="industrySettingsFormContainer">
                  {rangeCompetitors.map((competitor, index)=>{
                    return (
                      <FormGroup 
                        className={`industrySettingsForm ${Competitors < 5 && 'industrySettingsFormFull'}`}
                      >
                        <InputGroup
                          inputRef={(input)=>(
                            this.inputs.instagram[index] = input
                          )}
                          defaultValue={industry.instagram[index]}
                        />
                        <label className="industrySettingsLabel">{`Competitor ${competitor}`}</label>
                      </FormGroup>
                    )
                  })}
                </div>
              </Card>
            </div>
            <div className="col-4">
              <Card 
                interactive={false} 
                elevation={Elevation.TWO} 
                className="no-pad-t-b industrySettingsItem">
                <div className="subtitle">
                  Image
                </div>
                <div className='industrySettingsImages'>
                  <div className={`industrySettingsImagesItem ${Competitors < 5 && 'industrySettingsImagesItemFull'}`}>
                    <IndustryImage 
                      label='Brand'
                      file={{
                        disabled: true
                      }}
                      ref={(input)=>(
                        this.inputs.images.brand = input
                      )}
                      defaultValue={this.props.provider.picture}
                    />
                  </div>
                  {rangeCompetitors.map((competitor, index)=>{
                    return (
                      <div 
                        className={`industrySettingsImagesItem ${Competitors < 5 && 'industrySettingsImagesItemFull'}`}>
                        <IndustryImage 
                          label={`Competitor ${competitor}`}
                          ref={(input)=>(
                            this.inputs.images.competitors[index] = input
                          )}
                          defaultValue={industry.images.competitors[index]}
                        />
                      </div>
                    )
                  })}
                </div>
              </Card>
            </div>
            <div className="col-4">
              <Card 
                interactive={false} 
                elevation={Elevation.TWO} 
                className="no-pad-t-b industrySettingsItem">
                <div className="subtitle">
                  Twitter Handle
                </div>
                <div className="industrySettingsFormContainer">
                  {rangeCompetitors.map((competitor, index)=>{
                    return (
                      <FormGroup 
                        className={`industrySettingsForm ${Competitors < 5 && 'industrySettingsFormFull'}`}
                      >
                        <InputGroup
                          inputRef={(input)=>(
                            this.inputs.twitter[index] = input
                          )}
                          defaultValue={industry.twitter[index]}
                        />
                        <label className="industrySettingsLabel">{`Competitor ${competitor}`}</label>
                      </FormGroup>
                    )
                  })}
                </div>
              </Card>
            </div>
            <div className="col-4">
              <Card 
                interactive={false} 
                elevation={Elevation.TWO} 
                className="no-pad-t-b industrySettingsItem">
                <div className="subtitle">
                  URL
                </div>
                <div className="industrySettingsFormContainer">
                  <FormGroup 
                    className={`industrySettingsForm ${Competitors < 5 && 'industrySettingsFormFull'}`}
                  >
                    <InputGroup
                      inputRef={(input)=>(
                        this.inputs.urls.brand = input
                      )}
                      defaultValue={industry.urls.brand}
                    />
                    <label className="industrySettingsLabel">Your Brand</label>
                  </FormGroup>
                  {rangeCompetitors.map((competitor, index)=>{
                    return (
                      <FormGroup 
                        className={`industrySettingsForm ${Competitors < 5 && 'industrySettingsFormFull'}`}
                      >
                        <InputGroup 
                          inputRef={(input)=>(
                            this.inputs.urls.competitors[index] = input
                          )}
                          defaultValue={industry.urls.competitors[index]}
                        />
                        <label className="industrySettingsLabel">{`Competitor ${competitor}`}</label>
                      </FormGroup>
                    )
                  })}
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button 
              onClick={this.onSave} 
              minimal="true" 
              large="true" 
              className="industrySettingsSave">
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default IndustrySettings
