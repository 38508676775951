import React, { Component } from 'react'
import './styles.css'

class Events extends Component {
    getInitials(text=''){
      let parts = text.split(' ')
      let initials = ''
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
          initials += parts[i][0]
        }
      }
      return text == 'APPROVED BY CLIENT' ? 'SP' : initials.toUpperCase()
    }

    render() {
      return(
        <div {...this.props} key={this.props.id}>
          <div className='calendarEvents'>
            {this.props.events.map((event,i)=>{
              const error = event.state.toLowerCase() == 'error'
              const initials = this.getInitials(event.client_state)
              const ready = 
                (!!event.images && event.images.length > 0) 
                || (!!event.copy && event.copy.length > 0) 
                || (!!event.comments && event.comments.length > 0)
              
              return (
                <div key={i}
                  onClick={()=>(this.props.show(event))}
                  className={`calendarEvent calendarEvent-${error ? 'error' :event.provider}`}>
                  <p className='calendarEventTitle'>{event.categoria_id && event.categoria_id.name ? event.categoria_id.name : 'No Category'}</p>
                  <p className={`calendarEventStatus ${initials}`}>{initials}</p>
                  <i className={`far fa-calendar-${ready ? 'check' : 'times'}`}></i>
                </div>
              )
            })}
          </div>
        </div>
      )
    }
}

export default Events
