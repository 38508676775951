import React, { Component } from 'react';
import logoLogin from '../../assets/img/loginsmart.png';

import { Redirect } from 'react-router-dom'
import { API_URL, Test } from '../../config'
import storage from '../../services/storage'
import social from '../../services/social'
import axios from '../../services/axios'

import moment from 'moment'

import './styles.css'

class Login extends Component {
  constructor(props){
    super(props)
    this.state = {
      auth: null
    }

    this.login = this.login.bind(this)
    this.onLogin = this.onLogin.bind(this)
  }

  setData(response){
    return axios.post('users', {
      user_token: response.token,
      facebook_id: response.id
    }).then((response)=>{
      const { data } = response.data
      const zone = moment().format('Z')
      const expiration = moment().add(data.expiration, 'ms').subtract(2, 'days').utcOffset(zone).format()

      return Promise.all([
        storage.set('auth_token', data.token),
        storage.set('user', data.user),
        storage.set('expiration', expiration)
      ])
    })
  }

  onLogin(){
    social('facebook')
    .then(this.setData)
    .then(()=>(window.location.href = '/home'))
    .catch((error)=>{
      console.warn('error')
      window.Sentry.captureException(error)
    })
  }

  componentWillMount(){
    storage.get('auth_token')
    .then(()=>{
      this.setState({
        auth: true
      })
    }).catch(()=>{
      if(window.location.hostname == 'apple.smartintel.io'){
        this.setData(Test)
        .then(()=>(window.location.href = '/home'))
        .catch((error)=>{
          console.log(error)
        })
      }else{
        this.setState({
          auth: false
        })
      }
    })
  }

  login(){
    return (
      <div className="login">
        <div className="mainLogin grid-center-middle">
          <div className="col-6_sm-12">
            <div className="mainLoginLogo">
              <img src={logoLogin} className="logoLogin" alt="logo" />
            </div>
          </div>
          <div className="col-6_sm-12">
            <div className="loginForm">
              {window.location.hostname == 'smartintel.io' ? (
                <button onClick={this.onLogin} className="loginButtonFacebook">
                  <div>
                    <span className="loginButtonText">Login with facebook</span>
                    <i className="fab fa-facebook-square fa-2x"></i>
                  </div>
                </button>
              ) : (
                <button onClick={this.onLogin} className="loginButton">
                  <div>
                    <span className="loginButtonText">Login with facebook</span>
                    <i className="fab fa-facebook-square fa-2x"></i>
                  </div>
                </button>
              )}
              <p className="powered">Power by Spartan Technology</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  auth(auth){
    return (
      !!auth 
      ? <Redirect to='/home' />
      : this.login()
    )
  }

  render() {
    const { auth } = this.state
    return (
      auth != null 
      ? this.auth(auth) 
      : <div />
    )
  }
}

export default Login