import React, { Component } from 'react'
import { Card, Elevation, Spinner } from "@blueprintjs/core"

import Color from 'color'

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import { Colors } from '../../../config'
import Chart from '../../Shared/Chart'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class AdsDistribution extends Component {
	constructor(props){
		super(props)
		this.state = {
			data: []
		}

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
		this.chart = this.chart.bind(this)
		this.getData = this.getData.bind(this)
	}

	getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

		return storage.get('page')
		.then((page)=>{
			return axios.get(`https://adsapi.smartintel.io/api/ads/distribution/${page}?since=${since}&until=${until}&domain=${window.location.hostname}&campaings=${this.props.campaings}`)
		})
	}
  
  chart(data){    
    const custom =  _.map(data, (item, index)=>{
      const background = Colors[index]
      const color = Color(background)

      return {
        'plot-rules': {
          rule: `%p==${index}`,
          text: item.name.slice(0, 3),
          color: color.isLight() ? '#36383a' : '#ffffff'
        },
        'plot-labels': {
          rule: `%p==${index}`,
          text: `<span style="font-weight:bold"><div style="width: 10px; height: 10px; background-color: ${Colors[index]}; display: inline-block"></div> ${item.name} - ${numeral(item.value).format('0,0[.]00')}</span>`,
          borderColor: Colors[index]
        }
      }
    })

    let config = {
      type: 'pie',
      backgroundColor: '#363737',
      globals: {
        fontFamily: 'Helvetica'
      },
      gui: {
        behaviors: [{
          id: 'ViewSource',
          enabled: 'none'
        },{
          id: 'HideGuide',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'ViewAsPng',
          enabled: 'none'
        },{
          id: 'ViewDataTable',
          enabled: 'none'
        }]
      },
      plotarea:{
        backgroundColor : '#363737',
        adjustLayout: true,
        margin: 0
      },
      labels:[{
        x: '50%',
        y: '50%',
        anchor: 'c',
        text: _.sumBy(data, 'value'),
        fontSize: 24,
        fontColor: '#fff'
      }],
      plot:{
        slice: 70,
        marginRight: 100,
        borderWidth: 0,
        shadow: 0,
        cursor: 'hand',
        valueBox: {
          fontWeight: 100,
          fontSize: '12px',
          rules: _.concat(
            _.map(custom, 'plot-rules'), [{
              rule: '%npv < 5',
              text: ''
            }]
          )
        },
        tooltip: {
          text: '%v',
          multiple: true,
          fontSize: '12px',
          color: '#FFFFFF',
          backgroundColor: '#000',
          borderWidth: 0,
          alpha: 0.6,
          callout: true,
          shadow: 0,
          borderRadius: 4,
          rules: _.map(custom, 'plot-labels')
        }
      },
      series:  _.map(data, (item, index)=>{
        return {
          values: [item.value],
          backgroundColor: Colors[index]
        }
      })
    }

    return (
      <div>
        <Chart 
          width="100%" 
          height="400px"
          output="canvas"
          data={config} 
        />
      </div>
    )
  } 

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>{Chart}</div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }


  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return(
      <Card 
      	interactive={false} 
      	elevation={Elevation.TWO} 
      	className="no-pad-t-b"
        id="adsDistribution">
      	<Title title='Ads Distribution' />
        <div
          className='adsDistributionContainer'
          ref={(ref)=>(this.chartContainer = ref)}
        >
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default AdsDistribution
