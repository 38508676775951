import React, { Component } from 'react'
import { Button, H1 } from "@blueprintjs/core"
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import _ from 'lodash'
import { RangeConfig } from '../../../config'
import storage from '../../../services/storage'

//Componentes
import AdsScore from '../AdsScore/AdsScore'
import Demographics from '../Demographics/Demographics'
import Audience from '../Audience/Audience'
import Languages from '../Languages/Languages'
import Sentiment from '../Sentiment/Sentiment'
import BestPerformingAds from '../BestPerformingAds/BestPerformingAds'
import Interactions from '../Interactions/Interactions'
import AdsDistribution from '../AdsDistribution/AdsDistribution'
import TotalActivity from '../TotalActivity/TotalActivity'
import TopicData from '../TopicData/TopicData'
import TopInterests from '../TopInterests/TopInterests'
import TopInterestsCampaings from '../TopInterestsCampaings'
import TotalSpend from '../TotalSpend/TotalSpend'
import Placement from '../Placement/Placement'
import PaidMedia from '../PaidMedia/PaidMedia'
import Benchmark from '../Benchmark/Benchmark'
import CompetitionInvestment from '../CompetitionInvestment/CompetitionInvestment'
import AdsReach from '../AdsReach/AdsReach'
import BestPerformingAdsBottom from '../BestPerformingAdsBottom/BestPerformingAdsBottom'

import 'bootstrap-daterangepicker/daterangepicker.css'
import './styles.css'

class MainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      campaing: {
        campaign_id: 0
      },
      range: [
        moment(this.props.range[0]).startOf('day').toDate(),
        moment(this.props.range[1]).startOf('day').toDate()
      ]
    } 

    this.range = [
      moment(this.props.range[0]).startOf('day').toDate(),
      moment(this.props.range[1]).startOf('day').toDate()
    ]

    this.components = {}
    this.onSearch = this.onSearch.bind(this)
    this.onGetData = this.onGetData.bind(this)
    this.onChangeRange = this.onChangeRange.bind(this)
    this.onSelectCampaing = this.onSelectCampaing.bind(this)
  }
  
  onSearch(){
    this.onGetData()
  }

  onGetData(){
    this.setState({
      range: this.range
    })
  }

  onChangeRange({startDate, endDate}){
    const range = [startDate.toDate(), endDate.toDate()]

    this.dateStart.innerHTML = moment(range[0]).format('YYYY-MM-DD')
    this.dateEnd.innerHTML = moment(range[1]).format('YYYY-MM-DD')

    storage.set('range', range)
    
    this.range = range
  }


  onSelectCampaing(campaing){
    this.setState({
      campaing: campaing
    })
  }

  render () {
    const { campaings, match } = this.props
    return(
      <div data-html2canvas-print>
        <div className="top-container">
          <div className="brands-added">
            <div className="grid">
              <div className="col-5_sm-12">
                <H1 className="brands-added__title" data-html2canvas-ignore>ADs Intelligence</H1>
              </div>
              <div className="col-7_sm-12">
                <div className="searchContainer" data-html2canvas-ignore>
                  <div className="dateSmart bp3-dark">
                    <span className="searchCalendarIcon"><i className="far fa-calendar-alt"></i></span>
                    <DateRangePicker
                      {...RangeConfig} 
                      startDate={this.state.range[0]} 
                      endDate={this.state.range[1]}
                      onApply={(event, picker)=>(this.onChangeRange(picker))}>
                      <div className="daterangepickerDates">
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateStart = ref)}>
                          {moment(this.state.range[0]).format('YYYY-MM-DD')}
                        </span>
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateEnd = ref)}>
                          {moment(this.state.range[1]).format('YYYY-MM-DD')}
                        </span>
                      </div>
                    </DateRangePicker>
                    <Button onClick={this.onSearch} minimal="true" className="button btn-outline" >Search</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="social-intelligence__container" id="MainContainer">
          <div className="grid">
            <div className="col-12">
              <AdsScore
                onSelectCampaing={this.onSelectCampaing} 
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['score'] = ref))}
              />
            </div>
            <div className="col-5_sm-12">
              <TopInterests
                page={match.params.id} 
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['interests'] = ref))}
              />
            </div>
            <div className="col-3_sm-12">
              <TopInterestsCampaings
                {...this.state.campaing}
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['interestsCategory'] = ref))}
              />
            </div>
            <div className="col-4_sm-12 no-pad-b">
              <div className="col-12">
                <div className="best-days-shell">
                  <div className="grid">
                    <div className="col-12 no-pad-l-f">
                      <TotalSpend 
                        range={this.state.range}
                        campaings={campaings}
                        ref={(ref)=>( ref && (this.components['spend'] = ref))}
                      />
                    </div>
                    <div className="col-12 no-pad-l-f no-pad-b">
                      <Placement 
                        range={this.state.range}
                        campaings={campaings}
                        ref={(ref)=>( ref && (this.components['placement'] = ref))} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4_sm-12">
              <Demographics 
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['demographics'] = ref))}  
              />
            </div>
            <div className="col-4_sm-12">
              <Audience 
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['audience'] = ref))}
              />
            </div>
            <div className="col-4_sm-12 no-pad-b">
              <div className="col-12">
                <div className="best-days-shell">
                  <div className="grid">
                    <div className="col-12 no-pad-l-f no-pad-b">
                      <Sentiment 
                        range={this.state.range}
                        campaings={campaings}
                        ref={(ref)=>( ref && (this.components['sentiment'] = ref))} 
                      />
                    </div>
                    <div className="col-12 no-pad-l-f">
                      <Sentiment 
                        {...this.state.campaing}
                        range={this.state.range}
                        campaings={campaings}
                        ref={(ref)=>( ref && (this.components['sentimentCategory'] = ref))} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <BestPerformingAds 
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['performingAds'] = ref))} 
              />
            </div>
            {/* 
            <div className="col-12">
              <TopicData 
                id={this.props.id} />
            </div>
            */}
            <div className="col-4_sm-12">
              <Interactions 
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['interactions'] = ref))}
              />
            </div>
            <div className="col-4_sm-12">
              <AdsDistribution
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['distribution'] = ref))}  
              />
            </div>
            <div className="col-4_sm-12">
              <TotalActivity  
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['totalActivity'] = ref))} 
              />
            </div>
            <div className="col-12">
              <PaidMedia  
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['paidMedia'] = ref))}  
              />
            </div>
            <div className="col-4_sm-12">
              <Benchmark  
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['benchmark'] = ref))} 
              />
            </div>
            <div className="col-4_sm-12">
              <CompetitionInvestment  
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['competitionInvestment'] = ref))}  
              />
            </div>
            <div className="col-4_sm-12">
              <AdsReach  
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['adsReach'] = ref))}  
              />
            </div>
            <div className="col-12">
              <BestPerformingAdsBottom  
                range={this.state.range}
                campaings={campaings}
                ref={(ref)=>( ref && (this.components['performingAdsBottom'] = ref))}  
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MainContainer
