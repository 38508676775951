import React, { Component } from 'react'
import { Card, Elevation, Position, H5, H6  } from "@blueprintjs/core"

import logoSmartCom from '../../../../../assets/img/loginsmart.png'
import logoSmart from '../../../../../assets/img/logo.png'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import { Tooltip } from 'react-tippy'

import Truncate from '../../../../Shared/Truncate'
import SocialIcons from '../../../../Shared/SocialIcons'
import IconType from '../../../../Shared/IconType'

import Swiper from 'react-id-swiper'

import Loading from '../../../../Shared/LoadingCharts'
import Lists from '../Lists'

import './styles.css'

const channels = [{
  alias: 'Landing Page',
  id: 'direct',
  data: {
    metrics: 'ga:sessions',
    dimensions: 'ga:landingPagePath',
    sort: '-ga:sessions',
    maxResults: '10',
    filters: ''
  }},{
  alias: 'Keyword',
  id: 'organic search',
  data: {
    metrics: 'ga:sessions',
    dimensions: 'ga:keyword',
    sort: '-ga:sessions',
    maxResults: '10',
    filters: ''
  }},{
  alias: 'Source',
  id: '(other)',
  data: {
    metrics: 'ga:sessions',
    dimensions: 'ga:fullReferrer',
    sort: '-ga:sessions',
    maxResults: '10',
    filters: ''
  }},{
  alias: 'Keyword',
  id: 'paid search',
  data: {
    metrics: 'ga:sessions, ga:cpm',
    dimensions: 'ga:keyword',
    sort: '-ga:sessions',
    maxResults: '10',
    filters: ''
  }},{
  alias: 'Source',
  id: 'referral',
  data: {
    metrics: 'ga:sessions',
    dimensions: 'ga:source',
    sort: '-ga:sessions',
    maxResults: '10',
    filters: ''
  }},{
  alias: 'Social',
  id: 'social',
  data: {
    metrics: 'ga:sessions',
    dimensions: 'ga:socialNetwork',
    sort: '-ga:sessions',
    maxResults: '10',
    filters: ''
  }},{
  alias: 'Source',
  id: 'display',
  data: {
    metrics: 'ga:sessions',
    dimensions: 'ga:source',
    sort: '-ga:sessions',
    maxResults: '10',
    filters: ''
  }},{
  alias: 'Landing Page',
  id: 'email',
  data: {
    metrics: 'ga:sessions',
    dimensions: 'ga:landingPagePath',
    sort: '-ga:sessions',
    maxResults: '10',
    filters: ''
}}]

class Panel extends Component {
  constructor(props){
    super(props)
    this.state = {
      active: 0
    }
    
    this.lists = this.lists.bind(this)
    this.score = this.score.bind(this)
    this.channels = this.channels.bind(this)
  }
  
  secondsToTime(s){
    s = s * 1000
    const pad = (n, z)=>{
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;
    
    return pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
  }
  
  channels(data){
    return (
      <div className='googleSalesScoreChannels'>
        <Swiper 
          effect='fade'
          rebuildOnUpdate
          direction='vertical'
          autoHeight
          spaceBetween={0}
          initialSlide={Math.floor(this.state.active/4)}
          ref={(swiper)=>(swiper && (this.swiperChannels = swiper.swiper))}
        >
          {_.chunk(data.rows, 4).map((rows, rowIndex)=>{
            return (
              <div>
                <div className="googleSalesScoreChannelsContainer">
                {rows.map((row, index)=>{
                  return (
                    <div
                      className={`googleSalesScoreChannel ${this.state.active == (rowIndex * 4) + index && 'active'}`} 
                      onClick={()=>(this.setState({active: (rowIndex * 4) + index}))}>
                      <p className="googleSalesScoreChannelName">{row[0]}</p>
                      <p className="googleSalesScoreChannelScore">{numeral(row[11]).format('0,0[.]00')}%</p>
                      <p className="googleSalesScoreChannelPosition">#{(rowIndex * 4) + index + 1}</p>
                    </div>
                  )
                })}
                {_.range(4 - rows.length).map((item, index)=>{
                  return (
                    <div 
                      className="googleSalesScoreChannel googleSalesScoreChannelPlaceholder"
                      style={{
                        backgroundImage: `url(${logoSmart})`
                      }}
                    />
                  )
                })}
                </div>
              </div>
            )
          })}
        </Swiper>
        {_.chunk(data.rows, 4).length > 1 && (
          <div>
            <div 
              className="googleSalesScoreChannelsPrev">
              <i 
                onClick={()=>(this.swiperChannels && this.swiperChannels.slidePrev())} 
                className="fas fa-caret-up"
              />
            </div>
            <div
              className="googleSalesScoreChannelsNext">
              <i
                onClick={()=>(this.swiperChannels && this.swiperChannels.slideNext())}  
                className="fas fa-caret-down"
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  score(data){
    const { active: index } = this.state
    const active = data.rows[index] || []

    return (
      <div className='googleSalesScoreData'>
        <div className="googleSalesScoreDataContainer">
          <div className="googleSalesScoreDataItem">
            <p className="googleSalesScoreDataValue">{numeral(active[7]).format('0,0')}</p>
            <p className="googleSalesScoreTitle">Sessions</p>
          </div>
          <div className="googleSalesScoreDataItem">
            <p className="googleSalesScoreDataValue">{numeral(active[5]).format('0,0[.]00')}%</p>
            <p className="googleSalesScoreTitle">% New Sessions</p>
          </div>
          <div className="googleSalesScoreDataItem">
            <p className="googleSalesScoreDataValue">{numeral(active[8]).format('0,0')}</p>
            <p className="googleSalesScoreTitle">Page / Sessions</p>
          </div>
          <div className="googleSalesScoreDataItem">
            <p className="googleSalesScoreDataValue">{this.secondsToTime(active[9])}</p>
            <p className="googleSalesScoreTitle">Session Duration</p>
          </div>
          <div className="googleSalesScoreDataItem">
            <p className="googleSalesScoreDataValue">{numeral(active[1]).format('0,0[.]00')}</p>
            <p className="googleSalesScoreTitle">Transactions</p>
          </div>
          <div className="googleSalesScoreDataItem">
            <p className="googleSalesScoreDataValue">${numeral(active[10]).format('0,0')}</p>
            <p className="googleSalesScoreTitle">Revenue</p>
          </div>
        </div>
      </div>
    )
  }

  lists(data){
    const { active: index } = this.state
    const active = data.rows[index] || ['']

    const channel = _.find(channels, {id: active[0].toLowerCase()})

    return (
      <Lists 
        range={this.props.range} 
        channel={channel || {}} 
      />
    )
  }


  render () {
    const { data } = this.props

    const Channels = this.channels(data)
    const Score = this.score(data)
    const Lists = this.lists(data)

    return(
      <div>
        {Channels}
        {Score}
        {Lists}
      </div>
    )
  }
}

export default Panel
