import React, { Component } from 'react';
import { 
  Dialog,
  Switch
} from "@blueprintjs/core"
import _ from 'lodash'
import axios from '../../../../../services/axios'
import {
  withRouter
} from 'react-router-dom'

import './styles.css'

class Brands extends Component {
  constructor(props){
    super(props)

    this.state = {
      brands: []
    }
  }

  componentWillMount(){
    axios.get('marcas/list')
    .then((brands)=>{
      this.setState({
        brands: brands.data.data.brands
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  render() {
    return (
      <div className="sidebar-accounts">
        <div className="sidebar-title">
          User Brands
        </div>
        <div className='brandsContainer' >
          {this.state.brands.map((brand)=>{
            return (
              <div
                onClick={()=>(window.location.href = `/dashboard/${brand.id}`)}
                className='accountBrand' 
                style={{backgroundImage:`url(${brand.avatar})`}} />
            )
          })}
        </div>
      </div>
    )
  }
}

export default withRouter(Brands)
