import React, { Component } from 'react'
import { core as ZingChart } from '../../Shared/ZingChart'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import md5 from 'js-md5'

import download from 'downloadjs'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import ImageTracer from 'imagetracerjs'
import zingchart from 'zingchart'
import _ from 'lodash'

import './styles.css'

class Chart extends Component {
	constructor(props){
		super(props)

		this.chart = {
      width: 0
    }

		let chartId = `chart-${Math.random()}-${new Date().getMilliseconds()}`
    this.chartId = md5(chartId)

    let contextId = `context-${Math.random()}-${new Date().getMilliseconds()}`
    this.contextId = md5(contextId)

    this.setMaxChart = this.setMaxChart.bind(this)
    this.download = this.download.bind(this)
	}

  download(event, {type}){
    const positionInfo = this.chart.getBoundingClientRect();
    /*const height = positionInfo.height;
    const width = positionInfo.width;*/

    html2canvas(this.chart, {
      allowTaint: false,
      useCORS: true,
      width:  positionInfo.width,
      backgroundColor: '#2A2A2A',
      scale: 1
    }).then((canvas)=>{
      let width = canvas.width
      let height = canvas.height
      let image = canvas.toDataURL()

      ImageTracer.imageToSVG(image, (svg)=>{
        const file = `data:image/svg+xml;utf8,${svg}`
        download(file, "dlDataUrlText.svg", 'image/svg+xml')
      })

      /*var doc = new jsPDF({
        orientation: width > height ? 'l' : 'p',
        unit: 'px',
        format: width > height ? [height, width] : [width, height]
      })

      var widthInternal = doc.internal.pageSize.width;    
      var heightInternal = doc.internal.pageSize.height;

      doc.addImage(image, 'PNG', 0, 0, widthInternal, heightInternal);
      doc.save('capture.pdf')*/

      /*download(image, "dlDataUrlText.png", 'image/png');*/

      /*let doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [(3/4) * width, (3/4) * height]
      })
      
      doc.addImage(image, 'PNG', 0, 0, width);
      doc.save('capture.pdf')*/
    })
  }

  setMaxChart(data){
    if(data.type == 'bar'){
      const { width } = this.chart
      const length = data.series[0].values.length

      const nodeLength = Math.round(width / length)
      const chartLength = Math.round(nodeLength / 10)
      data = _.merge(data, {
        scaleY:{
          item: {
            fontSize: '0px'
          }
        },
        scaleX: {
          itemsOverlap: true,
          maxItems: length + 10,
          item: {
            maxChars: chartLength
          }
        }
      })
    }

    zingchart.exec(this.chartId, 'setdata', {
      data : data
    })
  }

  componentWillReceiveProps(props){
    this.setMaxChart(props.data)
  }

  componentDidMount(){
    this.setMaxChart(this.props.data)

    setTimeout(()=>{
      !!this.container && (this.container.style.opacity = 1)
    }, 100)
  }

  render () {
    const events = this.props.events || {}

    return(
      <div
        ref={(container)=>(this.container = container)}
        style={{
          opacity: 0
        }}
      >
        {/* Future use */}
        {/*<ContextMenuTrigger id={this.contextId}>
          <ZingChart
            id={this.chartId}
            {...this.props} />
        </ContextMenuTrigger>

        <ContextMenu 
          id={this.contextId}
          className="contextMenu">
          <MenuItem 
            data={{type: 'pdf'}}
            onClick={this.download}>
            <div 
              className="contextMenuItem">
              Download PDF
            </div>
          </MenuItem>
          <MenuItem 
            data={{type: 'png'}}
            onClick={this.download}>
            <div 
              className="contextMenuItem">
              Download PNG
            </div>
          </MenuItem>
          <MenuItem 
            data={{type: 'svg'}}
            onClick={this.download}>
            <div 
              className="contextMenuItem">
              Download SVG
            </div>
          </MenuItem>
        </ContextMenu>*/}
        <ZingChart
          id={this.chartId}
          {...this.props}
          events={{
            ...events,
            load: (chart)=>{
              !!events.load && events.load(chart)
              this.chart = chart
            }
          }}
        />
      </div>
    )
  }
}

export default Chart