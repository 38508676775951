import React, { Component } from 'react'
import { Card, Elevation, Spinner } from "@blueprintjs/core"

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Truncate from '../../Shared/Truncate'

import placeholder from '../../../assets/img/placeholder.jpg'
import logoSmart from '../../../assets/img/logo.png'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import SocialIcons from '../../Shared/SocialIcons'

import './styles.css'

class BestPosts extends Component {
  constructor(props){
    super(props)
    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  formatMetrics(metric, format){
    format = numeral(metric).format(metric > 100000 ? '0.0a' : format)
    return format.toUpperCase()
  }

  getData(range, filter){
    const { competitor } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      return axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/bestPosts/${page}?since=${since}&until=${until}&provider=${filter}`)
    }).then((response)=>{
      return competitor == 'brand' ? response.data.data.bestposts_organic : response.data.data
    })
  }
    
  chart(data){
    const { competitor } = this.props

    return (
      <div className='postsContainer'>
        {data.slice(0, 5).map((post)=>{
          post = competitor == 'all' && post.is_brand ? {...post, ...post.brand_post} : post
          
          return (
            <div
              onClick={()=>(window.open(post.link, '_blank'))}
              className='post'>
              <div className="postIcon">
                <SocialIcons
                  type={(competitor == 'brand' || post.is_brand) ? (!!post.ins_media_type ? post.ins_media_type.toLowerCase() : post.post_type.toLowerCase()) : post.type.toUpperCase()} 
                  provider={post.provider}
                />
              </div>
              <div className='postPicture'
                style={{
                  backgroundImage: `url(${logoSmart})`
                }}
              >
                <div style={{
                  backgroundImage: `url(${post.picture})`
                }} />
              </div>
              <div className='postMessage'>
                <Truncate
                  text={post.caption || post.message}
                /> 
              </div>
              <p className='postDate'>
                {moment(post.post_published_date).format('MMM. DD YYYY')}
              </p>
            </div>
          )
        })}
        {_.range(0, 5-data.length, 1).map((post)=>{
          return (
            <div 
              className="postPlaceholder"
              style={{
                backgroundImage: `url(${placeholder})`
              }}
            />
          )
        })}
      </div>
    )
  } 

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }


  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialBestPosts">
        <Title description='Los mejores posteos orgánicos de la semana.' title='Best Posts' />
        <div
          className='socialBestPostsContainer'
          ref={(ref)=>(this.chartContainer = ref)}
        >
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default BestPosts
