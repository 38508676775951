import React, { Component } from 'react'

import './styles.css'

class ErrorChart extends Component {
  render () {
    return(
    	<div className="ErrorChart">
        <p>{this.props.error}</p>
        <p>Call to: --------</p>
      </div>
    )
  }
}

export default ErrorChart