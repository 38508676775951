import React, { Component } from "react";
import { Alert } from "@blueprintjs/core"

class WordItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      category: null,
      id: null
    }

    this.onConfirmDelete =  this.onConfirmDelete.bind(this)
    this.onDeleteWord = this.onDeleteWord.bind(this)
  }

  onConfirmDelete(){
    this.props.delete(this.state.category, this.state.id)

    this.setState({
      isOpen: false,
      category: null,
      id: null
    })
  }

  onDeleteWord(category, id) {
    this.setState({
      isOpen: true,
      category: category,
      id: id
    })
  }

  render() {
    return (
      <div className="topic-item__list">
        {this.props.words.map((word)=>{
          return (
            <div key={word.id} className="topic-item__list--item">
              <div className="topic-item__list--name">
                {word.palabra}
              </div>
              <div className="topic-item__list--total">
                {word.count}
              </div>
              <div className="topic-item__list--delete">
                <i className="fas fa-times" onClick={()=>(this.onDeleteWord(word.palabra_categoria_id, word.id))}></i>
              </div>
            </div>
          )
        })}
        <Alert
          confirmButtonText="Okay"
          cancelButtonText="Cancel"
          onCancel={()=>(this.setState({isOpen: false}))}
          isOpen={this.state.isOpen}
          onConfirm={this.onConfirmDelete}
          className="bp3-dark input-modal">
          <p>
           lorem 
          </p>
        </Alert>
      </div>
    );
  }
};

export default WordItems