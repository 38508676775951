import React, { Component } from 'react'
import { 
	Link, 
	Element , 
	Events, 
	animateScroll as scroll, 
	scrollSpy, 
	scroller 
} from 'react-scroll'
import MainContainer from '../MainContainer/MainContainer'

import './styles.css'

class WebIntelligence extends Component {
  render () {
    return(
      <div id="web">
        <div className="webSidebar" data-html2canvas-ignore>
      		<Link 
    				activeClass="active" 
    				to="audience" 
    				spy={true} 
    				offset={-200}
    				smooth={true}>
						<i className="far fa-user"></i>
            <p className="webSidebarLabel">AUDIENCE</p>
      		</Link>
      		<Link 
    				activeClass="active" 
    				to="conversion" 
    				spy={true} 
    				offset={-100}
    				smooth={true}>
						<i className="fas fa-assistive-listening-systems"></i>
            <p className="webSidebarLabel">CONVERSION</p>
      		</Link>
      		<Link 
    				activeClass="active" 
    				to="goals" 
    				spy={true} 
    				offset={-100}
    				smooth={true}>
						<i className="far fa-star"></i>
            <p className="webSidebarLabel">GOALS</p>
      		</Link>
      	</div>
        <MainContainer {...this.props} />
      </div>
    )
  }
}

export default WebIntelligence
