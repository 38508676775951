import Moment from 'moment'
import _ from 'lodash'
import {
  FETCH_PROMOTIONS
} from './types'
import axios from '../services/axios'
import storage from '../services/storage'

export function get() {
  return (dispatch)=>{
    storage.get('page').
    then((page)=>{
      return axios.get(`/brands/${page}/promotions`)
    }).then((response)=> {
      dispatch({
        type: FETCH_PROMOTIONS,
        payload: response.data.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }
}

export function add(category, exclude) {
  return function(dispatch) {
    storage.get('page').
    then((page)=>{
      return axios.post(`/brands/${page}/promotions`, {
        name: category,
        exclude: exclude
      }).then((response)=> {
        return axios.get(`/brands/${page}/promotions`)
      })
    }).then((response)=> {
      dispatch({
        type: FETCH_PROMOTIONS,
        payload: response.data.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }
}

export function edit(id, category, exclude) {
  return function(dispatch) {
    storage.get('page').
    then((page)=>{
      return axios.put(`/brands/${page}/promotions/${id}`, {
        name: category,
        exclude: exclude
      }).then((response)=> {
        return axios.get(`/brands/${page}/promotions`)
      })
    }).then((response)=> {
      dispatch({
        type: FETCH_PROMOTIONS,
        payload: response.data.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }
}

export function remove(id) {
  return function(dispatch) {
    storage.get('page').
    then((page)=>{
      return axios.delete(`/brands/${page}/promotions/${id}`)
      .then((response)=> {
        return axios.get(`/brands/${page}/promotions`)
      })
    }).then((response)=> {
      dispatch({
        type: FETCH_PROMOTIONS,
        payload: response.data.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }
}