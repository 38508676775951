import React, { Component } from 'react'
import Rodal from 'rodal'
import './styles.css'

class Modal extends Component {
  render () {
    const customMaskStyles = this.props.customMaskStyles || {}
    return(
    	<Rodal 
            showCloseButton={false}
    		{...this.props} 
            closeMaskOnClick={true}
    		className='customRodal' 
    		customStyles={{
    			width: 'auto',
    			height: 'auto',
    			display: 'flex',
    			alignItems: 'center',
    			justifyContent: 'center',
    			bottom: 'auto',
                backgroundColor: 'transparent',
                pointerEvents: 'none'
    		}}
            customMaskStyles={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                opacity: 1,
                zIndex: 20,
                backgroundColor: 'rgba(16, 22, 26, 0.7)',
                overflow: 'auto',
                userSelect: 'none',
                ...customMaskStyles
            }}
    		visible={this.props.isOpen}
    	>
    		<div className={`bp3-dialog ${this.props.className}`}>
    			{this.props.children}
    		</div>
    	</Rodal>
    )
  }
}

export default Modal