import React, {Component} from 'react'
import _ from 'lodash'
import axios from 'axios'
import { API_URL } from '../../../config.js'
import { Button, Card, Classes, Elevation, Dialog, Tooltip, Position } from '@blueprintjs/core'
import TopicDataItem from './TopicDataItem/TopicDataItem'
import { Bar } from 'react-chartjs-2'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class TopicData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listado_topicData: [],
      isOpen: false,
      hide: false,
      graphData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#b3ff26', '#2aa9e9', '#ff9615']
          }
        ]
      }
    }

    this.token =  localStorage.getItem( "api_access_token" )
    this.getTopic = this.getTopic.bind(this)
    this.addWord =  this.addWord.bind(this)
    this.deleteWord =  this.deleteWord.bind(this)
    this.handleAddTopicDataItem = this.handleAddTopicDataItem.bind(this)

  }

  handleAddTopicDataItem(event) {
    const { value } = this.calegoryWord

    axios.post(`${API_URL}${this.props.id}/palabras/categorias`,{
      name: value,
      tipo: 'ads'
    }, {
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    }).then((response) => {
      this.getTopic()
      this.setState({
        isOpen: false
      })
    }).catch((error) => {
      this.setState({
        error: true
      })
    })

    event.preventDefault()
  }

  addWord(category, word){
    axios.post(`${API_URL}${this.props.id}/palabras/categoria/${category}/palabras`,{
      word: word
    }, {
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    }).then((response) => {
      this.getTopic()
    }).catch((error) => {
      console.log(error)
    })
  }

  deleteWord(category, id){
    axios.delete(`${API_URL}${this.props.id}/palabras/categoria/${category}/palabras/${id}`, {
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    }).then((response) => {
      this.getTopic()
    }).catch((error) => {
      console.log(error)
    })
  }

  getTopic(){
    axios.get(`${API_URL}ads/palabras/${this.props.id}`, {
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    }).then((response) => {
      const { data } = response
      const { graphData } = this.state
      const { labels, datasets } = graphData

      let newGraphData = {
        labels: _.map(data.data, 'nombre'),
        datasets: [
          {
            data: _.map(data.data, 'count'),
            backgroundColor: _.concat(datasets[0].backgroundColor, '#ffffff')
          }
        ]
      }

      this.setState({
        listado_topicData: data.data, 
        graphData: newGraphData
      })

    }).catch((error) => {
      console.warn(error)
    })
  }

  chart() {
    return <Bar data={this.state.graphData} width={530} options={{
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              display: true,
              gridLines: {
                display: true,
                color: "#636363",
                drawTicks: false
              },
              ticks: {
                fontColor: "#fff",
                padding: 14
              }
            }
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: "#636363",
                drawTicks: false
              },
              ticks: {
                fontColor: "#fff",
                padding: 10,
                beginAtZero:true
              }
            }
          ]
        }
      }}/>
  }

  componentWillMount(){
    this.getTopic()
  }

  render() {
    const {listado_topicData} = this.state
    return (
      <div>
        <Card interactive={true} elevation={Elevation.TWO} className="no-pad-t-b">
          <Title title='Topic Data' />
          {this.chart()}
          <div className="grid topicData-shell">
            {listado_topicData.map((topicData)=>{
              return (
                <div className="col-3" key={topicData.id}>
                  <TopicDataItem 
                    {...topicData} 
                    addWord={this.addWord}
                    deleteWord={this.deleteWord}
                  />
                </div>
              )
            })}
            <div 
              className="col-3" 
              onClick={()=>(this.setState({isOpen: true}))}>
              <div className="topicData-item topicData-item__last grid-center-middle">
                <div className="col-12">
                  <i className="fas fa-plus topicData-item__addIcon"></i>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Dialog 
          onClose={()=>(this.setState({isOpen: false}))} 
          isOpen={this.state.isOpen} 
          className="bp3-dark input-modal">
          <div className={Classes.DIALOG_BODY}>

            <form onSubmit={this.handleAddTopicDataItem}>
              <div className="bp3-input-group">
                <label className="bp3-label">Input Category Name</label>
                <div className={this.state.error === true
                    ? 'dialog-add__error'
                    : 'dialog-add__error hidden'}>Enter category name</div>
                <div className="grid-center-middle">
                  <div className="col-10">
                    <input className="bp3-input topic-item__input" ref={(ref) => this.calegoryWord = ref} placeholder="Category"></input>
                  </div>
                  <div className="col-2">
                    <Button minimal="true" large="true" className="button button-primary" onClick={this.handleAddTopicDataItem}>Add</Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Dialog>
      </div>
    )}
}

export default TopicData