import React, { Component } from 'react'
import { Button, H1 } from "@blueprintjs/core"
import { AutoSizer, List } from 'react-virtualized'
import moment from 'moment'
import _ from 'lodash'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import { RangeConfig } from '../../../config'
import storage from '../../../services/storage'
import axios from '../../../services/axios'
import Alert from '../../Shared/Alert'
import DropDown from '../../Shared/DropDown'
import LoadingMind from '../../Shared/LoadingCharts'
import Select, { components }  from 'react-select'
import Post from '../Post/Post'
import { connect } from 'react-redux'

import 'bootstrap-daterangepicker/daterangepicker.css'
import './styles.css'

class MainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      range: [
        moment(this.props.range[0]).startOf('day').toDate(),
        moment(this.props.range[1]).startOf('day').toDate()
      ],
      find: '',
      isOpenBrands: false,
      isOpenKeywords: false,
      order: this.props.order,
      filter: this.props.filter,
      allKeywords: false,
      brand: {
        id: -1,
        name: 'All'
      },
      loading: true,
      postsTemp: [],
      posts: [],
      resize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    } 
    
    this.range = [
      moment(this.props.range[0]).startOf('day').toDate(),
      moment(this.props.range[1]).startOf('day').toDate()
    ]

    this.onFind = _.debounce(this.onFind.bind(this), 300)
    this.onResize = this.onResize.bind(this)
    this.onBrand = this.onBrand.bind(this)
    this.onFilter = this.onFilter.bind(this)
    this.onSearchKeywords = this.onSearchKeywords.bind(this)
    this.onOrder = this.onOrder.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.onGetData = this.onGetData.bind(this)
    this.onChangeRange = this.onChangeRange.bind(this)
    this.onChangeCategory = this.onChangeCategory.bind(this)
  }
  
  componentWillMount(){
    window.addEventListener('resize', _.debounce(this.onResize, 500))
  }

  componentDidMount(){
    this.onSearch()
  }

  componentWillReceiveProps(props){
    if(this.props.filter != props.filter || this.props.order != props.order || !_.isEqual(this.props.keywords, props.keywords)){
      
      const { postsTemp, find, brand } = this.state
      const { order, filter, competitor } = props

      let posts = this.onFilter(filter, postsTemp, find, props.keywords)
      posts = this.onBrand(brand, posts, filter)
      posts = this.onOrder(order, posts)

      this.setState({
        posts: posts
      })
    }
  }

  componentDidUnmount(){
    window.addEventListener('resize')
  }

  onSearchKeywords(posts, keywords = null){
    const { allKeywords } = this.state

    !keywords && (keywords = this.props.keywords)
    keywords = _.compact(_.map(keywords, 'word'))

    if(keywords.length > 0 && allKeywords){
      posts = _.filter(posts, (post)=>{
        const text = (post.caption || post.message || '').normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()
        return keywords.some(text.includes.bind(text))
      })
    }

    return posts
  }

  onFind(){
    const { postsTemp, brand } = this.state
    const { value } = this.find
    const { order, filter, competitor } = this.props

    let posts = this.onFilter(filter, postsTemp, value)
    posts = this.onBrand(brand, posts, filter)
    posts = this.onOrder(order, posts)

    this.setState({
      posts: posts,
      find: value,
      loading: false
    })
  }

  onResize(event){
    this.setState({
      resize: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    })
  }
  
  onBrand({id}, posts, filter){
    if(id != -1){
      posts = _.filter(posts, ['brand.id', id])
    }

    return posts
  }

  onFilter(filter, posts, content = '', keywords = null){
    posts = _.filter(posts, (post)=>{
      const text = (post.caption || post.message || '').normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()
      content = content.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()

      return (filter == 'all' || post.provider == filter) && (content == '' ? true : text.includes(content))
    })

    !keywords && (keywords = this.props.keywords)

    posts = this.onSearchKeywords(posts, keywords)

    return posts
  }

  onOrder(order, posts){
    switch (order) {
      case 'date':
        posts = _.orderBy(posts, ['post_published_date'], ['desc'])
        break
      case 'eng_score':
        posts = _.orderBy(posts, ['es'], ['desc'])
        break
      case 'interactions':
        posts = _.orderBy(posts, (post)=>{
          let interactions = 0

          if(post.is_brand){
            interactions += post.wow + post.likes + post.anger + post.haha + post.sorry + post.love + post.anger
            interactions += post.shares
            interactions += post.comments
          }else{
            interactions += post.metrics.shares
            interactions += post.metrics.reactions
            interactions += post.metrics.comments
          }

          return interactions
        }, ['desc'])
        break
      case 'es':
        posts = _.orderBy(posts, ['es'], ['desc'])
        break
      case 'eng_score_paid':
        posts = _.orderBy(posts, ['es_calculated'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return post.post_impressions_paid > 0
        })
        break
      case 'reach_organic':
        posts = _.orderBy(posts, ['post_impressions_organic'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return  !post.post_impressions_paid || post.post_impressions_paid == 0
        })
        break
      case 'reach_paid':
        posts = _.orderBy(posts, ['post_impressions_paid'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return post.post_impressions_paid > 0
        })
        break
      case 'category':
        posts = _.orderBy(posts, ['es_calculated'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return post.categoria_id == 1
        })
        break
    }

    return posts
  }

  onSearch(){
    this.onGetData()
  }

  onGetData(){
    const since = moment(this.range[0]).format('YYYY-MM-DD')
    const until = moment(this.range[1]).format('YYYY-MM-DD')
        
    axios.get(`sales/competitorsPosts/${since}/${until}`)
    .then((response)=>{
      const { order, filter, competitor } = this.props
      const { find, brand } = this.state

      const postsTemp = _.map(response.data.data, (post)=>{
        post.is_brand = false

        return post
      })

      let posts = this.onFilter(filter, postsTemp, find)
      posts = this.onBrand(brand, posts, filter)
      posts = this.onOrder(order, posts)

      this.setState({
        postsTemp: postsTemp,
        loading: false,
        posts: posts
      })
    }).catch((error)=>{
      console.warn(error)
    })

  }

  onChangeRange({startDate, endDate}){
    const range = [startDate.toDate(), endDate.toDate()]

    this.dateStart.innerHTML = moment(range[0]).format('YYYY-MM-DD')
    this.dateEnd.innerHTML = moment(range[1]).format('YYYY-MM-DD')

    storage.set('range', range)
    
    this.range = range
  }

  onChangeCategory(id, category){
    const { order, filter } = this.props
    const { posts, postsTemp } = this.state
    let newPosts = _.map(posts, (post)=>{
      if(post.id == id){
        post.categoria_id = category
      }
      return {...post}
    })

    newPosts = this.onFilter(filter, newPosts)
    newPosts = this.onOrder(order, newPosts)

    const newPostsTemp = _.map(postsTemp, (post)=>{
      if(post.id == id){
        post.categoria_id = category
      }
      return {...post}
    })

    this.setState({
      posts: newPosts,
      postsTemp : newPostsTemp
    })
  }

  onChangeBrand(brand){
    this.setState({
      brand: brand
    }, this.onFind)
  }

  render () {
    const { userPermissions } = this.props
    const { isOpenBrands, isOpenKeywords, brand, find, allKeywords, loading } = this.state
    const count = Math.floor((this.state.resize.width - 86)/368)
    const rows = _.chunk(this.state.posts, count)

    let keywordsSearch =  allKeywords ? _.map(this.props.keywords, 'word') : []
    !!find && find.replace(/\s/g, "").trim() != '' && (keywordsSearch = _.concat(keywordsSearch, find))

    return(
      <div className="dashboardContainer">
        <Alert
          confirm 
          body='Your plan doesn’t have the required credentials to access this section. Please call you brand manager for further assistance.' 
          ref={(alert)=>(this.error = alert)} 
        />
        <div className="top-container">
          <div className="brands-added">
            <div className="grid">
              <div className="col-4_sm-12">
                <H1 className="brands-added__title" data-html2canvas-ignore>SALES BENCHMARK</H1>
              </div>
              <div className="col-8_sm-12">
                <div className="searchContainer" data-html2canvas-ignore>
                  <div 
                    onClick={()=>(this.setState({allKeywords: !allKeywords, loading: true}, this.onFind))}
                    className="saleSearchKeywords" 
                    data-active={allKeywords}>
                    <p>All Keywords</p>
                  </div>
                  <div className="saleBrandsMenuContainer">
                    <DropDown
                      bsStyle='default'
                      onClose={()=>(this.setState({isOpenBrands: false}))}
                      onClick={()=>(this.setState({isOpenBrands: !isOpenBrands}))}
                      title={brand.name}
                    >
                      <Select 
                        menuIsOpen={isOpenBrands}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        isClearable={false}
                        backspaceRemovesValue={false}
                        tabSelectsValue={false}
                        options={_.concat(this.props.brands, {
                          id: -1,
                          name: 'All Markets'
                        })} 
                        components={{ 
                          DropdownIndicator: null, 
                          IndicatorSeparator: null,
                          Control: (props)=>{
                            return (
                              <div className='saleBrandsMenuControl'>
                                <components.Control {...props} />
                              </div>
                            )
                          },
                          MenuList : (props)=>{
                            return (
                              <div className="saleBrandsMenu">
                                {props.children}
                              </div>
                            )
                          },
                          Option: ({ innerProps, isDisabled, data}) =>{
                            return (
                              <div 
                                className='saleBrandOption'
                                onClick={()=>(this.onChangeBrand({
                                  id: data.id,
                                  name: data.name
                                }))} 
                                data-active={brand.id == data.id}
                              >
                                <p>{data.name}</p>
                              </div>
                            )
                          }
                        }}
                      />
                    </DropDown>
                  </div>
                  <div className="searchSmart bp3-dark">
                    <div className="searchSmartKeywods">
                      <input 
                        type="text"
                        onPaste={this.onFind}
                        onKeyDown={this.onFind}
                        ref={(ref)=>(this.find = ref)}
                      />
                    </div>
                    <span className="searchFindIcon salesSearchFindIcon">
                      <i className="fas fa-search"></i>
                    </span>
                  </div>
                  <div className="dateSmart bp3-dark">
                    <span className="searchCalendarIcon"><i className="far fa-calendar-alt"></i></span>
                    <DateRangePicker
                      {...RangeConfig} 
                      startDate={this.state.range[0]} 
                      endDate={this.state.range[1]}
                      onApply={(event, picker)=>(this.onChangeRange(picker))}>
                      <div className="daterangepickerDates">
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateStart = ref)}>
                          {moment(this.state.range[0]).format('YYYY-MM-DD')}
                        </span>
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateEnd = ref)}>
                          {moment(this.state.range[1]).format('YYYY-MM-DD')}
                        </span>
                      </div>
                    </DateRangePicker>
                    <Button onClick={this.onSearch} minimal="true" className="button btn-outline" >Search</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="social-intelligence__container" id="MainContainer">
          {loading ? (
            <div className="competitor-dashboard">
              <LoadingMind />
            </div>
          ) : (
            <div className="dashboardContainerPosts">
              {!loading && rows.length == 0 ? (
                <p className="dashboardNotData">No Data</p>
              ) : (
                <List
                  width={this.state.resize.width - 86}
                  height={this.state.resize.height - 160}
                  rowHeight={430}
                  rowCount={rows.length}
                  rowRenderer={({key, index, isScrolling, isVisible, style})=>{
                    const posts = rows[index]

                    return (
                      <div
                        className='postsContainerRow'
                        key={key} 
                        style={style}>
                        {posts.map((post)=>{
                          return (
                            <Post
                              onErrorPermissions={()=>(this.error.show())}
                              lock={!userPermissions['Categorize']} 
                              onChangeCategory={this.onChangeCategory}
                              key={post.id}
                              keywords={keywordsSearch}
                              {...post}
                            />
                          )
                        })}
                        {_.range(count - posts.length).map(()=>{
                          return (
                            <div className="postsContainerRowDummy" />
                          )
                        })}
                      </div>
                  )}}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    keywords: state.keywords.keywords
  }
}

export default connect(mapStateToProps)(MainContainer)
