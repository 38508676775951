import {  combineReducers } from 'redux'
import categories from './categories'
import promotions from './promotions'
import keywords from './keywords'

const rootReducer = combineReducers({
  categories,
  promotions,
  keywords
})

export default rootReducer;
