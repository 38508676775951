import React, { Component } from 'react'
import { Route } from 'react-router-dom' // BrowserRouter, Route, Redirect
import Container from '../../containers/'
import Home from '../Home'
import Login from '../Login/Login'
import ContentAnalytics from '../ContentAnalytics/Dashboard'
import SocialIntelligence from '../SocialIntelligence/Dashboard/Dashboard'
import AdsIntelligence from '../AdsIntelligence/Dashboard/Dashboard'
import WebIntelligence from '../WebIntelligence/Dashboard/Dashboard'
import IndustryIntelligence from '../IndustryIntelligence/Dashboard/'
import RetailIntelligence from '../RetailIntelligence/Dashboard/'
import Calendar from '../Calendar/Dashboard'
import Overview from '../Overview/Dashboard'
import SalesBenchmark from '../SalesBenchmark'

import storage from '../../services/storage'

import './styles.css'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Route path="/" exact component={Login} />
        <Route path="/home" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Mission Control'>
              <Home />
            </Container>
        )}} />
        <Route path="/sales" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Sales Benchmark'>
              <SalesBenchmark />
            </Container>
        )}} />
        <Route path="/overview/:id" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Overview'>
              <Overview />
            </Container>
        )}} />
        <Route path="/dashboard/:id" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Dashboard'>
              <ContentAnalytics />
            </Container>
        )}} />
        <Route path="/social/:id" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Content Optimization'>
              <SocialIntelligence />
            </Container>
          )}} />
        <Route path="/ads/:id" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Ads Intel'>
              <AdsIntelligence />
            </Container>
          )}} />
        <Route path="/web/:id" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Web Intel'>
              <WebIntelligence />
            </Container>
          )}} />
        <Route path="/calendar/:id" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Calendar'>
              <Calendar />
            </Container>
          )}} />
        <Route path="/trending/:id" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Trending'>
              <IndustryIntelligence />
            </Container>
          )}} />
        <Route path="/retail/:id" render={(props)=>{
          return (
            <Container {...props} title='S|M|A|R|T - Retail Intel'>
              <RetailIntelligence />
            </Container>
          )}} />
      </div>
    )
  }
}

export default App
