import React,{ Component } from 'react'
import { 
  Tag, 
  Dialog, 
  Button, 
  Card, 
  Classes, 
  FormGroup, 
  InputGroup, 
  Icon, 
  Elevation, 
  Spinner, 
  FileInput 
} from "@blueprintjs/core"
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import IndustrySettings from './components/IndustrySettings'
import CampaingsSettings from './components/CampaingsSettings'
import CompetitorsSettings from './components/CompetitorsSettings'
import NetWorks from './components/NetWorks'
import Optimal from './components/Optimal'
import Brands from './components/Brands'
import Categories from './components/Categories'
import Promotions from './components/Promotions'
import Keywords from './components/Keywords'
import InstagramForm from './components/InstagramForm'
import Alert from '../../Shared/Alert'
import transition from 'transitionjs'

import './styles.css'

class SidebarContent extends Component {
  constructor(props){
    super(props)

    this.state = {
      networks: [],
      optimal: [],
      industry_settings: {
        names: {
          brand: '',
          competitors: []
        },
        images: {
          brand: '',
          competitors: []
        },
        facebook: [],
        instagram: [],
        twitter: [],
        urls: {
          brand: '',
          competitors: []
        }
      },
      instagram_email: ''
    }

    this.onSaveCampaings = this.onSaveCampaings.bind(this)
    this.logout = this.logout.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  onBackDropClick(){
    const sidebar = document.getElementById('sidebar')
    const backdrop = document.getElementById('sidebarBackDrop')
    const body = document.querySelector('body')

    transition.begin(backdrop, {
      property: "background-color",
      to: "rgba(0, 0, 0, 0)",
      duration: ".5s"
    })

    transition.begin(sidebar, {
      property: "right",
      to: "-300px",
      duration: ".5s",
      onTransitionEnd : ()=>{
        transition.begin(body, {
          property: "overflow",
          to: "auto",
          duration: ".00001s"
        })
        transition.begin(sidebar, {
          property: "z-index",
          to: "-1000",
          duration: ".00001s"
        })
      }
    })
  }
  
  logout(){
    localStorage.removeItem("api_access_token")
    storage.clear()
    window.location.href = '/'
  }

  componentWillMount() {
    const { pathname } = this.props.location

    pathname != '/sales' && storage.get('page')
    .then((page)=>{
      return axios.get(`brandSettings/${page}`)
    }).then((response)=>{
      this.setState({
        ...response.data.data
      })
    }).catch((error)=>{
  
    })
  }

  onSaveCampaings(campaings){
    storage.get('page')
    .then((page)=>{
      axios.put(`user/CampaingsAds`, {
        campaing_ads: campaings,
        brand_id: page
      })
    }).catch((error)=>{
  
    })
  }

  onSave(data){
    storage.get('page')
    .then((page)=>{
      return axios.post(`brandSettings/${page}`, {...data})
    }).then((response)=>{
      this.setState({
        ...response.data.data
      })
    }).catch((error)=>{
  
    })
  }

  print(){
    const main = document.querySelector('[data-html2canvas-print] #MainContainer')
    const positionInfo = main.getBoundingClientRect()
    html2canvas(main, {
      allowTaint: false,
      useCORS: true,
      width:  positionInfo.width,
      height: positionInfo.height,
      windowWidth: positionInfo.width,
      windowHeight: positionInfo.height,
      scrollY: -window.pageYOffset,
      onclone: (clone)=>{
        const container = clone.querySelector('#MainContainer')
        container.style.paddingTop = '0px'
        container.style.marginLeft = '0px'
      },
      backgroundColor: '#2A2A2A',
      scale: 1
    }).then((canvas)=>{
      let width = canvas.width
      let height = canvas.height
      let image = canvas.toDataURL()

      var doc = new jsPDF({
        orientation: width > height ? 'l' : 'p',
        unit: 'px',
        format: width > height ? [height, width] : [width, height]
      })

      var widthInternal = doc.internal.pageSize.width    
      var heightInternal = doc.internal.pageSize.height

      doc.addImage(image, 'PNG', 0, 0, widthInternal, heightInternal)
      doc.save('capture.pdf')
    })
  }

  render (){
    const { 
      tags, 
      networks, 
      optimal,
      industry_settings,
      instagram_email
    } = this.state
    const { userPermissions,  brandPermissions, providerPermissions } = this.props
    const { pathname } = this.props.location

    return (
      <div className="sidebarShell" id="sidebar" data-html2canvas-ignore>
        <div className="sidebarShellContainer">
          <Alert
            confirm 
            body='Your plan doesn’t have the required credentials to access this section. Please call you brand manager for further assistance.' 
            ref={(alert)=>(this.error = alert)} 
          />
          <CampaingsSettings
            network={!!_.find(networks, {provider: 'ads'})}
            onSave={this.onSaveCampaings}
            ref={(campaingsSettings)=>(this.campaingsSettings = campaingsSettings)}
          />
          <CompetitorsSettings
            ref={(competitorsSettings)=>(this.competitorsSettings = competitorsSettings)}
          />
          <IndustrySettings
            provider={_.find(networks, {provider: 'facebook'}) || {}}
            industry={industry_settings}
            onSave={this.onSave}
            ref={(industry)=>(industry && (this.industry = industry))} 
          /> 
          <div className="sidebar">
            <Brands />
            
            {pathname != '/sales' && (
              <NetWorks 
                networks={networks}
              />
            )}

            {pathname != '/sales' && (
              <div className="sidebar-navegation">
                <div className="sidebar-title sidebar-title__left">
                  Navegation
                </div>
                <ul className="sidebar-navegation__list bp3-list-unstyled">
                  {window.location.hostname != 'expansion.smartintel.io' && (
                    <li className="sidebar-navegation__list--item sidebar-navegation__list--item__web">
                      <i className="fas fa-eye" />
                      <a href={`/overview/${this.props.match.params.id}`}>
                        Overview Intelligence
                      </a>
                    </li>
                  )}
                  <li className="sidebar-navegation__list--item sidebar-navegation__list--item__dashboard">
                    <i className="fas fa-tachometer-alt" />
                    <a href={`/dashboard/${this.props.match.params.id}`}>
                      Dashboard
                    </a>
                  </li>
                  <li className="sidebar-navegation__list--item sidebar-navegation__list--item__social">
                    <i className="fas fa-chart-area" />
                    <a href={`/social/${this.props.match.params.id}`}>
                      Content Optimization
                    </a>
                  </li>
                  <li className="sidebar-navegation__list--item sidebar-navegation__list--item__web">
                    <i className="far fa-calendar-alt" />
                    <a href={`/calendar/${this.props.match.params.id}`}>
                      Calendar
                    </a>
                  </li>
                  {_.find(networks, {provider: 'ads'}) != undefined && (
                    <li className="sidebar-navegation__list--item sidebar-navegation__list--item__ads">
                      <i className="fas fa-dollar-sign" />
                      <a href={`/ads/${this.props.match.params.id}`}>
                        ADs Intelligence
                      </a>
                    </li>
                  )}
                  {_.find(networks, {provider: 'google'}) != undefined && (
                    <li className="sidebar-navegation__list--item sidebar-navegation__list--item__web">
                      <i className="fas fa-chart-bar" />
                      <a href={`/web/${this.props.match.params.id}`}>
                        Web Intelligence
                      </a>
                    </li>
                  )}
                  <li className="sidebar-navegation__list--item sidebar-navegation__list--item__web">
                    <i className="fa fa-industry" />
                    <a href={`/trending/${this.props.match.params.id}`}>
                      Industry Intelligence
                    </a>
                  </li>
                  {window.location.hostname == 'mobica.smartintel.io' && (
                    <li className="sidebar-navegation__list--item sidebar-navegation__list--item__web">
                      <i className="fa fa-industry" />
                      <a href={`/retail/${this.props.match.params.id}`}>
                        Retail Intelligence
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
            
            {pathname != '/sales' && (
              <div>
                <Categories
                  onErrorPermissions={()=>(this.error.show())} 
                  lock={!userPermissions['Categorize']} 
                />
                
                <Promotions
                  onErrorPermissions={()=>(this.error.show())} 
                  lock={!userPermissions['Categorize']} 
                />

                <Optimal
                  onErrorPermissions={()=>(this.error.show())}
                  lock={!userPermissions['Optimal Post']} 
                  optimal={optimal}
                  onSave={this.onSave}
                />

                <div className='sidebarInstagram'>
                  <div className="sidebar-title sidebar-title__left">
                    Instagram Schedule E-mail
                  </div>
                  <InstagramForm
                    onErrorPermissions={()=>(this.error.show())}
                    lock={!userPermissions['Instagram email']}
                    placeholder='mail@mail.com'
                    defaultValue={instagram_email}
                    onSave={this.onSave}
                  />
                </div>

                <div className="sidebar-industry">
                  <div className="sidebar-title sidebar-title__left">
                    Industry Settings
                  </div>
                  <div className="text-center">
                    <Button 
                      minimal="true" 
                      className={`button button-primary button__edit ${!userPermissions['Industy Settings'] && 'bp3-disabled'}`}
                      onClick={()=>(!userPermissions['Industy Settings'] ? this.error.show() : this.industry.show())}>EDIT</Button>
                  </div>
                </div>


                <div className="sidebar-ads">
                  <div className="sidebar-title sidebar-title__left">
                    ADs Settings
                  </div>
                  <div className="text-center">
                    <Button
                      minimal="true"
                      onClick={() => (!userPermissions['Ads Intelligence'] || providerPermissions.includes('ads') ? this.error.show() : this.campaingsSettings.show())}
                      className={`button button-primary button__edit ${(!userPermissions['Ads Intelligence'] || providerPermissions.includes('ads')) && 'bp3-disabled'}`}>
                        CAMPAIGNS
                    </Button>
                  </div>
                </div>

                {(pathname != '/home' && pathname != '/' && pathname.search('dashboard') == -1) && (
                  <div className="sidebar-capture">
                    <div className="sidebar-title sidebar-title__left">
                      Capture
                    </div>
                    <div className="text-center">
                      <Button
                        minimal="true"
                        onClick={()=>(this.print())}
                        className="button button-primary button__edit">
                          PDF
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {pathname == '/sales' && (
              <div>
                <Keywords />
                <div className="sidebar-competitors">
                  <div className="sidebar-title sidebar-title__left">
                    Select Competitors
                  </div>
                  <div className="text-center">
                    <Button
                      minimal="true"
                      onClick={() => (this.competitorsSettings.show())}
                      className={`button button-primary button__edit`}>
                      Competitors
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="sidebar-controls">
            <div className="sidebar-controls__btn">
              <a href={`/home`}>Mision Control</a>
            </div>
            <div className="sidebar-controls__btn">
              <a onClick={this.logout}>Logout</a>
            </div>
          </div>
        </div>
        <div id="sidebarBackDrop" onClick={()=>(this.onBackDropClick())} />
      </div>
    )
  }
}

export default withRouter(SidebarContent)