import {
  FETCH_KEYWORDS
} from './types'
import axios from '../services/axios'

export function get() {
  return (dispatch)=>{
    axios.get('/sales/words')
    .then((response)=> {
      dispatch({
        type: FETCH_KEYWORDS,
        payload: response.data.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }
}

export function add(word) {
  return function(dispatch) {
    axios.post('/sales/words', {
      word: word
    }).then((response)=> {
      dispatch({
        type: FETCH_KEYWORDS,
        payload: response.data.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }
}

export function edit(id, word) {
  return function(dispatch) {
    axios.put(`/sales/words/${id}`, {
      word: word,
    }).then((response)=> {
      dispatch({
        type: FETCH_KEYWORDS,
        payload: response.data.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }
}

export function remove(id) {
  return function(dispatch) {
    axios.delete(`/sales/words/${id}`)
    .then((response)=> {
      dispatch({
        type: FETCH_KEYWORDS,
        payload: response.data.data
      })
    }).catch((error) => {
      console.log(error)
    })
  }
}