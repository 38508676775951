import React, { Component } from 'react'
import logoHeader from '../../../assets/img/logoTOP.png'
import { Alignment, Navbar } from "@blueprintjs/core"
import { withRouter } from 'react-router-dom'
import storage from '../../../services/storage'
import axios from '../../../services/axios'
import _ from 'lodash'
import transition from 'transitionjs'

import './styles.css'

class Header extends Component {
    constructor(props){
      super(props)
      this.state = {
        name: '',
        id: '',
        page: {}
      }

      this.logout = this.logout.bind(this)
    }

    componentWillMount(){
      storage.get('user')
      .then((user)=>{
        this.setState({
          name: user.name,
          id: user.facebook_id
        })
      }).catch((error)=>{
        console.log(error)
      })

      storage.get('page')
      .then((page)=>{
        if(page){
          return axios.get(`brandSettings/${page}`)
        }
      }).then((response)=>{
        let page = response.data.data.networks
        page = _.find(page, {provider: 'facebook'})
        this.setState({
          page: page
        })
      }).catch((error)=>{
    
      })

    }

    onShowSidebar(){
      const sidebar = document.getElementById('sidebar')
      const backdrop = document.getElementById('sidebarBackDrop')
      const body = document.querySelector('body')

      try {
        transition.begin(sidebar, {
          property: "z-index",
          to: "20",
          duration: ".00001s",
          onTransitionEnd : ()=>{
            transition.begin(body, {
              property: "overflow",
              to: "hidden",
              duration: ".00001s"
            })
            transition.begin(sidebar, {
              property: "right",
              to: "0px",
              duration: ".5s"
            })
            transition.begin(backdrop, {
              property: "background-color",
              to: "rgba(0, 0, 0, .3)",
              duration: ".5s"
            })
          }
        })
      }catch(error) {
        console.log(error)
      }
    }

    logout(){
      localStorage.removeItem("api_access_token")
      storage.clear()
      window.location.href = '/'
    }

    render(){
      return (
        <Navbar fixedToTop="true" className="header" data-html2canvas-ignore>
          <Navbar.Group align={Alignment.LEFT} className="headerBrand" >
            <div className="headerBrandImage" onClick={()=>(window.location.href = '/')}>
              <img src={logoHeader} alt="Smart Logo" />
            </div>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT} className="headerSocial" >
            <div className="headerUser">
              <p className="headerUserUsername">
                {this.state.name}
              </p>
              {this.props.location.pathname == '/home' && (
                <p 
                className="headerUserLogout"
                onClick={()=>(this.logout())}>
                  Logout
                </p>
              )}
              {this.state.page.name && (
                <p className='headerPageName'>
                  {this.state.page.name}
                </p>
              )}
              {this.props.location.pathname != '/home' && (
                <p 
                  className='headerSettings'
                  onClick={()=>(this.onShowSidebar())}>
                    Settings
                </p>
              )}
            </div>
            <div className="header-user__avatar">
              <div
                className="headerBrandUserAvatar"  
                style={{
                  backgroundImage: `url(//graph.facebook.com/${this.state.id}/picture?type=large&width=1000&height=1000)`
                }}/>
            </div>
            {this.state.page.name && (
              <div
              className="headerBrandPageAvatar"  
              style={{
                  backgroundImage: `url(${this.state.page.picture})`
              }}/>
            )}
          </Navbar.Group>
        </Navbar>
      )
    }
}

export default withRouter(Header)
