import React, { Component } from 'react'
import { Card, Elevation, Spinner } from '@blueprintjs/core'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Truncate from '../../Shared/Truncate'

import logoSmart from '../../../assets/img/logo.png'
import placeholder from '../../../assets/img/placeholder.jpg'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class BestPerformingAds extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`https://adsapi.smartintel.io/api/ads/bestAds/${page}?since=${since}&until=${until}&domain=${window.location.hostname}&campaings=${this.props.campaings}`)
    })
  }

  chart(data){
    return (
      <div className='postsContainer'>
        {data.slice(0, 10).map((ad)=>{
          const post = _.has(ad, 'post')  ? ad.post : {}
          const creative = _.has(ad, 'adcreatives[0]') ? ad.adcreatives[0] : {}

          return (
            <div 
              className='post'
              onClick={()=>(!!post && window.open(`https://www.facebook.com/${post.id || creative.effective_object_story_id}`, '_blank'))}>
              <div className='postPicture'
                style={{
                  backgroundImage: `url(${logoSmart})`
                }}
              >
                <div style={{
                  backgroundImage: `url(${ad.image_url || creative.image_url })`
                }} />
              </div>
              <div className='postMessage'>
                <Truncate
                  text={post.message || creative.body}
                /> 
              </div>
              <p className='postDate'>
                {moment(ad.created_time).format('MMM. DD YYYY')}
              </p>
              <p className='postRelevance'>ADs Score</p>
              <p className='postNumber'>{numeral(ad.es_score).format('0,0.00')}%</p>
            </div>
          )
        })}
        {_.range(0, 5-data.length, 1).map((post)=>{
          return (
            <div 
              className="postPlaceholder"
              style={{
                backgroundImage: `url(${placeholder})`
              }}
            />
          )
        })}
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data || [])

    return (
      <div>{Chart}</div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="adsBestPerforming">
        <Title title='Best Performing ADs' />
         <div className='adsBestPerformingContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default BestPerformingAds
