import React, { Component } from 'react'
import { Card, Elevation, Spinner } from "@blueprintjs/core"

import SimpleBar from 'simplebar-react'

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import Chart from '../../Shared/Chart'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Colors extends Component {
  constructor(props){
    super(props)
    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
    this.table = this.table.bind(this)
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range, filter){
    const { competitor } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      return axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/vision/${page}/colors?since=${since}&until=${until}&provider=${filter}`)
    }).then((response)=>{
      return response.data.data.slice(0, 30)
    })
  }
    
  chart(data){
    data = data.slice(0, 5)
    const total = _.sumBy(data, 'score')

    return (
      <ul id="socialColorsChart">
        {data.map((color, index)=>{
          return (
            <li
            style={{
              width: `${(100/total)*color.score}%`,
              backgroundColor: `#${color.hex}`
            }}>
              <div>
                <p>#{color.hex}, RGB({color.rgb.red}, {color.rgb.green}, {color.rgb.blue})</p>
                <p>{numeral(color.score).format('0,0.00')}%</p>
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  table(data){
    return (
			<div className="socialColorsTable">
        <table>
          <thead>
            <tr>
              <th>Color</th>
              <th>Count</th> 
              <th>ER</th>
            </tr>
          </thead>
          <SimpleBar style={{maxHeight: '106px'}}>
            <tbody>
              {data.map((color, index)=>{
                return (
                  <tr>
                    <td>
                      {index+1}. #{color.hex.toUpperCase()} 
                      <div style={{backgroundColor: `#${color.hex}`}} className="socialColorstableColor" />
                    </td>
                    <td>{numeral(color.count).format('0,0')}</td>
                    <td>{numeral(color.score).format('0,0.00')}%</td> 
                  </tr>
                )
              })}
              {_.range(0, 5 - data.length, 1).map((item, index)=>{
                return (
                  <tr>
                    <td>-</td>
                    <td>-</td> 
                    <td>-</td>
                  </tr>
                )
              })}
            </tbody>
          </SimpleBar>
        </table>
      </div>
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)
    const Table = this.table(data)

    return (
      <div>
        {Chart}
        {Table}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }


  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialColors">
        <Title title='Dominant Colors' />
        <div
          className='socialColorsContainer'
          ref={(ref)=>(this.chartContainer = ref)}
        >
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default Colors
