import React, { Component } from 'react'
import logo from '../../assets/img/logo.png'
import _ from 'lodash'
// COMPONENTES
import MainContainer from './MainContainer/MainContainer'

import './styles.css'

class SalesBenchmark extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: 'all',
      order: 'date'
    }

    this.onFilter = this.onFilter.bind(this)
    this.onOrder = this.onOrder.bind(this)
  }


  onFilter(filter){
    this.setState({
      filter: filter
    })
  }

  onOrder(order){
    this.setState({
      order: order
    })
  }

  render () {    
    return(
      <div id="dashboard">
        <div className="salesSidebar">
          <div>
            <a
              className={this.state.filter == 'facebook' && 'active'} 
              onClick={()=>(this.onFilter('facebook'))}
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className={this.state.filter == 'instagram' && 'active'} 
              onClick={()=>(this.onFilter('instagram'))}
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className={this.state.filter == 'twitter' && 'active'} 
              onClick={()=>(this.onFilter('twitter'))}
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              className={this.state.filter == 'all' && 'active'} 
              onClick={()=>(this.onFilter('all'))}
            > 
              <i>ALL</i>
            </a>
          </div>
          <div>
            <a 
              className={this.state.order == 'date' && 'active'} 
              onClick={()=>(this.onOrder('date'))}
            >
              <i className="far fa-calendar-alt" />
              <p className="salesSidebarLabel">DAY</p>
            </a>

            <a 
              className={this.state.order == 'es' && 'active'} 
              onClick={()=>(this.onOrder('es'))}
            >
              <i className="far fa-heart" />
              <p className="salesSidebarLabel">ENG SCORE</p>
            </a>

            <a 
              className={this.state.order == 'reach_organic' && 'active'} 
              onClick={()=>(this.onOrder('reach_organic'))}
            >
              <i className="fas fa-sitemap" />
              <p className="salesSidebarLabel">REACH</p>
            </a>
            <a 
              className={this.state.order == 'interactions' && 'active'} 
              onClick={()=>(this.onOrder('interactions'))}
            >
              <i className="fab fa-wpexplorer" />
              <p className="salesSidebarLabel">INTERACTIONS</p>
            </a>
          </div>
        </div>
        <MainContainer
          {...this.props} 
          filter={this.state.filter}
          order={this.state.order}
          competitor={this.state.competitor}
        />
      </div>
    )
  }
}

export default SalesBenchmark
