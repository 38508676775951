import React, { Component } from 'react'
import mind from '../../../assets/img/mind.png'
import SocialIcons from '../../Shared/SocialIcons'
import Alert from '../../Shared/Alert'
import './styles.css'

class PlaceholderAccount extends Component {
  render () {
    const { brand, provider, account, lock } = this.props

    return(
      <div>
      <Alert
        confirm 
        body='Your plan doesn’t have the required credentials to access this section. Please call you brand manager for further assistance.' 
        ref={(alert)=>(this.error = alert)} 
      />
      <div 
        className='brandAccount'
        onClick={()=>{
          lock ? this.error.show() : this.props.onAddAccount && this.props.onAddAccount(brand, provider)
        }}>
        {(!!account && account.pending) ? (
          <div className="loadingAccountSpinerContainer">
            <img src={mind} />
            <div className='loadingAccountSpiner'>
              <div class="lds-css">
                <div class="lds-dual-ring">
                  <div>
                    
                  </div>
                </div>
              </div>
            </div> 
          </div>
        ) : (
          <div className='brandAccountsIcon'>
            <div className={`brandAccounts${provider}`}>
              <SocialIcons provider={provider} />
            </div>
          </div>
        )}
        <p className='brandAccountsProvider'>+ {provider}</p>
      </div>
      </div>
    )
  }
}

export default PlaceholderAccount