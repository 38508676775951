const Permissions = [{
	name: 'Communication Strategy',
	lock: ['ads', 'google']
},{
	name: 'Benchmark',
	lock: ['ads']
},{
	name: 'Budget Optimization',
	lock: []
}]

module.exports = Permissions