import React, { Component } from 'react';
import { Card, Elevation, Spinner } from "@blueprintjs/core";

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import Color from 'color'
import { Colors } from '../../../config'
import Chart from '../../Shared/Chart'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class VisitorsSS extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        columns: [],
        rows: [],
        serialized: [{}]
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.table = this.table.bind(this)
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${page}/analytics/google/score`, {
        params: {
          since,
          until,
          dimensions: 'ga:userType',
          sort: '-ga:sessions',
          filters: ''
        }
      })
    }).then((response)=>{
      const { serialized } = response.data.data
      const pick = ['User Type', 'Transactions', 'Revenue', 'SS']
      const dataSerialized = _.map(serialized, (item)=>(_.pick(item, pick)))
      const dataRows = _.map(dataSerialized, (item)=>(_.values(item)))

      return {
        columns: pick,
        rows: dataRows,
        serialized: dataSerialized
      }
    })
  }

  chart(data){
    const { serialized, columns, rows } = data

    const custom =  _.map(rows, (item, index)=>{
      const background = Colors[index]
      const color = Color(background)

      return {
        'plot-rules': {
          rule: `%p==${index}`,
          text: item[0],
          color: color.isLight() ? '#36383a' : '#ffffff'
        },
        'plot-labels': {
          rule: `%p==${index}`,
          text: `<span style="font-weight:bold"><div style="width: 10px; height: 10px; background-color: ${Colors[index]}; display: inline-block"></div> ${item[0]}: ${numeral(item[3]).format('0,0[.]00')}%</span>`,
          borderColor: Colors[index]
        }
      }
    })

    let config = {
      type: 'pie',
      backgroundColor: '#363737',
      globals: {
        fontFamily: 'Helvetica'
      },
      gui: {
        behaviors: [{
          id: 'ViewSource',
          enabled: 'none'
        },{
          id: 'HideGuide',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'ViewAsPng',
          enabled: 'none'
        },{
          id: 'ViewDataTable',
          enabled: 'none'
        }]
      },
      plotarea:{
        backgroundColor : '#363737',
        adjustLayout: true,
        margin: 0
      },
      plot:{
        borderWidth: 0,
        shadow: 0,
        detach: false,
        valueBox: {
          fontWeight: 100,
          fontSize: '12px',
          rules: _.concat(
            _.map(custom, 'plot-rules'), [{
              rule: '%npv < 10',
              text: ''
            }]
          )
        },
        tooltip: {
          text: '%v',
          multiple: true,
          fontSize: '12px',
          color: '#FFFFFF',
          backgroundColor: '#000',
          borderWidth: 0,
          alpha: 0.6,
          callout: true,
          shadow: 0,
          borderRadius: 4,
          rules: _.map(custom, 'plot-labels')
        }
      },
      series:  _.map(rows, (row, index)=>{
        return {
          values: [row[3]],
          backgroundColor: Colors[index]
        }
      })
    }

    return (
      <div>
        <Chart 
          width="100%" 
          height="300px"
          output="canvas"
          data={config} 
        />
      </div>
    )
  }

  table(data){
    const { serialized, columns, rows } = data
    return (
        <div className="googleVisitorsSSTable">
          <table>
            <thead>
              <tr>
                {columns.map((column)=>{
                  return (
                    <th>{column}</th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index)=>{
                return (
                  <tr>
                    <td>{index+1}. {row[0]}</td>
                    <td>{row[1]}</td>
                    <td>${numeral(row[2]).format('0,0')}</td>
                    <td>{numeral(row[3]).format('0,0[.]00')}%</td> 
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)
    const Table = this.table(data)

    return (
      <div>
        {Chart}
        {Table}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        id='googleVisitorsSS'
        className="no-pad-t-b">
        <Title description='Ponderación de visitantes segmentados en Recurrentes y nuevos.' title='Visitors' />
        <div className="googleVisitorsSSContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default  VisitorsSS
