import React, { Component } from 'react';
import MainContainer from '../MainContainer'

class WebIntelligence extends Component {
  render () {
    return(
      <div id="calender">
        <MainContainer {...this.props} />
      </div>
    )
  }
}

export default WebIntelligence
