import React, { Component } from 'react';
import { Card, Elevation, Spinner } from "@blueprintjs/core";

import SimpleBar from 'simplebar-react'
import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import { Colors } from '../../../config'
import Chart from '../../Shared/Chart'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class GenderSS extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        columns: [],
        rows: [],
        serialized: [{}]
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.table = this.table.bind(this)
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${page}/analytics/google/score`, {
        params: {
          since,
          until,
          dimensions: 'ga:userGender',
          sort: '-ga:sessions',
          filters: ''
        }
      })
    }).then((response)=>{
      const { serialized } = response.data.data
      const pick = ['Gender', 'Transactions', 'Revenue', 'SS']
      const dataSerialized = _.map(serialized, (item)=>(_.pick(item, pick)))
      const dataRows = _.map(dataSerialized, (item)=>(_.values(item)))

      return {
        columns: pick,
        rows: dataRows,
        serialized: dataSerialized
      }
    })
  }

  chart(data){
    const { serialized, columns, rows } = data
    const total = _.sumBy(serialized, 'SS')

    return (
      <div className="googleGenderSSChartContainer">
        <div className="googleGenderSSChart">
          {serialized.map((item)=>{
            const percent = Math.round(item.SS/(total/100))
            return (
              <div 
                className={`googleGenderSS${item.Gender}`}
                style={{
                  width: `${percent}%`
                }}>
                <i className={`fas fa-${item.Gender}`}></i>
                <p>{percent}%</p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  table(data){
    const { columns, rows, serialized } = data

    return (
      <div className="googleGenderSSTable">
        <table>
          <thead>
            <tr>
              {columns.map((column)=>{
                return (
                  <th>{column}</th>
                )
              })}
            </tr>
          </thead>
          <SimpleBar style={{ maxHeight: '155px' }}>
            <tbody>
              {rows.map((row, index)=>{
                return (
                  <tr>
                    <td>{index+1}. {row[0]}</td>
                    <td>{row[1]}</td>
                    <td>${numeral(row[2]).format('0,0')}</td>
                    <td>{numeral(row[3]).format('0,0[.]00')}%</td> 
                  </tr>
                )
              })}
            </tbody>
          </SimpleBar>
        </table>
      </div>
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)
    const Table = this.table(data)

    return (
      <div>
        {Chart}
        {Table}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        id='googleGenderSS'
        className="no-pad-t-b">
        <Title title='Gender' />
        <div className="googleGenderSSContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default  GenderSS
