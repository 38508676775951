import React, { Component } from 'react'
import Header from '../Shared/Header/Header'
import ListPages from './ListPages'
import ListAds from './ListAds'
import ListGoogle from './ListGoogle'
import Account from './Account'
import TimeZonePicker from './TimeZonePicker'
import PlaceholderAccount from './PlaceholderAccount'
import { connect } from 'react-redux'
import axios from '../../services/axios'
import _ from 'lodash'
import ContentLoader from 'react-content-loader'
import social from '../../services/social'
import SocialIcons from '../Shared/SocialIcons'
import Loading from '../Shared/LoadingCharts'
import Alert from '../Shared/Alert'
import { Providers, Permissions } from '../../config'
import { 
  Spinner,
  Card,
  H4,
  H1,
  Button,
  Elevation
} from '@blueprintjs/core'

import './styles.css'

class Home extends Component {
  constructor(props){
    super(props)
    this.state = {
      brands: [],
      newBrand: undefined,
      loading: false
    }

    this.AlertsBrandsDeletes = []
    this.onDeleteBrand = this.onDeleteBrand.bind(this)
    this.onDeleteNetwork = this.onDeleteNetwork.bind(this);
    this.onAddBrand = this.onAddBrand.bind(this)
    this.onAddNetwork = this.onAddNetwork.bind(this)
    this.onEditNetwork = this.onEditNetwork.bind(this)
    this.brands = this.brands.bind(this)
  }

  brandPermissions(permissions){
    permissions = _.filter(permissions, 'access')

    if(permissions.length == 0){
      return 'all'
    }else{
      permissions = _.filter(Permissions, (permission,  index)=>{
        return !!_.find(permissions, { 'name': permission.name });
      })
      permissions = _.orderBy(permissions, 'lock.length', 'desc')
      permissions = _.reduce(permissions, (all, permission, index)=>{
        return index == 0 ? permission.lock : _.intersection(all, permission.lock)
      }, [])

      return permissions
    }
  }

  onDeleteNetwork(brand, provider){
    axios.delete(`marcas/${brand}/accounts`, {
      params: {
        provider
      }
    }).then((response)=>{
      this.brands()
    }).catch((error)=>{
      console.log(error)
    })
  }

  onDeleteBrand(brand){
    axios.delete(`/marcas/${brand}`)
    .then((response)=>{
      this.brands()
    }).catch((error)=>{
      console.log(error)
    })
  }

  onAddBrand(){
    social('facebook')
    .then((response)=>{
      return this.listPages.show(response.token, 'facebook')
    }).then((page)=>{
      this.setState({
        newBrand: page.data.name
      })
      return axios.post('/marcas', {
        page_id: page.data.id,
        access_token: page.data.access_token
      })
    }).then((response)=>{
      this.setState({
        brands: response.data.data.brands,
        newBrand: undefined
      })
    }).catch((error)=>{
      this.setState({
        newBrand: undefined
      })
    })
  }

  onEditNetwork(brand, provider){
    social(provider == 'youtube' ? 'google' : provider)
    .then((response)=>{
      switch(provider) {
        case 'facebook':
        case 'instagram':
        case 'linkedin':
        case 'youtube':
          return this.listPages.show(response.token, provider)
          break;
        case 'ads': 
          return this.listAds.show(response.token)
          break;
        case 'google': 
          return this.listGoogle.show(response.token, response)
          break;
        case 'twitter':
          return {
            provider,
            data: response
          }
          break;
      }
    }).then((account)=>{
      let params = {}
      switch(account.provider) {
        case 'facebook':
        case 'instagram':
        case 'linkedin':
        case 'google':
        case 'youtube':
          params = {
            provider: account.provider,
            access_token: account.data.access_token,
            avatar: account.data.avatar,
            account_id: account.data.id,
            name: account.data.name
          }
          break;
        case 'twitter':
          params = {
            provider: account.provider,
            access_token: account.data.token,
            avatar: account.data.avatar,
            access_token_secret: account.data.tokenSecret,
            account_id: account.data.id,
            name: account.data.name
          }
          break;
        case 'ads':
          params = {
            provider: account.provider,
            access_token: account.data.token,
            account_id: account.data.id
          }
          break;
      }

      let brands = _.concat([], this.state.brands)

      let indexBrand = _.findIndex(brands, {id: brand})
      let index = _.findIndex(brands[indexBrand].accounts, {provider})

      if(index == -1){
        brands[indexBrand].accounts = _.concat(brands[indexBrand].accounts, {
          provider,
          pending: true
        })
      }else{
        brands[indexBrand].accounts[index]['pending'] = true
      }

      this.setState({
        brands: brands
      })

      return axios.put(`marcas/${brand}/accounts`,  params)
    }).then((response)=>{
      this.brands()
    }).catch((error)=>{
      console.log(error)
    })
  }

  onAddNetwork(brand, provider){
    social(provider == 'youtube' ? 'google' : provider)
    .then((response)=>{
      switch(provider) {
        case 'facebook':
        case 'instagram':
        case 'linkedin':
        case 'youtube':
          return this.listPages.show(response.token, provider)
          break;
        case 'ads': 
          return this.listAds.show(response.token)
          break;
        case 'google': 
          return this.listGoogle.show(response.token, response)
          break;
        case 'twitter':
          return {
            provider,
            data: response
          }
          break;
      }
    }).then((account)=>{
      let params = {}
      switch(account.provider) {
        case 'facebook':
        case 'instagram':
        case 'linkedin':
        case 'google':
        case 'youtube':
          params = {
            provider: account.provider,
            access_token: account.data.access_token,
            avatar: account.data.avatar,
            account_id: account.data.id,
            name: account.data.name
          }
          break;
        case 'twitter':
          params = {
            provider: account.provider,
            access_token: account.data.token,
            avatar: account.data.avatar,
            access_token_secret: account.data.tokenSecret,
            account_id: account.data.id,
            name: account.data.name
          }
          break;
        case 'ads':
          params = {
            provider: account.provider,
            access_token: account.data.token,
            account_id: account.data.id
          }
          break;
      }

      let brands = _.concat([], this.state.brands)

      let indexBrand = _.findIndex(brands, {id: brand})
      let index = _.findIndex(brands[indexBrand].accounts, {provider})

      if(index == -1){
        brands[indexBrand].accounts = _.concat(brands[indexBrand].accounts, {
          provider,
          pending: true
        })
      }else{
        brands[indexBrand].accounts[index]['pending'] = true
      }

      this.setState({
        brands: brands
      })

      return axios.post(`marcas/${brand}/accounts`,  params)
    }).then((response)=>{
      this.brands()
    }).catch((error)=>{
      console.log(error)
    })
  }

  brands(){
    axios.get('marcas/list')
    .then((brands)=>{
      this.setState({
        loading: true,
        brands: brands.data.data.brands
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  componentDidMount() {
    this.brands()
  }

  render() {
      const { brands } = this.state
      return (
        <div className="homeContainer">
          <Alert
            confirm 
            body='Your plan doesn’t have the required credentials to access this section. Please call you brand manager for further assistance.' 
            ref={(alert)=>(this.error = alert)} 
          />
          <ListGoogle 
            ref={(ListGoogle)=>(this.listGoogle = ListGoogle)} />
          <ListAds 
            ref={(ListAds)=>(this.listAds = ListAds)} />
          <ListPages 
            ref={(ListPages)=>(this.listPages = ListPages)} />
          <div>
            <div className="homeHeader">
              <H1 className="homeHeaderTitle">YOUR BRANDS</H1>
              {this.props.config.SALES_BENCHMARK && (
                <Button
                  onClick={()=>(window.location.href = "/sales")}
                  minimal="true"
                  className="button button-primary homeHeaderAddBrand" >
                  Sales Benchmark
                </Button>
              )}
              <Button
                onClick={this.onAddBrand}
                minimal="true"
                className="button button-primary homeHeaderAddBrand" >
                Add a Brand
              </Button>
            </div>
            <div className="homeBrands" data-loading={this.state.loading}>
              {brands.map((brand, index)=>{
                const permissions = this.brandPermissions(brand.brandPermissions)

                return (
                  <Card
                    interactive={false} 
                    elevation={Elevation.TWO} 
                    className="brand">
                    <Alert 
                      body='Do you want to erase this brand?'
                      onAccept={()=>(this.onDeleteBrand(brand.id))} 
                      ref={(Alert)=>(this.AlertsBrandsDeletes[index] = Alert)} 
                    />
                    <TimeZonePicker />
                    <H4 className="brandName">{brand.name}</H4>
                    <div className='brandAccounts'>
                      {Providers.map((provider)=>{
                        const account = _.find(brand.accounts, {provider})
                        return  account != undefined && !account.pending ? (
                          <Account
                            lock={permissions == 'all' || permissions.includes(provider)} 
                            brand={brand.id}
                            account={account}
                            provider={provider}
                            onDelete={this.onDeleteNetwork}
                            onUpdate={this.onEditNetwork}
                          />
                        ) : (
                          <PlaceholderAccount 
                            lock={permissions == 'all' || permissions.includes(provider)}
                            onAddAccount={this.onAddNetwork}
                            provider={provider}
                            account={account}
                            brand={brand.id}
                          />
                        )
                      })}
                    </div>
                    <div className='brandActions'>
                       <Button
                        onClick={()=>(this.AlertsBrandsDeletes[index].show())} 
                        intent="danger"
                        icon="trash" 
                        text="Erase Brand" />

                        <a
                          onClick={(event)=>{
                            if(permissions == 'all'){
                              this.error.show()
                              event.preventDefault()
                              return false
                            }
                          }}
                          href={permissions != 'all' && `/dashboard/${brand.id}`}
                          className={`bp3-button bp3-minimal button button-primary ${permissions == 'all' && 'bp3-disabled'}`}>
                          <span className="bp3-button-text">Start</span>
                        </a>
                    </div>
                  </Card>
                )
              })}
              {window.location.hostname == 'apple.smartintel.io' && (
                <Card
                  interactive={false} 
                  elevation={Elevation.TWO} 
                  className="brand">
                  <H4 className="brandName">Name</H4>
                  <div className='brandAccounts'>
                    {Providers.map((provider)=>{
                      return (
                        <PlaceholderAccount 
                          provider={provider}
                        />  
                      )
                    })}
                  </div>
                  <div className='brandActions'>
                    <Button
                      intent="danger"
                      icon="trash" 
                      text="Erase Brand" 
                    />
                    <a
                      className='bp3-button bp3-minimal button button-primary'>
                      <span className="bp3-button-text">Start</span>
                    </a>
                  </div>
                </Card>
              )}
              {this.state.newBrand != undefined && (
                <Card
                  interactive={false} 
                  elevation={Elevation.TWO} 
                  className="brand">
                  <H4 className="brandName">{this.state.newBrand}</H4>
                  <div className='brandAccountPlaceholder'>
                    <ContentLoader 
                      height={300}
                      width={400}
                      speed={2}
                      primaryColor="#7b7b7b"
                      secondaryColor="#363737"
                    >
                      <circle cx="100" cy="50" r="47" /> 
                      <circle cx="204" cy="50" r="47" /> 
                      <circle cx="308" cy="50" r="47" /> 
                      <circle cx="100" cy="175" r="47" /> 
                      <circle cx="204" cy="175" r="47" /> 
                      <circle cx="308" cy="175" r="47" /> 
                      <rect x="64" y="105" rx="5" ry="5" width="70" height="8" /> 
                      <rect x="170" y="105" rx="5" ry="5" width="70" height="8" /> 
                      <rect x="276" y="105" rx="5" ry="5" width="70" height="8" /> 
                      <rect x="64" y="230" rx="5" ry="5" width="70" height="8" /> 
                      <rect x="170" y="230" rx="5" ry="5" width="70" height="8" /> 
                      <rect x="276" y="230" rx="5" ry="5" width="70" height="8" />
                    </ContentLoader>
                  </div>
                </Card>
              )}
              {!this.state.loading &&(
                <Loading />
              )}
            </div>
          </div>
        </div>
      )
  }
}


export default Home
