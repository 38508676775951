import React, { Component } from 'react'
import { 
  Spinner,
  Dialog 
} from "@blueprintjs/core"
import { Dropdown as DropdownSem } from 'semantic-ui-react'
import axios from '../../../services/axios'
import SocialIcons from '../../Shared/SocialIcons'
import DropDown from '../../Shared/DropDown'


import './styles.css'

class ListGoogle extends Component {
    constructor(props){
      super(props)
      this.state = {
        visible: false,
        accounts: [],
        properties: [],
        views: [],
        accountSelect: -1,
        propertySelect: -1,
        viewSelect: -1
      }

      this.access_token = '' 
      this.response = {} 
      this.resolve = null
      this.reject = null

      this.onAccountsSelect = this.onAccountsSelect.bind(this)
      this.onPropertySelect = this.onPropertySelect.bind(this)
      this.onSelect = this.onSelect.bind(this)
      this.show = this.show.bind(this)
      this.hide = this.hide.bind(this)
    }


    onAccountsSelect(){
      const { accountSelect, accounts } = this.state
      const account = accounts[accountSelect]

      axios.get(`analytics/google/${account.id}/properties`, {
        params: {
          access_token: this.access_token
        }
      }).then((response)=>{
        this.setState({
          properties: response.data.data,
          viewSelect: -1,
          propertySelect: -1,
          views: []
        })
      })
    }

    onPropertySelect(){
      const { propertySelect, properties, accountSelect, accounts } = this.state
      const property = properties[propertySelect]
      const account = accounts[accountSelect]

      axios.get(`analytics/google/${account.id}/${property.id}/views`, {
        params: {
          access_token: this.access_token
        }
      }).then((response)=>{
        this.setState({
          views: response.data.data
        })
      })
    }

    onSelect(){
      const { viewSelect, views} = this.state
      const view = views[viewSelect]

      this.resolve && this.resolve({
        provider: 'google',
        data: {
          id: view.id,
          access_token: this.access_token,
          avatar: this.response.avatar,
          name: view.name,
          provider: 'google'
        }
      })

      this.hide() 
    }

    show(access_token, response){
      this.setState({
        visible: true
      })
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        this.access_token = access_token
        this.response = response

        axios.get('analytics/google/accounts', {
          params: {
            access_token
          }
        }).then((response)=>{
          this.setState({
            accounts: response.data.data
          })
        })
      })
    }

    hide(){
      this.setState({
        visible: false,
        accounts: [],
        properties: [],
        views: [],
        accountSelect: -1,
        propertySelect: -1,
        viewSelect: -1
      })
    }

    render() {
      return(
        <Dialog
          isOpen={this.state.visible}
          className='listGoogle'
        >
          <div className='listGoogleContainer'>
            <div className="bp3-dialog-header listGoogleHeader">
              <SocialIcons provider='google' className='listGoogleProvider' />
              <h4 className="bp3-heading">Select Your Account</h4>
              <i className="fas fa-times" onClick={this.hide}></i>
            </div>
            <div className="bp3-dialog-body">
              <div className="listGoogleItems">
                <div className="listGoogleItemsItem">
                  <DropDown
                    disabled={this.state.accounts.length == 0}
                    bsStyle='default'
                    title={this.state.accountSelect > -1 ? this.state.accounts[this.state.accountSelect].name : 'accounts'}
                  >
                    {this.state.accounts.map((account, index)=>{
                      return (
                        <DropdownSem.Item onClick={()=>{
                          this.setState({
                            accountSelect: index
                          }, this.onAccountsSelect)
                        }} active={this.state.accountSelect == index} >{account.name}</DropdownSem.Item>
                      )
                    })}
                  </DropDown>
                </div>
                <div className="listGoogleItemsItem">
                  <DropDown
                    disabled={this.state.properties.length == 0}
                    bsStyle='default'
                    title={this.state.propertySelect > -1 ? this.state.properties[this.state.propertySelect].name : 'Properties'}
                  >
                    {this.state.properties.map((property, index)=>{
                      return (
                        <DropdownSem.Item onClick={()=>{
                          this.setState({
                            propertySelect: index
                          }, this.onPropertySelect)
                        }} active={this.state.propertySelect == index} >{property.name}</DropdownSem.Item>
                      )
                    })}
                  </DropDown>
                </div>
                <div className="listGoogleItemsItem">
                  <DropDown
                    disabled={this.state.views.length == 0}
                    bsStyle='default'
                    title={this.state.viewSelect > -1 ? this.state.views[this.state.viewSelect].name : 'Views'}
                  >
                    {this.state.views.map((view, index)=>{
                      return (
                        <DropdownSem.Item onClick={()=>{
                          this.setState({
                            viewSelect: index
                          })
                        }} active={this.state.viewSelect == index} >{view.name}</DropdownSem.Item>
                      )
                    })}
                  </DropDown>
                </div>
              </div>
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <button
                  disabled={this.state.viewSelect == -1}
                  onClick={this.onSelect} 
                  type="button" 
                  className="bp3-button listGoogleSelect">
                  Select
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      )
    }
}

export default ListGoogle
