import React, { Component } from 'react'
import { Colors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"
import numeral from 'numeral'
import moment from 'moment'
import Chart from '../../Shared/Chart'
import _ from 'lodash'
import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

const icons = {
  'Avg. Domain Lookup Time (sec)': <i className='fas fa-search' />,
  'Avg. Page Download Time (sec)': <i className='fas fa-cloud-download-alt' />,
  'Avg. Page Load Time (sec)': <i className='far fa-dot-circle' />,
  'Avg. Server Connection Time (sec)': <i className='fas fa-server' />,
}

class SiteSpeed extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        columns: [],
        rows: [],
        serialized: [{}]
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:avgPageLoadTime, ga:avgServerConnectionTime, ga:avgDomainLookupTime, ga:avgPageDownloadTime'
        }
      })
    })
  }

  secondsToTime(s){
    s = s * 1000
    const pad = (n, z)=>{
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;
    
    return pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
  }
  
  chart(data){
    const { serialized, columns, rows } = data

    return (
      <div className="googleSiteSpeedItems">
        {_.map(rows[0], (item, index)=>{
          return (
            <div className="googleSiteSpeedItem" >
              <div 
                className="googleSiteSpeedIcon"
                style={{
                  backgroundColor: Colors[index],
                  color: Color(Colors[index]).isLight() ? '#36383a' : '#ffffff'
                }}>
                {icons[columns[index]]}
              </div>
              <p className="googleSiteSpeedTitle">{columns[index].replace('Time (sec)', '').replace('Avg. ', '')}</p>
              <p className="googleSiteSpeedValue">{this.secondsToTime(item)}</p>
            </div>
          )
        })}
      </div>
    )
  }
  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>{Chart}</div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })
    
    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id='googleSiteSpeed'>
        <Title description='Reporte de velocidades relacionadas a la rapidez con la que se carga y navega el sitio.' title='Site Speed' />
        <div className="googleSiteSpeedContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )

  }
}

export default SiteSpeed
