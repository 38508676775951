import React, { Component } from 'react'

import './styles.css'

class ReloadChart extends Component {
  render () {
    return(
    	<div className="ReloadChart">
        <i className="far fa-sad-cry"></i>
        <p>We had a problem on the server.</p>
        <i 
          className="fas fa-redo-alt"
          onClick={()=>( this.props.onReload && this.props.onReload())}
        />
      </div>
    )
  }
}

export default ReloadChart