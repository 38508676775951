import React, { Component } from 'react'
import PanelCategories from '../PanelCategories'
import PanelPromotions from '../PanelPromotions'
import PanelFormats from '../PanelFormats'

class Panels extends Component {
  constructor(props){
    super(props)

    this.state = {
      select: props.defaultPanel || 'categories' 
    }
  }

  onSwitch(panel){
    const { select } = this.state

    this.setState({
      select: panel || (select == 'categories' ? 'promotions' : 'categories')
    })
  }

  render () {
    switch(this.state.select) {
      case 'formats':
        return (
          <PanelFormats
            {...this.props}
            data={this.props.data.formats}
          />
        )
        break;
      case 'promotions':
        return (
          <PanelPromotions 
            {...this.props}
            data={this.props.data.promotions}
          />
        )
        break;
      case 'categories': 
      default:
        return (
          <PanelCategories 
            {...this.props}
            data={this.props.data.categories}
          />
        )
        break;
    }
  }
}

export default Panels