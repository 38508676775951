import React, { Component } from 'react'
import { 
	Link, 
	Element , 
	Events, 
	animateScroll as scroll, 
	scrollSpy, 
	scroller 
} from 'react-scroll'
import _ from 'lodash'
import MainContainer from '../MainContainer/MainContainer'
import logo from '../../../assets/img/logo.png'

import './styles.css'

class SocialIntelligence extends Component {
  constructor(props){
    super(props)

    this.state = {
      filter: 'all',
      competitor: 'brand'
    }

    this.onFilter = this.onFilter.bind(this)
  }

  onCompetitor(competitor){
    this.setState({
      competitor: competitor
    })
  }

  onFilter(filter){
    this.setState({
      filter: filter
    })
  }

  render () {
    const { industrySettings, networks } = this.props
    const { competitors_enabled } = this.props.settings
    
    const brand = _.find(networks, {
      provider: 'facebook'
    })

    let competitors = _.map(industrySettings.names.competitors, (competitor, index)=>{
      const { facebook, instagram, twitter, images } = industrySettings
      const id = facebook[index] || twitter[index] || instagram[index] || ''

      return {
        name: competitor == '' ? `Competitor ${index+1}` : competitor,
        facebook: facebook[index] || '',
        twitter: twitter[index] || '',
        instagram: instagram[index] || '',
        image: images.competitors[index],
        id: competitor == '' ? id : competitor,
      }
    })

    competitors = _.filter(competitors, (competitor, index)=>{
      const { facebook, instagram, twitter } = competitor

      return facebook.trim() != '' || instagram.trim() || twitter.trim()
    })

    return(
      <div id="social">
      	<div className="socialSidebar" data-html2canvas-ignore>
          <div className="dashboardSidebarProviders">
            <a 
              className={this.state.competitor == 'brand' && 'active'} 
              onClick={()=>(this.onCompetitor('brand'))}
            >
              <div className="dashboardSidebarProviderImage" style={{backgroundImage: `url(${brand.picture})`}} />
              <p className="dashboardSidebarLabel">{industrySettings.names.brand.trim() == '' ? brand.name : industrySettings.names.brand}</p>
            </a>

            {(competitors.length > 0 && competitors_enabled) && (
              <a 
                className={this.state.competitor == 'all' && 'active'} 
                onClick={()=>{
                  this.onCompetitor('all')
                  this.state.filter == 'linkedin' && this.onFilter('all')
                }}
              >
                <div className="dashboardSidebarProviderImage" style={{backgroundImage: `url(${logo})`}} />
                <p className="dashboardSidebarLabel">All</p>
              </a>
            )}
          </div>
          <div>
            <a
              className={this.state.filter == 'facebook' && 'active'} 
              onClick={()=>(this.onFilter('facebook'))}
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className={this.state.filter == 'instagram' && 'active'} 
              onClick={()=>(this.onFilter('instagram'))}
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className={this.state.filter == 'twitter' && 'active'} 
              onClick={()=>(this.onFilter('twitter'))}
            >
              <i className="fab fa-twitter"></i>
            </a>
            {this.state.competitor == 'brand' && (
              <a
                className={this.state.filter == 'linkedin' && 'active'} 
                onClick={()=>(this.onFilter('linkedin'))}
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            )}
            {this.state.competitor == 'brand' && (
              <a
                className={this.state.filter == 'youtube' && 'active'} 
                onClick={()=>(this.onFilter('youtube'))}
              >
                <i className="fab fa-youtube"></i>
              </a>
            )}
            <a
              className={this.state.filter == 'all' && 'active'} 
              onClick={()=>(this.onFilter('all'))}
            > 
              <i>ALL</i>
            </a>
          </div>
      		<div>
      			<Link 
      				activeClass="active" 
      				to="strategy" 
      				spy={true} 
      				offset={-200}
      				smooth={true}>
							<i className="far fa-comments"></i>
              <p className="socialSidebarLabel">COMM STRATEGY</p>
        		</Link>
            {this.state.competitor == 'brand' && (
          		<Link 
        				activeClass="active" 
        				to="audience" 
        				spy={true} 
        				offset={-100}
        				smooth={true}>
  							<i className="far fa-user"></i>
                <p className="socialSidebarLabel">AUDIENCE</p>
          		</Link>
            )}
        		<Link 
      				activeClass="active" 
      				to="topic" 
      				spy={true} 
      				offset={-100}
      				smooth={true}>
							<i className="fas fa-assistive-listening-systems"></i>
              <p className="socialSidebarLabel">TOPIC DATA</p>
        		</Link>
        		<Link 
      				activeClass="active" 
      				to="top" 
      				spy={true} 
      				offset={-100}
      				smooth={true}>
							<i className="far fa-star"></i>
              <p className="socialSidebarLabel">TOP PERFORMERS</p>
        		</Link>
      		</div>
      	</div>
        <MainContainer 
          filter={this.state.filter} 
          competitor={this.state.competitor}
          {...this.props}
        />
      </div>
    ) 
  }
}

export default SocialIntelligence
