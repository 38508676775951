import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import { Colors, Competitors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Truncate from '../../Shared/Truncate'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

import logo from '../../../assets/img/logo.png'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class ERComp extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(){
    return Promise.all([storage.get('page'), storage.range()])
    .then((responses)=>{
      const since = moment(responses[1][0]).format('YYYY-MM-DD')
      const until = moment(responses[1][1]).format('YYYY-MM-DD')

      return axios.get(`industry/erComp/${responses[0]}?since=${since}&until=${until}`)
    })
  }

  chart(data){
    return (
      data.map((item, index)=>{
        return (
          <div className={`erCompItem ${Competitors < 5 && 'erCompItemFull'}`}>
            <div className="erCompItemIcon" >
               <img alt="" src={!item.brand.image || item.brand.image == "" ? logo : item.brand.image} />
            </div>
            <p className="erCompItemPosition">
              #{index+1}
            </p>
            <div className="erCompItemData">
              <p className="erCompItemValue">
                {numeral(item.score).format('0.0000')}
              </p>
            </div>
          </div>
        )
      })
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data || [])

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData()
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="erComp">
        <Title description='Comparativo de indice de Engagement Rate que se tiene de la marca y la competencia.' title='ER Comp' />
         <div className='erCompContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default ERComp
