import React, { Component } from 'react'
import { 
  Button,
  FormGroup, 
  InputGroup
} from "@blueprintjs/core"
import Alert from '../../../../Shared/Alert'
import is from 'is_js'
import './styles.css'

class InstagramForm extends Component {
  constructor(props){
    super(props)

    this.onSave = this.onSave.bind(this)
  }

  onSave(){
    const { value } = this.Instagram
    this.props.onSave && this.props.onSave({instagram_email: value})
  }

  render () {
    return(
      <div className='sidebarInstagramForm'>
        <Alert 
          body='We need a correct email'
          confirm
          ref={(error)=>(this.error = error)}
        />
        <Alert 
          body='You are saving this email for your Instagram schedule'
          onAccept={this.onSave}
          ref={(confirm)=>(this.confirm = confirm)}
        />
        <FormGroup
          inline={true}>
          <InputGroup
            disabled={this.props.lock}
            inputRef={(input)=>(this.Instagram = input)}
            {...this.props}
          />
        </FormGroup>
        <Button
          onClick={()=>(this.props.lock ? this.props.onErrorPermissions() : is.email(this.Instagram.value) ? this.confirm.show() : this.error.show())} 
          minimal="true" 
          className={`button button-primary sidebar-button ${this.props.lock && 'bp3-disabled'}`}>
          SAVE
        </Button>
      </div>
    )
  }
}

export default InstagramForm