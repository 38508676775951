import { FETCH_PROMOTIONS } from '../actions/types'
const initialState = {
  promotions: []
}

const promotions = (state=initialState, action)=>{
  switch(action.type){
    case FETCH_PROMOTIONS:
      return {
        ...state,
        promotions: action.payload
      }
  }

  return state
}

export default promotions
