import React, { Component } from 'react'
import { Button, H1 } from "@blueprintjs/core"
import moment from 'moment'
import _ from 'lodash'
import DateRangePicker from 'react-bootstrap-daterangepicker'

import { RangeConfig } from '../../../config'
import storage from '../../../services/storage'
import axios from '../../../services/axios'
import Alert from '../../Shared/Alert'

import Loads, { createLoader } from 'react-loads'
import List from '../List'

import 'bootstrap-daterangepicker/daterangepicker.css'
import './styles.css'

class MainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      range: [
        moment(this.props.range[0]).startOf('day').toDate(),
        moment(this.props.range[1]).startOf('day').toDate()
      ],
      find: ''
    } 
    
    this.range = [
      moment(this.props.range[0]).startOf('day').toDate(),
      moment(this.props.range[1]).startOf('day').toDate()
    ]

    this.onFind = this.onFind.bind(this)
    this.onFilter = this.onFilter.bind(this)
    this.onOrder = this.onOrder.bind(this)

    this.onChangeRange = this.onChangeRange.bind(this)
    this.onChangeCategory = this.onChangeCategory.bind(this)

    this.onRefreshPosts = this.onRefreshPosts.bind(this)
    this.getData = this.getData.bind(this)
    this.success = this.success.bind(this)
  }

  shouldComponentUpdate(props, state){
    return !_.isEqual(this.props.competitor, props.competitor) || !_.isEqual(this.state.range, state.range)
  }

  getData(){
    const { order, filter, competitor } = this.props
    const { find } = this.state
    const since = moment(this.range[0]).format('YYYY-MM-DD')
    const until = moment(this.range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      if(competitor == 'brand'){
        return axios.get(`posts/brand/${page}/${since}/${until}/all`)
      }else if(competitor != 'all'){
        return axios.get(`brands/competitorPosts/${page}/${since}/${until}/all?competitor=${competitor.name}`)
      }

      return Promise.all([axios.get(`posts/brand/${page}/${since}/${until}/all`), axios.get(`brands/competitorPosts/${page}/${since}/${until}/all`)])
    }).then((response)=>{
      let posts = []
      if(competitor != 'all'){
        posts = _.map(response.data.data, (post)=>{
          post.is_brand = (competitor == 'brand')
          return post
        }) 
      }else{
        posts = _.concat(posts, _.map(response[0].data.data, (post)=>{
          post.is_brand = true
          return post
        }), _.map(response[1].data.data, (post)=>{
          post.is_brand = false
          return post
        }))
      }

      posts = _.map(posts, (post)=>{
        const brand = _.find(this.props.networks, {
          provider: 'facebook'
        })
        post.competitor = brand
        return post
      })
      posts = this.onFilter(filter, posts, find)
      posts = this.onOrder(order, posts)

      return posts
    })
  }

  success({ response, load }){
    this.load = load
    return (
      <List
        ref={(list)=>(this.list = list)}
        userPermissions={this.props.userPermissions}
        posts={response}
      />
    )
  }

  onFilter(filter, posts, content = ''){
    posts = _.filter(posts, (post)=>{
      const text = (post.caption || post.message || '').normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()
      content = content.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()

      return (filter == 'all' || post.provider == filter) && (content == '' ? true : text.includes(content))
    })

    return posts
  }

  onOrder(order, posts){
    switch (order) {
      case 'date':
        posts = _.orderBy(posts, ['post_published_date'], ['desc'])
        break
      case 'eng_score':
        posts = _.orderBy(posts, ['es_calculated'], ['desc'])
        break
      case 'es_competition':
        posts = _.orderBy(posts, (post)=>{
          return post.es_competition != undefined ?  post.es_competition : 0
        }, ['desc'])
        break
      case 'interactions':
        posts = _.orderBy(posts, (post)=>{
          let interactions = 0

          if(post.is_brand){
            interactions += post.wow + post.likes + post.anger + post.haha + post.sorry + post.love + post.anger
            interactions += post.shares
            interactions += post.comments
          }else{
            interactions += post.metrics.shares
            interactions += post.metrics.reactions
            interactions += post.metrics.comments
          }

          return interactions
        }, ['desc'])
        break
      case 'eng_score_organic':
        posts = _.orderBy(posts, ['es_calculated'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return post.post_impressions_paid == 0
        })
        break
      case 'eng_score_paid':
        posts = _.orderBy(posts, ['es_calculated'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return post.post_impressions_paid > 0
        })
        break
      case 'reach_organic':
        posts = _.orderBy(posts, ['post_impressions_organic'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return !post.post_impressions_paid || post.post_impressions_paid == 0
        })
        break
      case 'reach_paid':
        posts = _.orderBy(posts, ['post_impressions_paid'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return post.post_impressions_paid > 0
        })
        break
      case 'category':
        posts = _.orderBy(posts, ['es_calculated'], ['desc'])
        posts = _.filter(posts, (post)=>{
          return post.categoria_id == 1
        })
        break
    }

    return posts
  }

  onRefreshPosts(find){
    const { filter, order } = this.props

    if(this.list){
      let posts = this.list.getPosts()
      posts = this.onFilter(filter, posts, find != undefined ? find : this.state.find)
      posts = this.onOrder(order, posts)
      this.list.setPosts(posts)
    }
  }

  onChangeRange({startDate, endDate}){
    const range = [startDate.toDate(), endDate.toDate()]

    this.dateStart.innerHTML = moment(range[0]).format('YYYY-MM-DD')
    this.dateEnd.innerHTML = moment(range[1]).format('YYYY-MM-DD')

    storage.set('range', range)
    
    this.range = range

    this.setState({
      range: this.range
    })
  }

  onChangeCategory(id, category){
    const { order, filter } = this.props
    const { posts, postsTemp } = this.state
    let newPosts = _.map(posts, (post)=>{
      if(post.id == id){
        post.categoria_id = category
      }
      return {...post}
    })

    newPosts = this.onFilter(filter, newPosts)
    newPosts = this.onOrder(order, newPosts)

    const newPostsTemp = _.map(postsTemp, (post)=>{
      if(post.id == id){
        post.categoria_id = category
      }
      return {...post}
    })

    this.setState({
      posts: newPosts,
      postsTemp : newPostsTemp
    })
  }

  onFind(event){
    const { target: { value }} = event

    this.setState({
      find: value
    }, ()=>(this.onRefreshPosts(value)))
  }

  render () {
    const Posts = createLoader({
      load: () => this.getData(this.props.range)
    })

    return(
      <div className="dashboardContainer">
        <Alert
          confirm 
          body='Your plan doesn’t have the required credentials to access this section. Please call you brand manager for further assistance.' 
          ref={(alert)=>(this.error = alert)} 
        />
        <div className="top-container">
          <div className="brands-added">
            <div className="grid">
              <div className="col-5_sm-12">
                <H1 className="brands-added__title" data-html2canvas-ignore>Content Analytics</H1>
              </div>
              <div className="col-7_sm-12">
                <div className="searchContainer" data-html2canvas-ignore>
                  <div className="searchSmart bp3-dark">
                    <input 
                      type="text" 
                      onPaste={this.onFind}
                      onKeyDown={this.onFind}
                      ref={(ref)=>(this.find = ref)}
                    />
                    <span className="searchFindIcon">
                      <i className="fas fa-search"></i>
                    </span>
                  </div>
                  <div className="dateSmart bp3-dark">
                    <span className="searchCalendarIcon"><i className="far fa-calendar-alt"></i></span>
                    <DateRangePicker
                      {...RangeConfig} 
                      startDate={this.state.range[0]} 
                      endDate={this.state.range[1]}
                      onApply={(event, picker)=>(this.onChangeRange(picker))}>
                      <div className="daterangepickerDates">
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateStart = ref)}>
                          {moment(this.state.range[0]).format('YYYY-MM-DD')}
                        </span>
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateEnd = ref)}>
                          {moment(this.state.range[1]).format('YYYY-MM-DD')}
                        </span>
                      </div>
                    </DateRangePicker>
                    <Button onClick={()=>(this.onRefreshPosts())} minimal="true" className="button btn-outline" >Search</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="social-intelligence__container" id="MainContainer">
          <div className="dashboardContainerPosts">
            <Posts loadOnMount>
              <Posts.Loading>
                <p>loading</p>
              </Posts.Loading>
              <Posts.Success>
                {this.success}
              </Posts.Success>
              <Posts.Error>
                <p>error</p>
              </Posts.Error>
            </Posts>
          </div>
        </div>
      </div>
    )
  }
}

export default MainContainer
