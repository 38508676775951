import React, { Component } from 'react'

import MainContainer from '../MainContainer/'

class IndustryIntelligence extends Component {
  render(){
    return (
      <div id="industry">
        <MainContainer {...this.props} />
      </div>
    )
  }
}


export default IndustryIntelligence
