import React, { Component } from 'react';
import MainContainer from '../MainContainer/MainContainer'

class AdsIntelligence extends Component {
  render () {
    return(
      <div id="ads">
        <MainContainer {...this.props} />
      </div>
    )
  }
}

export default AdsIntelligence
