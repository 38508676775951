import React, { Component } from 'react'
import { Card, Elevation, Position, H5, H6  } from "@blueprintjs/core"

import logoSmartCom from '../../../../../assets/img/loginsmart.png'
import logoSmart from '../../../../../assets/img/logo.png'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import { Tooltip } from 'react-tippy'

import Truncate from '../../../../Shared/Truncate'
import SocialIcons from '../../../../Shared/SocialIcons'
import IconType from '../../../../Shared/IconType'
import axios from '../../../../../services/axios'
import storage from '../../../../../services/storage'

import Swiper from 'react-id-swiper'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../../../Shared/LoadingCharts'
import Title from '../../../../Shared/TitleChart'
import Reload from '../../../../Shared/ReloadChart'
import Error from '../../../../Shared/ErrorChart'
import './styles.css'

class Lists extends Component {
  constructor(props){
    super(props)

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
    this.lists = this.lists.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range, channel){
    if(!!channel){
      const since = moment(range[0]).format('YYYY-MM-DD')
      const until = moment(range[1]).format('YYYY-MM-DD')
      return storage.get('page')
      .then((page)=>{
        return axios.get(`${page}/analytics/google/score`, {
          params: {
            since,
            until,
            ...channel.data
          }
        })
      }).then((response)=>{
        const { rows } = response.data.data
        return rows || []
      })
    }else{
      return Promise.resolve([])
    }
  }

  lists(data){
    return (
      this.props.channel 
      ? <div className='googleSalesScoreListsContainer'>
          <p className='googleSalesScoreListsTitle'>{!!this.props.channel  ? this.props.channel.alias : ''}</p>
          <ul>
            {data.map((item, index)=>{
              return (
                <li>
                  {index + 1}. 
                  {item[0]}
                </li>
              )
            })}
          </ul>
        </div>
      : <div className='googleSalesScoreListsContainer'
          style={{
            backgroundImage: `url(${logoSmart})`
          }}
        />
    )
  }

  success({ response, load }){
    const data = response

    return (
      this.lists(data)
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.channel)
    })    

    return(
      <div className='googleSalesScoreLists'>
        <Chart loadOnMount>
          <Chart.Loading>
            {this.loading()}
          </Chart.Loading>
          <Chart.Success>
            {this.success}
          </Chart.Success>
          <Chart.Error>
            {this.error}
          </Chart.Error>
        </Chart>
      </div>
    )
  }
}

export default Lists
