import React, { Component } from 'react';
import { Card, Elevation, Spinner } from "@blueprintjs/core";

import SimpleBar from 'simplebar-react'
import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import { Colors } from '../../../config'
import Chart from '../../Shared/Chart'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class PagePath extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        columns: [],
        rows: [],
        serialized: [{}]
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.table = this.table.bind(this)
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:pageviews',
          dimensions: 'ga:pagePath',
          sort: '-ga:pageviews',
          maxResults: '10',
          filters: ''
        }
      })
    })
  }

  chart(data){
    const { serialized, columns, rows } = data

    const custom =  _.map(rows.slice(0, 6), (item, index)=>{
      return {
        'plot-rules': {
          rule: `%i==${index}`,
          backgroundColor: Colors[index]
        },
        'plot-labels': {
          rule: `%i==${index}`,
          borderColor: Colors[index],
          text: `<span style="font-weight:bold"><div style="width: 10px; height: 10px; background-color: ${Colors[index]}; display: inline-block"></div> ${item[0]}: ${numeral(item[1]).format('0,0[.]00')}</span>`
        }
      }
    })

    let config = {
      type: 'bar',
      backgroundColor: '#363737',
      gui: {
        behaviors: [{
          id: 'ViewSource',
          enabled: 'none'
        },{
          id: 'HideGuide',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'ViewAsPng',
          enabled: 'none'
        },{
          id: 'ViewDataTable',
          enabled: 'none'
        }]
      },
      globals: {
        fontFamily: 'Helvetica'
      },
      plotarea:{ 
        backgroundColor : '#363737',
        adjustLayout: true,
        margin: 0
      },
      scaleY: {
        lineStyle: 'solid',
        thousandsSeparator: ',',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF'
        }
      },
      scaleX: {
        values: _.range(1, 7),
        maxItems: 20,
        lineStyle: 'solid',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF'
        }
      },
      plot: {
        cursor: 'hand',
        rules: _.map(custom, 'plot-rules')
      },
      tooltip: {
        text: '%v',
        multiple: true,
        fontSize: '12px',
        color: '#FFFFFF',
        backgroundColor: '#000',
        borderWidth: 0,
        alpha: 0.6,
        callout: true,
        calloutPosition: 'bottom',
        shadow: 0,
        borderRadius: 4,
        rules: _.map(custom, 'plot-labels'),
        placement: 'node:top',
        calloutPosition: 'bottom',
        offsetY: 8
      },
      series: [{
        values: _.map(rows.slice(0, 6), (row)=>(row[1]))
      }]
    }

    return (
      <div>
        <Chart 
          width="100%" 
          height="230px"
          output="canvas"
          data={config} 
        />
      </div>
    )
  }

  table(data){
    const { serialized, columns, rows } = data
    return (
      <div className="googlePagePathTable">
        <table>
          <thead>
            <tr>
              {columns.map((column)=>{
                return (
                  <th>{column}</th>
                )
              })}
            </tr>
          </thead>
          <SimpleBar style={{ maxHeight: '126px' }}>
            <tbody>
              {rows.map((row, index)=>{
                return (
                  <tr>
                    <td>{index+1}. {row[0]}</td>
                    <td>{numeral(row[1]).format('0,0[.]00')}</td> 
                  </tr>
                )
              })}
            </tbody>
          </SimpleBar>
        </table>
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)
    const Table = this.table(data)

    return (
      <div>
        {Chart}
        {Table}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        id='googlePagePath'
        className="no-pad-t-b">
        <Title description='La url dentro del sitio que tuvo un mayor de visitas.' title='Page Path' />
        <div className="googlePagePathContainer">
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default  PagePath
