import React, { Component } from 'react'
import { Card, Elevation } from "@blueprintjs/core"

import moment from 'moment'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Languages extends Component {
	constructor(props){
		super(props)

		this.getData = this.getData.bind(this)
	}

	getData(){

	}

  render () {
    return(
      <Card 
      	interactive={false} 
      	elevation={Elevation.TWO} 
      	className="no-pad-t-b"
      	id='adsLanguage'>
        <Title title='Languages' />
        <h3 className='adsLanguageData'>
        	Use all LANGUAGES available
        </h3>
      </Card>

    )
  }

}

export default Languages
