import React, { Component } from 'react'
import _ from 'lodash'
import numeral from 'numeral'

class Metrics extends Component {
  constructor(props){
    super(props)
    this.state = {
      metrics: {
        type: 'er'
      }
    }

    this.onActive = this.onActive.bind(this)
    this.metricValue = this.metricValue.bind(this)
  }

  onActive(metrics){
    this.setState({
      metrics: metrics
    })
  }

  metricValue(metric){
    const { type, metrics } = this.state.metrics
    const { data } = this.props
    
    if(metric == 'er'){
      return numeral(data.er.value).format('0,0.0000')
    }else if(type != metric){
      return numeral(_.sumBy(_.values(data[metric]), 'value')).format('0,0.[00]')
    }else{
      return numeral(_.sumBy(metrics, (item)=>{
        return data[type][item].value
      })).format('0,0.[00]')
    }
  }

  render() {
    const { onActiveMetric, data, competitor, filter } = this.props

    return (
      <div className="socialAnalyticsPanelMetrics">
        <div className="socialAnalyticsPanelMetric">
          <div className="socialAnalyticsPanelCategories">
            <div 
              className='active'
              data-er 
              onClick={()=>(onActiveMetric('er'))}
              data-key='er'
            >
              <p>ENG COMP</p>
            </div>
          </div>
          <div className="socialAnalyticsPanelValue">
            <p>{this.metricValue('er')}</p>
          </div>
        </div>
        <div className="socialAnalyticsPanelMetric">
          <div className="socialAnalyticsPanelCategories">
            <div 
              onClick={()=>(onActiveMetric('organic', 'growth'))}
              data-key='growth.organic'
              data-only={!(filter == 'all' || filter == 'facebook')}>
              {(filter == 'all' || filter == 'facebook') ? (
                <p>Growth<br/>Organic</p>
              ) : (
                <p>Growth</p>
              )}
            </div>
            {(filter == 'all' || filter == 'facebook') && (
              <div 
                onClick={()=>(onActiveMetric('paid', 'growth'))}
                data-key='growth.paid'>
                <p>Growth<br/>Paid</p>
              </div>
            )}
          </div>
          <div className="socialAnalyticsPanelValue">
            <p>{this.metricValue('growth')}</p>
          </div>
        </div>
        <div className="socialAnalyticsPanelMetric">
          <div className="socialAnalyticsPanelCategories">
            <div 
              onClick={()=>(onActiveMetric('organic', 'posts'))}
              data-key='posts.organic'>
              <p>Posts<br/>Organic</p>
            </div>
            <div 
              onClick={()=>(onActiveMetric('paid', 'posts'))}
              data-key='posts.paid'>
              <p>Posts<br/>Paid</p>
            </div>
          </div>
          <div className="socialAnalyticsPanelValue">
            <p>{this.metricValue('posts')}</p>
          </div>
        </div>
        <div className="socialAnalyticsPanelMetric">
          <div className="socialAnalyticsPanelCategories">
            <div 
              onClick={()=>(onActiveMetric('organic', 'interactions'))}
              data-key='interactions.organic'>
              <p>Interactions<br/>Organic</p>
            </div>
            <div 
              onClick={()=>(onActiveMetric('paid', 'interactions'))}
              data-key='interactions.paid'>
              <p>Interactions<br/>Paid</p>
            </div>
          </div>
          <div className="socialAnalyticsPanelValue">
            <p>{this.metricValue('interactions')}</p>
          </div>
        </div>
        {((filter == 'all' || filter == 'facebook' || filter == 'instagram') && window.location.hostname == 'audi.smartintel.io') && (
          <div className="socialAnalyticsPanelMetric">
            <div className="socialAnalyticsPanelCategories">
              <div 
                onClick={()=>(onActiveMetric('organic', 'reach'))}
                data-key='reach.organic'>
                <p>Reach<br/>Organic</p>
              </div>
              <div 
                onClick={()=>(onActiveMetric('paid', 'reach'))}
                data-key='reach.paid'>
                <p>Reach<br/>Paid</p>
              </div>
            </div>
            <div className="socialAnalyticsPanelValue">
              <p>{this.metricValue('reach')}</p>
            </div>
          </div>
        )}
        <div className="socialAnalyticsPanelMetric">
          <div className="socialAnalyticsPanelCategories">
            <div 
              onClick={()=>(onActiveMetric('organic', 'impressions'))}
              data-key='impressions.organic'>
              <p>Impressions<br/>Organic</p>
            </div>
            <div 
              onClick={()=>(onActiveMetric('paid', 'impressions'))}
              data-key='impressions.paid'>
              <p>Impressions<br/>Paid</p>
            </div>
          </div>
          <div className="socialAnalyticsPanelValue">
            <p>{this.metricValue('impressions')}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Metrics
