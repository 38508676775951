import React, { Component } from 'react';
import { 
  Dialog,
  Switch
} from "@blueprintjs/core"
import _ from 'lodash'
import moment from 'moment'
import ContentLoader from 'react-content-loader'
import axios from '../../../../../services/axios'
import storage from '../../../../../services/storage'

import './styles.css'

class CompetitorsSettings extends Component {
  constructor(props){
    super(props)

    this.state = {
      open: false,
      search: '',
      competitors: [], 
      original: [],
      loading: false
    }

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.onUnSelectAll = this.onUnSelectAll.bind(this)
    this.onSelectAll = this.onSelectAll.bind(this)
    this.onChangeCompetitor = this.onChangeCompetitor.bind(this)
    this.onSave = this.onSave.bind(this)
  }

  onUnSelectAll(){
    let competitors = this.state.competitors
    competitors = competitors.map((competitor)=>{
      competitor.enabled = false

      return competitor
    }) 

    this.setState({
      competitors: competitors
    })
  }

  onSelectAll(){
    let competitors = this.state.competitors
    competitors = competitors.map((competitor)=>{
      competitor.enabled = true

      return competitor
    }) 

    this.setState({
      competitors: competitors
    })
  }

  onSave(){
    const { competitors, original } = this.state

    const changes = _.filter(competitors, (competitor,  index)=>(competitor.enabled != original[index].enabled))
    
    if(changes.length > 0){
      const requests = _.map(changes, (change)=>{
        const data = {
          provider_type: change.provider_type,
          provider_id: change.provider_id
        }

        return change.enabled ? axios.put('sales/competitors/enable', data) : axios.put('sales/competitors/disable', data)
      })

      Promise.all(requests)
      .then((responses)=>{
        this.hide()
      }).catch((error)=>{
        console.warn('error: ',  error)
        this.hide()
      })
    }
  }

  onChangeCompetitor(event, id){
    let competitors = this.state.competitors
    competitors[id].enabled = !competitors[id].enabled

    this.setState({
      competitors: competitors
    })    
  }

  show(){
    this.setState({
      open: true,
      loading: true
    }, ()=>{
      axios.get('/sales/brandsCompetitors')
      .then((competitors)=>{
        const newCompetitors = _.map(competitors.data.data, (competitor, index)=>{
          return {
            ...competitor,
            id: index
          }
        })

        this.setState({
          loading: false,
          competitors: newCompetitors,
          original: competitors.data.data
        })
      }).catch((error)=>{
        this.setState({
          loading: false
        })
        console.warn('error: ', error)
      })
    })
  }

  hide(){
    this.setState({
      open: false,
      search: '',
      competitors: [],
      original: [],
      loading: false
    })
  }

  render() {
    const competitors = _.filter(this.state.competitors, (competitor)=>{
      const search = this.state.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      const name = competitor.provider_id.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      return search == '' ? true : name.indexOf(search) > -1
    })

    return (
      <div>
        <Dialog
          {...this.props}
          isOpen={this.state.open}
          backdropProps={{
            onClick: this.hide,
            style: {
              cursor: 'pointer'
            }
          }}
          className='competitorsSettings'
        >
          <div className='competitorsContent'>
            <div className='competitorsHeader'>
              <p className='competitorsHeaderTitle'>
                Competitors
              </p>
              <div className='competitorsHeaderSearch'>
                <input 
                  onChange={(event)=>(this.setState({search: event.target.value}))}
                />
                <button
                  onClick={this.onSelectAll}  
                  className='competitorsHeaderButtom'
                >
                  Select All
                </button>
                <button
                  onClick={this.onUnSelectAll} 
                  className='competitorsHeaderButtom'
                >
                  Unselect All
                </button>
              </div>
            </div>
            <div className='competitorsBody'>
              {competitors.map((competitor, index)=>{
                return (
                  <div className='competitorListItem'>
                    <Switch
                      className='competitorListItemSwitch'
                      checked={competitor.enabled} 
                      label={(
                        <div className="competitorListItemLabel">
                          <div style={{backgroundImage: `url(${competitor.avatar})`}} />
                          <p>{competitor.provider_id} ({competitor.provider_type})</p>
                        </div>
                      )} 
                      onChange={(event)=>(this.onChangeCompetitor(event, competitor.id))} 
                    />
                  </div>
                )
              })}
              {this.state.loading && (
                <div>
                  <ContentLoader 
                    height={160}
                    width={400}
                    speed={2}   
                    primaryColor="#7b7b7b"
                    secondaryColor="#363737"
                  >
                    {_.range(0, 7).map((item)=>{
                      return (
                        <rect x="0" y={20 * item} rx="5" ry="5" width="28" height="12" />
                      )
                    })}
                    {_.range(0, 7).map((item)=>{
                      return (
                        <rect x="36" y={(20 * item) + 2} rx="5" ry="5" width="354" height="10" />
                      )
                    })}
                  </ContentLoader>
                </div>
              )}
            </div>
            <div className='competitorsFotter'>
              <button 
                onClick={this.onSave}
                className='competitorsFotterSave'
              >
                Save
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default CompetitorsSettings
