import React, { Component } from 'react'
import { Colors } from '../../../config'
import Color from 'color'
import axios from '../../../services/axios'
import storage from '../../../services/storage'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"
import numeral from 'numeral'
import moment from 'moment'
import Chart from '../../Shared/Chart'
import _ from 'lodash'
import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

const icons = {
  'Avg. Domain Lookup Time (sec)': <i className='fas fa-search' />,
  'Avg. Page Download Time (sec)': <i className='fas fa-cloud-download-alt' />,
  'Avg. Page Load Time (sec)': <i className='far fa-dot-circle' />,
  'Avg. Server Connection Time (sec)': <i className='fas fa-server' />,
}

class FrequencyQuality extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: {
        frequency: {
          columns: [],
          rows: [],
          serialized: [{}]
        },
        sessions: {
          columns: [],
          rows: [],
          serialized: [{}]
        }
      }
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.sessions = this.sessions.bind(this)
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  secondsToTime(s){
    s = s * 1000
    const pad = (n, z)=>{
      z = z || 2;
      return ('00' + n).slice(-z);
    }
  
    let ms = s % 1000;
    s = (s - ms) / 1000;
    let secs = s % 60;
    s = (s - secs) / 60;
    let mins = s % 60;
    let hrs = (s - mins) / 60;
    
    return pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      const sessions = axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:sessions'
        }
      })

      const frequency = axios.get(`${page}/analytics/google/query`, {
        params: {
          since,
          until,
          metrics: 'ga:sessions',
          dimensions: 'ga:sessionCount',
          sort: '-ga:sessions',
          maxResults: '100'
        }
      })

      return Promise.all([frequency, sessions])
    }).then((response)=>{
      return {
        frequency: response[0].data.data,
        sessions: response[1].data.data
      }
    })
  }

  chart(data){
    const { serialized, columns, rows } = data.frequency
    const sessions = data.sessions.rows[0]
    const ranges = [[0,1],[1,5],[5,26],[26,50],[50,100]]

    const frequencies = _.map(ranges, function(range, index){
      const sessionsFrecuency = serialized.slice(range[0], range[1])
      const total = _.sumBy(sessionsFrecuency, 'Sessions');
      
      return {
        value: total,
        range: (range[0]+1) + ' - ' + range[1]
      }
    })

    return (
      <div className="googleFrequencyQualityItems">
        <div className="googleFrequencyQualityItemsTitles">
          <p>Transactions</p>
          <p>No transactions</p>
        </div>
        {frequencies.map((frequency, index)=>{
          return (
            <div className="googleFrequencyQualityItem">
              <div className="googleFrequencyQualityItemRange">
                <p>{frequency.range}</p>
              </div>
              <div className="googleFrequencyQualityItemChart">
                <div style={{
                  width: `${Math.round((100 / sessions) * frequency.value)}%`
                }}>
                 {Math.round((100 / sessions) * frequency.value)}%
                </div>
                <div style={{
                  width: `${Math.round((100 - ((100 / sessions) * frequency.value)))}%`
                }}>
                  {Math.round((100 - ((100 / sessions) * frequency.value)))}%
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  sessions(data){
    return (
      <div className="googleFrequencyQualitySessions">
        <p className="googleFrequencyQualitySessionsTitle">Sessions</p>
        <p className="googleFrequencyQualitySessionsValue">{numeral(data.sessions.rows[0]).format('0,0.[0000]')}</p>
      </div>
    )
  }

  success({ response, load }){
    const data = response
    const Chart = this.chart(data)
    const Sessions = this.sessions(data)

    return (
      <div>
        {Sessions}
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id='googleFrequencyQuality'>
        <Title description='Calidad del numero de sesiones, basados en las compras logradas dentro del sitio.' title='Frequency Quality' />
        <div className="googleFrequencyQualityContainer">
         <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )

  }
}

export default FrequencyQuality
