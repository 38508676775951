import React, { Component } from 'react'
import { 
  Spinner,
  Dialog,
  Slider
} from "@blueprintjs/core"
import Loading from '../../../Shared/LoadingCharts'
import axios from '../../../../services/axios'
import storage from '../../../../services/storage'

import './styles.css'

class NewWeek extends Component {
    constructor(props){
      super(props)
      this.state = {
        visible: false,
        weeks: 1,
        loading: false
      }

      this.onAccept = this.onAccept.bind(this)
      this.onCancel = this.onCancel.bind(this)
      this.show = this.show.bind(this)
      this.hide = this.hide.bind(this)
    }

    show(){
      this.setState({
        visible: true
      })
    }

    hide(){
      this.setState({
        visible: false,
        loading: false
      })
    }


    onAccept(){
      storage.get('page')
      .then((page)=>{
        return axios.post(`/calendar/${page}/weeks`, {
          weeks: this.state.weeks
        })
      }).then((response)=>{
        this.hide()
        this.props.onAccept && this.props.onAccept()
      }).catch((error)=>{
        this.setState({
          loading: false
        })
      })
    }

    onCancel(){
      this.hide()
      this.props.onCancel && this.props.onCancel()
    }

    render() {
      return(
        <Dialog
          isOpen={this.state.visible}
          className='NewWeek'
        >
          <div className='NewWeekContainer'>
            <div className="bp3-dialog-header NewWeekHeader">
              <h4 className="bp3-heading">
                Select the number of weeks to add:
              </h4>
            </div>
            <div className="bp3-dialog-body">
              <Slider
                min={1}
                max={4}
                stepSize={1}
                labelStepSize={10}
                value={this.state.weeks}
                onChange={(value)=>(this.setState({
                  weeks: value
                }))}
              />
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <button
                  onClick={()=>{
                    this.setState({
                      loading: true
                    }, this.onAccept)
                  }} 
                  type="button" 
                  className="bp3-button NewWeekAccept">
                  Accept
                </button>
                <button
                  onClick={this.onCancel} 
                  type="button" 
                  className="bp3-button NewWeekCancel">
                  Cancel
                </button>
              </div>
            </div>
            {this.state.loading && (
              <div className="newWeekLoading">
                <Loading />
              </div>
            )}
          </div>
        </Dialog>
      )
    }
}

export default NewWeek
