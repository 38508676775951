import React, { Component } from 'react'
import { H1 } from "@blueprintjs/core"

//COMPONENTES
import RetailInvestment from '../RetailInvestment'
import RetailTraffic from '../RetailTraffic'
import RetailGross from '../RetailGross'

import './styles.css'

class MainContainer extends Component {
  render () {
    return(
      <div className="retailContainer" data-html2canvas-print>
        <div className="top-container">
          <div className="brands-added">
            <div className="grid">
              <div className="col-5_sm-12">
                <H1 className="brands-added__title" data-html2canvas-ignore>Retail Intelligence</H1>
              </div>
              <div className="col-7_sm-12">
              </div>
            </div>
          </div>
        </div>
        <div className="social-intelligence__container" id="MainContainer">
          <div className="grid">
            <div className="col-12">
              <RetailInvestment 
                id={this.props.id}
              />
            </div> 
            <div className="col-12">
              <RetailTraffic 
                id={this.props.id}
              />
            </div>
            <div className="col-12">
              <RetailGross
                id={this.props.id}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default MainContainer
