import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"

import { Colors } from '../../../config'
import Color from 'color'
import Chart from '../../Shared/Chart'
import Details from './components/Details'

import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'
import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class RetailInvestment extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.onChangeDetails = this.onChangeDetails.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    return storage.get('page')
    .then((page)=>{
      return axios.get(`retail/${page}/traffic`)
    })
  }

  onChangeDetails(node, data){
    this.details.setData(data[node.nodeindex])
  }

  chart(data){
    const range = _.range(3)
    const custom =  _.map(range, (item, index)=>{
      return {
        'plot-rules': {
          rule: `%p==${index}`,
          lineColor: Colors[index]
        },
        'marker-rules': {
          rule: `%p==${index}`,
          borderColor: Colors[index],
          backgroundColor: Colors[index]
        },
        'plot-labels': {
          rule: `%p==${index}`,
          borderColor: Colors[index],
          text: `<span style="font-weight:bold"><div style="width: 10px; height: 10px; background-color: ${Colors[index]}; display: inline-block"></div> %v</span>`
        }
      }
    })

    let config = {
      type: 'line',
      backgroundColor: '#2A2A2A',
      gui: {
        behaviors: [{
          id: 'ViewSource',
          enabled: 'none'
        },{
          id: 'HideGuide',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'ViewAsPng',
          enabled: 'none'
        },{
          id: 'ViewDataTable',
          enabled: 'none'
        }]
      },
      globals: {
        fontFamily: 'Helvetica'
      },
      plotarea:{ 
        backgroundColor : '#2A2A2A',
        adjustLayout: true,
        margin: 0
      },
      scaleY: {
        lineStyle: 'solid',
        thousandsSeparator: ',',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          fontSize: '0px'
        }
      },
      scaleX: {
        values: _.map(data, (item)=>(`W${item.week}:Y${item.year}`)),
        lineStyle: 'solid',
        lineColor: '#4a4a4a',
        lineWidth: '.5px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF'
        }
      },
      plot: {
        cursor: 'hand',
        marker: {
          size: 4,
          rules: _.map(custom, 'marker-rules')
        },
        rules: _.map(custom, 'plot-rules')
      },
      tooltip: {
        text: '%v',
        multiple: true,
        fontSize: '12px',
        color: '#FFFFFF',
        backgroundColor: '#000',
        borderWidth: 0,
        alpha: 0.6,
        callout: true,
        calloutPosition: 'bottom',
        shadow: 0,
        borderRadius: 4,
        rules: _.map(custom, 'plot-labels'),
        placement: 'node:top',
        calloutPosition: 'bottom',
        thousandsSeparator: ',',
        offsetY: 8
      },
      series: [{
        values: _.map(data, 'traffic_online_user')
      },
      {
        values: _.map(data, 'traffic_store_traffic')
      }]
    }

    return (
      <div className="retailChartContainer">
        <Chart 
          width="100%" 
          height="318px"
          output="canvas"
          events={{
            node_click: (node)=>(this.onChangeDetails(node, data))
          }}
          data={config} 
        />
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data.weeks)

    return (
      <div>
        <div className="retailInvestmentData">
          <Details 
            ref={(details)=>(this.details = details)}
            data={data.total}
          />
          {Chart}
        </div>
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="retailInvestment">
        <Title title='Traffic' />
        <div className='retailInvestmentContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default RetailInvestment
