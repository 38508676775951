import React, { Component } from 'react'
import { Card, Elevation, Spinner } from '@blueprintjs/core'

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import logo from '../../../assets/img/logo.png'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class Placement extends Component {
  constructor(props){
    super(props)

    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)

    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range){
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')
    
    return storage.get('page')
    .then((page)=>{
      return axios.get(`https://adsapi.smartintel.io/api/ads/placement/${page}?since=${since}&until=${until}&domain=${window.location.hostname}&campaings=${this.props.campaings}`)
    })
  }

  chart(data){
    const icons = {
      facebook: <i className="fab fa-facebook-f" />,
      messenger: <i className="fab fa-facebook-messenger" />,
      instagram: <i className="fab fa-instagram" />,
      audience_network: <i className="fas fa-users" />
    }

    return (
      data.map((placement, index)=>{
        return (
          <div className="adsPlacementItem">
            <div className={`adsPlacementIcon adsPlacement${placement.name}`}>
              {icons[placement.name]}
            </div>
            <p className="adsPlacementValue">
              {placement.value} ADs
            </p>
          </div>
        )
      })
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)

    return (
      <div>
        {Chart}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }

  render() {
    const Chart = createLoader({
      load: () => this.getData(this.props.range)
    })

    return (
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="adsPlacement">
        <Title title='Placement' />
         <div className='adsPlacementContainer'>
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default Placement
