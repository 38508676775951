import React, { Component } from 'react'
import { Card, Elevation, Tooltip, Position } from "@blueprintjs/core"
import { connect } from 'react-redux'

class Goal extends Component {

  render () {

    return(

        <Card interactive={true} elevation={Elevation.TWO} className="no-pad-t-b">
					<div className="subtitle">
						Goal #1

						<div className="question-tooltip question-tooltip__right">
	            <Tooltip content="Goal Tooltip" position={Position.BOTTOM}>
	              <div><i className="fas fa-question"></i></div>
	            </Tooltip>
	          </div>
					</div>

          <div>
            <div className="category-rank__item" style={{ backgroundColor: '#CF3', color: '#2A2A2A' }}>

              <div className="category-rank__container">

                <div className="category-rank__item--data">
                  $0
                </div>
                <div className="category-rank__item--" style={{ color: '#2A2A2A' }}>Goal Value</div>

              </div>

            </div>

            <div className="category-rank__item" style={{ backgroundColor: '#e2474f' }}>

              <div className="category-rank__container">

                <div className="category-rank__item--data">
                  0
                </div>
                <div className="category-rank__item--">Goal Completions</div>

              </div>

            </div>

            <div className="category-rank__item" style={{ backgroundColor: '#4fabe6' }}>

              <div className="category-rank__container">

                <div className="category-rank__item--data">
                  $0
                </div>
                <div className="category-rank__item--">Goal Conversion Rate</div>

              </div>

            </div>

            <div className="category-rank__item" style={{ backgroundColor: '#3cc453' }}>

              <div className="category-rank__container">

                <div className="category-rank__item--data">
                  0
                </div>
                <div className="category-rank__item--">Goal Abandoned Funnels</div>

              </div>

            </div>
          </div>


        </Card>

    );
  }
}

function mapStateToProps( state )
{
  return {

  }
}

export default connect(mapStateToProps)( Goal );
