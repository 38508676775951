import store from 'store'
import moment from 'moment'

const _resolve = Promise.resolve()

const set = (key, value) => {
	return _resolve.then(()=>{
    if(key == 'page'){
      let meta = document.querySelector('meta[name="page"]')
      !!meta && (meta.content = value)
    }else{
      return store.set(key, value)
    }
  })
}

const get = (key) => {
	return new Promise((resolve, reject) => {
   const data = store.get(key)

   if(key == 'page'){
      let meta = document.querySelector('meta[name="page"]')
      !!meta ? resolve(meta.content) : reject(null)
    }else{
      if(!!data){
        resolve(data)
      }else{
        reject(null)
      }
    }
  })
}

const remove = (key) => {
  return _resolve.then(()=>{
    return store.remove(key)
  })
}

const clear = () => {
	return _resolve.then(()=>{
    return store.clearAll()
  })
}

const token = (name = 'auth_token') =>{
  return _resolve.then(()=>{
    return store.get(name)
  })
}

const range = (name = 'range') =>{
  return _resolve.then(()=>{
    return store.get(name)
  }).then((range)=>{
    const days =  window.location.hostname == 'apple.smartintel.io' ? 45 : 14
    return !!range ? range : set('range', [
      moment().subtract(days, 'days').startOf('day').toDate(),
      moment().startOf('day').toDate()
    ])
  })
}


const expiration = (name = 'expiration') =>{
  return _resolve.then(()=>{
    return store.get(name)
  })
}

export default {
  set,
  get,
  range,
  remove,
  clear,
  token,
  expiration
}
