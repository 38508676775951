import React, { Component } from 'react'
import { Icon, Elevation } from "@blueprintjs/core"
import is from 'is_js'

import Alert from '../../../../Shared/Alert'

import axios from '../../../../../services/axios'
import storage from '../../../../../services/storage'

import './styles.css'

class Section extends Component {
  constructor(props){
    super(props)

    this.deleteWord = {}

    this.onAddWord = this.onAddWord.bind(this)
    this.onDeleteSection = this.onDeleteSection.bind(this)
    this.onDeleteWord = this.onDeleteWord.bind(this)
  }

  onDeleteSection(id){
    storage.get('page')
    .then((page)=>{
      return axios.delete(`${page}/palabras/categoria/${id}`)
    }).then((response)=>{
      this.props.load()
    }).catch((error)=>{
      
    })
  }

  onDeleteWord(category, id){
    storage.get('page')
    .then((page)=>{
      return axios.delete(`${page}/palabras/categoria/${category}/palabras/${id}`)
    }).then((response)=>{
      this.props.load()
    }).catch((error)=>{
      
    })
  }

  onAddWord(category, value){
    storage.get('page')
    .then((page)=>{
      return axios.post(`${page}/palabras/categoria/${category}/palabras`, {
        word: value
      })
    }).then((response)=>{
      this.props.load()
    }).catch((error)=>{
      
    })
  }

  render() {
    return(
      <div className="socialTopicDataItem">
        <div className="socialTopicDataItemInfo">
          <p className="socialTopicDataName">{this.props.nombre}</p>
          <p className="socialTopicDataCount">COUNT: {this.props.count}</p>
        </div>
        <div className="socialTopicDataItemForm">
          <div className="socialTopicDataItemFormInput">
            <input type='text' placeholder='New Word' ref={(word)=>(this.word = word)} />
          </div>
          <div className="socialTopicDataItemFormAdd">
            <i 
              className="fas fa-plus-circle" 
              onClick={()=>{
                const { value } = this.word
                !is.empty(value.replace(/\s+/g, '')) && this.onAddWord(this.props.id, value)
              }}
            />
          </div>
        </div>
        <div className="socialTopicDataItemWords">
           {this.props.words.map((word)=>{
            return (
              <div key={word.id} className="socialTopicDataWord">
                <div className="socialTopicDataWordName">
                  {word.palabra}
                </div>
                <div className="socialTopicDataWordCount">
                  {word.count}
                </div>
                <div className="socialTopicDataWordDelete">
                  <i 
                    className="fas fa-times" 
                    onClick={()=>{
                      this.deleteWord.show()
                      .then(()=>(this.onDeleteWord(this.props.id, word.id)))
                    }} 
                  />
                </div>
                  
              </div>
            )
           })}
        </div>
        <div className="socialTopicDataItemDelete">
          <i 
            className="fas fa-times" 
            onClick={()=>(this.deleteSection.show())} 
          />
        </div>
        <Alert 
          body="Do you want to delete this category?"
          ref={(alert)=>(this.deleteSection = alert)}
          onAccept={()=>(this.onDeleteSection(this.props.id))}
        />
        <Alert 
          body="Do you want to delete this word?"
          ref={(alert)=>(this.deleteWord = alert)}
        />
      </div>
    )
  }
}

export default Section
