import React, { Component } from 'react'
class IconType extends Component {
  render () {
    switch(this.props.type) {
      case 'IMAGE':
      case 'PHOTO':
      case 'PICTURE':
        return (<i {...this.props} className="fas fa-image" />)
        break
      case 'ALBUM':
        return (<i {...this.props} className="fas fa-images" />)
        break
      case 'VIDEO':
        return (<i {...this.props} className="fas fa-video" />)
        break
      case 'CAROUSEL_ALBUM':
        return (<i {...this.props} className="fas fa-th" />)
        break
      case 'MUSIC':
        return (<i {...this.props} className="fas fa-music" />)
        break
      case 'VIDEO_STREAM':
        return (<i {...this.props} className="fas fa-gamepad" />)
        break
      case 'STATUS':
      case 'RICH':
      case 'TEXT':
        return (<i {...this.props} className="fas fa-font" />)
        break
      case 'LINK':
        return (<i {...this.props} className="fas fa-link" />)
        break
      case 'OFFER':
        return (<i {...this.props} className="fas fa-tag" />)
        break
      case 'ARTICLE':
        return (<i {...this.props} className="far fa-newspaper" />)
        break
      case 'EVENT':
        return (<i {...this.props} className="fas fa-calendar-alt"/>)
        break
      case 'NOTE':
        return (<i {...this.props} className="fas fa-sticky-note"/>)
        break
      case 'STORY_VIDEO':
      case 'STORY_IMAGE':
        return (<i {...this.props} className="far fa-hourglass" />)
        break
      default:
        return (<i {...this.props} />)
        break
    }
  }
}

export default IconType