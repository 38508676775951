import { FETCH_CATS } from '../actions/types'
const initialState = {
  categories: []
}

const categories = (state=initialState, action)=>{
  switch(action.type){
    case FETCH_CATS:
      return {
        ...state,
        categories: action.payload
      }
  }

  return state
}

export default categories
