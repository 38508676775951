import React, { Component } from 'react'
import { Card, Elevation, Position, H5, H6  } from "@blueprintjs/core"

import moment from 'moment'

import axios from '../../../services/axios'
import storage from '../../../services/storage'
import Panels from './components/Panels'
import _ from 'lodash'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class CommunicationStrategy extends Component {
  constructor(props){
    super(props)
    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
    this.getData = this.getData.bind(this)
  }

  getData(range, filter){
    const { competitor } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      return Promise.all([
        axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/comunicationStrategy/${page}?since=${since}&until=${until}&provider=${filter}`),
        axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/comunicationStrategyPromotions/${page}?since=${since}&until=${until}&provider=${filter}`),
        axios.get(`content/${page}/topFormats?since=${since}&until=${until}&is_brand=${competitor == 'brand'}&provider=${filter}`)
      ])
    }).then((response)=>{
      let panelCategories = response[0].data.data
      panelCategories =  panelCategories.length > 0 ? panelCategories : [{
        posts: [],
        category: {}
      }]

      let panelPromotions = response[1].data.data
      panelPromotions =  panelPromotions.length > 0 ? panelPromotions : [{
        posts: [],
        categories: [],
        promotion: {}
      }]

      let panelFormats = response[2].data.data
      panelFormats = panelFormats.length > 0 ? panelFormats : [{
        posts: [],
        name: ''
      }]

      return {
        categories: panelCategories,
        promotions: panelPromotions,
        formats: panelFormats
      }
    })
  }

  success({ response, load }){
    const { competitor } = this.props
    const panel = this.inputs.querySelector('input[type=radio]:checked').value
    
    return (
      <Panels 
        ref={(panels)=>(this.panels = panels)}
        competitor={competitor}
        onSelect={this.props.onSelectCategory}
        defaultPanel={panel}
        data={response} 
      />
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }


  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialStrategy">
        <Title 
          left={(
            <div 
              ref={(inputs)=>(this.inputs = inputs)}
              className="socialStrategySwitch">
              <label 
                onClick={()=>(this.panels.onSwitch('categories'))}>
                <input
                  defaultChecked={true}
                  type="radio" 
                  name="socialStrategyPanel" 
                  value="categories" />
                  <p>Categories</p>
              </label>
              <label
                onClick={()=>(this.panels.onSwitch('promotions'))}>
                <input 
                  type="radio" 
                  name="socialStrategyPanel"
                  value="promotions" />
                  <p>Promotions</p>
              </label>
              <label
                onClick={()=>(this.panels.onSwitch('formats'))}>
                <input 
                  type="radio" 
                  name="socialStrategyPanel"
                  value="formats" />
                  <p>Formats</p>
              </label>
            </div>
          )} 
          description='Categorías organizadas de mejor a peor Performance Comparativa de los mejores posts por categoría y saber el Engagement Rate de cada Categoria.' 
          title='Communication Strategy' 
        />
        <div
          className='socialStrategyContainer'
          ref={(ref)=>(this.chartContainer = ref)}
        >
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default CommunicationStrategy
