import React, { Component } from 'react'
import { Button, H1 } from "@blueprintjs/core"
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import _ from 'lodash'
import { RangeConfig } from '../../../config'
import storage from '../../../services/storage'

// COMPONENTES
import PageActivity from '../PageActivity/PageActivity'

import Visitors from '../Visitors/Visitors'
import VisitorsSessions from '../VisitorsSessions/VisitorsSessions'
import VisitorsDuration from '../VisitorsDuration/VisitorsDuration'
import VisitorsSS from '../VisitorsSS'

import Gender from '../Gender/Gender'
import GenderSessions from '../GenderSessions/GenderSessions'
import GenderDuration from '../GenderDuration/GenderDuration'

import Age from '../Age/Age'
import AgeSession from '../AgeSession/'
import AgeDuration from '../AgeDuration/'

import Language from '../Language/'
import LanguageSession from '../LanguageSession/'
import LanguageDuration from '../LanguageDuration/'

import Location from '../Location/'
import Browser from '../Browser/'
import Devices from '../Devices/'
import Brand from '../Brand/'

import SiteSpeed from '../SiteSpeed/'
import Frequency from '../Frequency/'
import FrequencyQuality from '../FrequencyQuality'
import PagePath from '../PagePath/'

import BroadAudience from '../BroadAudience/'
import SpecificAudience from '../SpecificAudience/'
import GranularAudience from '../GranularAudience/'

import Conversion from '../Conversion/'

import SalesScore from '../SalesScore/'

import SourceMedium from '../SourceMedium'
import SearchTerm from '../SearchTerm'
import Campaign from '../Campaign'

import GenderSS from '../GenderSS'
import AgeSS from '../AgeSS'
import Region from '../Region'

import Goal from '../Goal'
import Journey from '../Journey'

import 'bootstrap-daterangepicker/daterangepicker.css'
import './styles.css'

class MainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      range: [
        moment(this.props.range[0]).startOf('day').toDate(),
        moment(this.props.range[1]).startOf('day').toDate()
      ]
    } 

    this.range = [
      moment(this.props.range[0]).startOf('day').toDate(),
      moment(this.props.range[1]).startOf('day').toDate()
    ]

    this.components = {}
    this.onSearch = this.onSearch.bind(this)
    this.onGetData = this.onGetData.bind(this)
    this.onChangeRange = this.onChangeRange.bind(this)
  }

  onSearch(){
    this.onGetData()
  }

  onGetData(){
    this.setState({
      range: this.range
    })
  }

  onChangeRange({startDate, endDate}){
    const range = [startDate.toDate(), endDate.toDate()]

    this.dateStart.innerHTML = moment(range[0]).format('YYYY-MM-DD')
    this.dateEnd.innerHTML = moment(range[1]).format('YYYY-MM-DD')

    storage.set('range', range)
    
    this.range = range
  }

  render () {
    return(
      <div className="webContainer" data-html2canvas-print>
        <div className="top-container">
          <div className="brands-added">
            <div className="grid">
              <div className="col-5_sm-12">
                <H1 className="brands-added__title" data-html2canvas-ignore>Web Intelligence</H1>
              </div>
              <div className="col-7_sm-12">
                <div className="searchContainer" data-html2canvas-ignore>
                  <div className="dateSmart bp3-dark">
                    <span className="searchCalendarIcon"><i className="far fa-calendar-alt"></i></span>
                    <DateRangePicker
                    {...RangeConfig} 
                    startDate={this.state.range[0]} 
                    endDate={this.state.range[1]}
                    onApply={(event, picker)=>(this.onChangeRange(picker))}>
                      <div className="daterangepickerDates">
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateStart = ref)}>
                          {moment(this.state.range[0]).format('YYYY-MM-DD')}
                        </span>
                        <span className="daterangepickerDate" ref={(ref)=>(this.dateEnd = ref)}>
                          {moment(this.state.range[1]).format('YYYY-MM-DD')}
                        </span>
                      </div>
                    </DateRangePicker>
                    <Button onClick={this.onSearch} minimal="true" className="button btn-outline" >Search</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="social-intelligence__container" id="MainContainer">
          <div className="grid" id="audience">
            <div className="col-12">
              <PageActivity
                range={this.state.range} 
                ref={(pageActivity)=>(pageActivity && (this.components['pageActivity'] = pageActivity))}
              />
            </div>
            <div className="col-4_sm-12">
              <Visitors
                range={this.state.range} 
                ref={(visitors)=>(visitors && (this.components['visitors'] = visitors))}
              />
            </div>
            <div className="col-4_sm-12">
              <VisitorsSessions
                range={this.state.range} 
                ref={(visitorsSessions)=>(visitorsSessions && (this.components['visitorsSessions'] = visitorsSessions))}
              />
            </div>
            <div className="col-4_sm-12">
              <VisitorsDuration
                range={this.state.range} 
                ref={(visitorsDuration)=>(visitorsDuration && (this.components['visitorsDuration'] = visitorsDuration))}
              />
            </div>
            <div className="col-4_sm-12">
              <Gender
                range={this.state.range} 
                ref={(gender)=>(gender && (this.components['gender'] = gender))}
              />
            </div>
            <div className="col-4_sm-12">
              <GenderSessions
                range={this.state.range} 
                ref={(genderSessions)=>(genderSessions && (this.components['genderSessions'] = genderSessions))}
              />
            </div>
            <div className="col-4_sm-12">
              <GenderDuration
                range={this.state.range} 
                ref={(genderDuration)=>(genderDuration && (this.components['genderDuration'] = genderDuration))}
              />
            </div>
            <div className="col-4_sm-12">
              <Age
                range={this.state.range} 
                ref={(age)=>(age && (this.components['age'] = age))}
              />
            </div>
            <div className="col-4_sm-12">
              <AgeSession
                range={this.state.range} 
                ref={(ageSession)=>(ageSession && (this.components['ageSession'] = ageSession))}
              />
            </div>
            <div className="col-4_sm-12">
              <AgeDuration
                range={this.state.range} 
                ref={(ageDuration)=>(ageDuration && (this.components['ageDuration'] = ageDuration))}
              />
            </div>
            <div className="col-4_sm-12">
              <Language
                range={this.state.range} 
                ref={(language)=>(language && (this.components['language'] = language))}
              />
            </div>
            <div className="col-4_sm-12">
              <LanguageSession
                range={this.state.range} 
                ref={(languageSession)=>(languageSession && (this.components['languageSession'] = languageSession))}
              />
            </div>
            <div className="col-4_sm-12">
              <LanguageDuration
                range={this.state.range} 
                ref={(languageDuration)=>(languageDuration && (this.components['languageDuration'] = languageDuration))}
              />
            </div>
            <div className="col-4_sm-12">
              <Location
                range={this.state.range} 
                ref={(location)=>(location && (this.components['location'] = location))}
              />
            </div>
            <div className="col-4_sm-12 no-pad-b">
              <div className="col-12">
                <div className="best-days-shell">
                  <div className="grid">
                    <div className="col-12 no-pad-l-f">
                      <Devices
                        range={this.state.range} 
                        ref={(devices)=>(devices && (this.components['devices'] = devices))}
                      />
                    </div>
                    <div className="col-12 no-pad-l-f no-pad-b">
                      <Brand
                        range={this.state.range} 
                        ref={(brand)=>(brand && (this.components['brand'] = brand))}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4_sm-12">
              <Browser
                range={this.state.range} 
                ref={(browser)=>(browser && (this.components['browser'] = browser))}
              />
            </div>
            <div className="col-4_sm-12">
              <SiteSpeed
                range={this.state.range} 
                ref={(siteSpeed)=>(siteSpeed && (this.components['siteSpeed'] = siteSpeed))}
              />
            </div>
            <div className="col-4_sm-12">
              <Frequency
                range={this.state.range} 
                ref={(frequency)=>(frequency && (this.components['frequency'] = frequency))}
              />
            </div>
            <div className="col-4_sm-12">
              <PagePath
                range={this.state.range} 
                ref={(pagePath)=>(pagePath && (this.components['pagePath'] = pagePath))}
              />
            </div>
            <div className="col-4_sm-12">
              <BroadAudience
                range={this.state.range} 
                ref={(broadAudience)=>(broadAudience && (this.components['broadAudience'] = broadAudience))}
              />
            </div>
            <div className="col-4_sm-12">
              <SpecificAudience
                range={this.state.range} 
                ref={(specificAudience)=>(specificAudience && (this.components['specificAudience'] = specificAudience))}
              />
            </div>
            <div className="col-4_sm-12">
              <GranularAudience
                range={this.state.range} 
                ref={(granularAudience)=>(granularAudience && (this.components['granularAudience'] = granularAudience))}
              />
            </div>
          </div>
          <div className="grid" id="conversion">
            <div className="col-12">
              <Conversion
                range={this.state.range} 
                ref={(conversion)=>(conversion && (this.components['conversion'] = conversion))}
              />
            </div>
            <div className="col-12">
              <SalesScore
                range={this.state.range} 
                ref={(salesScore)=>(salesScore && (this.components['salesScore'] = salesScore))}
              />
            </div>
            <div className="col-4_sm-12">
              <SourceMedium
                range={this.state.range} 
                ref={(sourceMedium)=>(sourceMedium && (this.components['sourceMedium'] = sourceMedium))}
              />
            </div>
            <div className="col-4_sm-12">
              <SearchTerm
                range={this.state.range} 
                ref={(searchTerm)=>(searchTerm && (this.components['searchTerm'] = searchTerm))}
              />
            </div>
            <div className="col-4_sm-12">
              <Campaign
                range={this.state.range} 
                ref={(campaign)=>(campaign && (this.components['campaign'] = campaign))}
              />
            </div>
            <div className="col-4_sm-12">
              <GenderSS
                range={this.state.range} 
                ref={(genderSS)=>(genderSS && (this.components['genderSS'] = genderSS))}
              />
            </div>
            <div className="col-4_sm-12">
              <AgeSS 
                range={this.state.range}
                ref={(ageSS)=>(ageSS && (this.components['ageSS'] = ageSS))}
              />
            </div>
            <div className="col-4_sm-12">
              <Region
                range={this.state.range} 
                ref={(region)=>(region && (this.components['region'] = region))}
              />
            </div>
            <div className="col-4_sm-12">
              <VisitorsSS
                range={this.state.range} 
                ref={(visitorsSS)=>(visitorsSS && (this.components['visitorsSS'] = visitorsSS))}
              />
            </div>
            <div className="col-4_sm-12">
              <FrequencyQuality
                range={this.state.range} 
                ref={(frequencyQuality)=>(frequencyQuality && (this.components['frequencyQuality'] = frequencyQuality))}
              />
            </div>
            <div className="col-4_sm-12">
              <Journey
                range={this.state.range} 
                ref={(journey)=>(journey && (this.components['journey'] = journey))}
              />
            </div>
          </div>
            {/*<div className="col-4_sm-12">
              <Goal />
            </div>
            <div className="col-4_sm-12">
              <Goal />
            </div>
            <div className="col-4_sm-12">
              <Goal />
            </div>*/}
        </div>
      </div>
    )
  }
}

export default MainContainer
