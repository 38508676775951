import React, { Component } from 'react'
import { Card, Elevation, Spinner } from "@blueprintjs/core"

import Color from 'color'

import moment from 'moment'
import _ from 'lodash'
import numeral from 'numeral'

import { Colors } from '../../../config'
import Chart from '../../Shared/Chart'

import axios from '../../../services/axios'
import storage from '../../../services/storage'

import Loads, { createLoader } from 'react-loads'
import Loading from '../../Shared/LoadingCharts'
import Title from '../../Shared/TitleChart'
import Reload from '../../Shared/ReloadChart'
import Error from '../../Shared/ErrorChart'
import './styles.css'

class BestHours extends Component {
  constructor(props){
    super(props)
    this.state = {
      data: []
    }

    this.reload = 0
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.loading = this.loading.bind(this)
    
    this.table = this.table.bind(this)
    this.chart = this.chart.bind(this)
    this.getData = this.getData.bind(this)
  }

  getData(range, filter){
    const { competitor } = this.props
    const since = moment(range[0]).format('YYYY-MM-DD')
    const until = moment(range[1]).format('YYYY-MM-DD')

    return storage.get('page')
    .then((page)=>{
      return axios.get(`${competitor == 'brand' ? 'social' : 'competitionsocial'}/bestHours/${page}?since=${since}&until=${until}&provider=${filter}`)
    })
  }
    
  chart(data){
    const custom =  _.map(data, (item, index)=>{
      return {
        'plot-labels': {
          rule: `%i==${index}`,
          text: `<span style="font-weight:bold">${item.hour < 10 ? '0' : ''}${item.hour}:00 - ${numeral(item.es_calculated).format('0,0[.]00')}%</span>`
        }
      }
    })

    let config = {
      type: 'scatter',
      backgroundColor: '#363737',
      gui: {
        behaviors: [{
          id: 'ViewSource',
          enabled: 'none'
        },{
          id: 'HideGuide',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'Reload',
          enabled: 'none'
        },{
          id: 'ViewAsPng',
          enabled: 'none'
        },{
          id: 'ViewDataTable',
          enabled: 'none'
        }]
      },
      globals: {
        fontFamily: 'Helvetica'
      },
      plotarea:{ 
        backgroundColor : '#363737',
        adjustLayout: true,
        margin: 0
      },
      scaleY: {
        lineStyle: 'solid',
        thousandsSeparator: ',',
        lineColor: '#4a4a4a',
        lineWidth: '0px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF',
          fontSize: '14px'
        }
      },
      scaleX: {
        itemsOverlap: true,
        maxItems: 25,
        lineStyle: 'solid',
        lineColor: '#4a4a4a',
        lineWidth: '0px',
        tick: {
          lineColor: 'none'
        },
        guide: {
          visible: true,
          lineStyle: 'solid',
          lineColor: '#4a4a4a',
          lineWidth: '.5px'
        },
        item: {
          color: '#FFFFFF'
        }
      },
      plot: {
        cursor: 'hand'
      },
      series: [{
        marker: {
          backgroundColor: '#CF3',
          borderColor: '#CF3',
          size: 8
        },
        tooltip: {
          text: '%v',
          multiple: true,
          fontSize: '12px',
          color: '#FFFFFF',
          backgroundColor: '#000',
          borderWidth: 0,
          borderColor: '#CF3',
          alpha: 0.6,
          callout: true,
          shadow: 0,
          borderRadius: 4,
          rules: _.map(custom, 'plot-labels')
        },
        values: _.map(data, (item)=>{
          return [item.hour, item.posts]
        })
      }]
    }

    return (
      <div>
        <Chart 
          width="100%" 
          height="342px"
          output="canvas"
          data={config} 
        />
      </div>
    )
  } 

  table(data){
    return (
      <div className="socialBestHoursTable">
        <table>
          <thead>
            <tr>
              <th>Hour</th>
              {data.map((hour, index)=>{
                return (
                  <th>{hour.hour}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>E Score</td>
              {data.map((hour, index)=>{
                return (
                  <td>{numeral(hour.es_calculated).format('0,0.00')}%</td> 
                )
              })}
            </tr>
            <tr>
              <td>Mo Posts</td>
              {data.map((hour, index)=>{
                return (
                  <td>{numeral(hour.posts).format('0,0')}</td> 
                )
              })}
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  success({ response, load }){
    const data = response.data.data
    const Chart = this.chart(data)
    const Table = this.table(data)

    return (
      <div>
        {Chart}
        {Table}
      </div>
    )
  }

  error({ error, load }){
    switch(true){
      case this.reload < 6:
        this.reload++
        load()
        return (<Loading />)
      break

      case this.reload < 10:
        return (<Reload onReload={()=>{
          this.reload++
          load()
        }} />)
      break

      case this.reload > 9:
        return (<Error 
          error={error.message} 
        />)
      break
    }
  }

  shouldComponentUpdate(props){
    return !_.isEqual(this.props, props)
  }

  loading(){
    return (
      <Loading />
    )
  }


  render () {
    const Chart = createLoader({
      load: () => this.getData(this.props.range, this.props.filter)
    })

    return(
      <Card 
        interactive={false} 
        elevation={Elevation.TWO} 
        className="no-pad-t-b"
        id="socialBestHours">
        <Title description='Las mejores horas del día respecto al ER (engagement rate) reportado.' title='Best Hours' />
        <div
          className='socialBestHoursContainer'
          ref={(ref)=>(this.chartContainer = ref)}
        >
          <Chart loadOnMount>
            <Chart.Loading>
              {this.loading()}
            </Chart.Loading>
            <Chart.Success>
              {this.success}
            </Chart.Success>
            <Chart.Error>
              {this.error}
            </Chart.Error>
          </Chart>
        </div>
      </Card>
    )
  }
}

export default BestHours
